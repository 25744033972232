/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
import { SCENE_DRAWER_FORM_FIELD } from 'components/common/Drawers/SceneDrawer/constants';
import { RIGHTS_TERRITORIES_TERM } from 'components/Settings/Tabs/Rights/constants';
import { RIGHTS_HINT_MESSAGE_TYPE } from 'components/common/Drawers/RightPresetDrawer/constants';
import { IMPORT_FIELDS, IMPORT_DIVIDERS } from 'redux-core/import-cuesheet/constants';
import { LanguageFile } from './types';

const es: LanguageFile = {
  translation: {
    global: {
      showHidden: 'MOSTRAR OCULTO',
      removeHidden: 'QUITAR OCULTO',
      addAll: 'Agregar todos',
      genericError: 'Ocurrió un error inesperado',
      versionError:
        'La información en esta página ha cambiado. Sus actualizaciones se perderán. Consejo: Copie cualquier cambio que necesite antes de refrescar la página',
      refreshInformation: 'Actualizar información',
      lastUpdatedByUserName: 'Última actualización por {{userName}} en {{date}}',
      lastUpdatedBy: 'Última actualización por {{userName}}',
      lastUpdatedAt: 'Última actualización el {{date}}',
      ok: 'Okay',
      hide: 'Esconder',
      show: 'Mostrar',
      clear: 'Limpiar',
      clearFilters: 'Limpiar Filtros',
      fileUploadError: 'Error al cargar el archivo',
      noResults: 'Sin resultados',
      startTyping: 'Comience a escribir para buscar',
      seeAll: 'Ver Todos',
      addItem: 'Agregar',
      apply: 'Aplicar',
      cancel: 'Cancelar',
      remove: 'Eliminar',
      search: 'Buscar',
      searchPlaceholder: 'Buscar...',
      confirmDialog: {
        cancel: '¿Seguro quiere cancelar?',
        message: '¿Seguro quiere continuar?',
        no: 'No',
        yes: 'Si',
        delete: '¿Esta seguro que quiere borrar?',
      },
      select: 'Seleccionar',
      format: {
        date: 'dd/MM/yyyy',
        placeholderCurrency: '0,00',
        timestamp: 'DD/MM/YYYY HH:mm:ss a',
      },
      yes: 'Sí',
      no: 'No',
      accept: 'Aceptar',
      section: 'Sección',
      forms: {
        add: 'Agregar',
        addAndContinue: 'Agregar y Continuar',
        allOption: 'Todos',
        attach: 'Adjuntar',
        noneOption: 'Ninguno',
        apply: 'Aplicar',
        close: 'Cerrar',
        cancel: 'Cancelar',
        choose: 'Elegir',
        continue: 'Continuar',
        change: 'Cambiar',
        chooseOne: 'Elegir una opción',
        select: 'Seleccionar',
        country: 'Paises',
        create: 'CREAR',
        createNew: 'Crear Nuevo',
        delete: 'Eliminar',
        edit: 'Editar',
        from: 'Desde',
        download: 'Descargar',
        back: 'Anterior',
        next: 'Siguiente',
        checkbox: {
          checked: 'Si',
          unchecked: 'No',
        },
        ariaLabels: {
          password: 'Alternar visibilidad de contraseña',
        },
        notification: {
          save: 'Tus cambios han sido guardados',
        },
        save: 'Guardar',
        done: 'Finalizado',
        saveAndClose: 'Guardar y Cerrar',
        saveAndAddNew: 'Guardar y Agregar Nuevo',
        searchByName: 'Busqueda por nombre',
        state: 'Estado/Provincia',
        status: {
          active: 'Activado',
          inactive: 'Desactivado',
        },
        details: 'Detalles',
        timeHoursPlaceholder: 'hh:mm:ss',
        timeMinutesPlaceholder: 'mm:ss',
        timeHHMMplaceholder: 'hh:mm',
        days: 'Días',
        months: 'Meses',
        update: 'Actualizar',
        unsavedChanges: 'Tienes cambios sin guardar. ¿Seguro desea continuar?',
        uploadPicture: 'Subir una imagen',
        removePicture: 'Borrar imagen',
        files: {
          image: 'Por favor suba una imagen.',
        },
        placeholder: {
          search: 'Escribe para buscar',
        },
        validations: {
          required: 'Por favor complete éste campo',
          requiredOneOfNameFields: 'Por favor complete uno de esos campos de nombre',
          min: 'Por favor ingrese al menos {{min}} caracteres',
          max: 'Por favor ingrese menos de {{max}} caracteres',
          lower: 'El valor no puede ser menor que {{min}}',
          higher: 'El valor no puede ser mayor que {{max}}',
          duplicated: 'El valor está duplicado',
          types: {
            date: 'Por favor ingrese una fecha válida',
            dateStartIsBefore: 'La fecha es anterior a la fecha final',
            dateIsBefore: 'La fecha es anterior al valor mínimo permitido',
            dateIsAfter: 'La fecha es psoterior al valor máximo permitido',
            number: 'Por favor ingrese un número',
            string: 'Por favor ingrese texto',
            email: 'Por favor ingrese un email válido',
            emails: 'Por favor verifique que todos los emails sean válidos',
            time: 'Por favor ingrese un tiempo válido',
            timeMax24: 'Por favor ingrese un tiempo entre 00:00:00 y 23:59:59',
          },
          /* @TODO make this rule flexible to accept value as 0 */
          moreThan: 'El valor debe ser mayor a 0',
          password: {
            atLeastLowercase: '{{contain}} al menos una letra minúscula',
            atLeastNumber: '{{contain}} al menos un número',
            atLeastSpecial: '{{contain}} al menos un caracter especial (@,!,#, etc)',
            atLeastUppercase: '{{contain}} al menos una letra mayúscula',
            mustMatch: 'Las contraseñas deben coincidir',
            shouldContain: 'La contraseña debería tener',
          },
          unique: 'El valor debe ser único',
          sender: 'Sender ID no puede tener más de 9 caracteres',
        },
        rightsPreset: 'Derechos preestablecidos es un campo obligatorio.',
        assign: 'Asignar',
        sync: 'Sincronizar',
        skip: 'Saltar',
      },
      navbar: {
        help: 'Ayuda',
        licensors: 'Licenciatarios',
        licensing: 'Licencias',
        home: 'Proyectos',
        qwire: 'Qwire',
        reports: 'Reportes',
        newReports: 'Nuevos Reportes',
        settings: 'Configuración',
        tasks: 'Tareas',
        cueSheets: 'Hojas de Pistas',
        tracks: 'Canciones',
      },
      instructions: {
        hide: 'Esconder instrucciones',
        show: 'Mostrar instrucciones',
      },
      actions: {
        edit: 'Editar',
        delete: 'Eliminar',
        download: 'Descargar',
        duplicate: 'Duplicar',
        removeButton: 'Quitar de la lista',
        editOption: 'Editar opción seleccionada',
      },
      prompt: {
        cancel: '¿Seguro quiere cancelar?',
        delete: '¿Seguro quiere eliminar?',
      },
      paginator: {
        intervalIndicator: '{{interval}} de {{total}}',
      },
      tabs: {
        ariaLabel: 'Pestañas',
      },
      trackStatuses: {
        CHECK_REQUEST_SENT: 'Solicitud de Control Enviada',
        CLEARED: 'Validado',
        CONF_SENT: 'Confirmación enviada',
        DENIED: 'Rechazado',
        EXPIRED: 'Expirado',
        LICENSE_REQUEST_SENT: 'Solicitud de Licencia Enviada',
        LICENSE_SENT: 'Licencia Enviada',
        NEW: 'Nueva',
        PAID: 'Pagado',
        QUOTE_REQ_SENT: 'Solicitud de Cotización Enviada',
        REQUOTE: 'Recotizar',
        STUDIO_OWNED: 'Propiedad del Estudio',
      },
      cueSheetSubmissionStatus: {
        NOT_SENT: 'Sin Enviar',
        EMAILED: 'Enviada por mail',
        SENT: 'Enviada',
        SENT_REVISED: 'Envío Revisado',
        REJECTED: 'Rechazada',
        ACCEPTED: 'Aceptada',
        WARNING: 'Advertencia',
      },
      descriptors: {
        L: 'L - Lenguaje',
        S: 'S - Sexo',
        V: 'V - Violencia',
        FV: 'FV - Violencia Ficticia',
        D: 'D - Sexual o Diálogo Sugestivo',
      },
      fileFormats: {
        PDF: 'PDF',
        CSV: 'CSV',
        XML: 'XML',
        XLS: 'XLS',
        XLSX: 'XLSX',
        RapidCue: 'RapidCue',
        FormattedXLSX: 'Formatted XLSX',
        RapidCueXML: 'RapidCue XML',
      },
      trackTypes: {
        SONG: 'Cancion',
        SCORE: 'Puntajes',
        LIBRARY: 'Librerías',
        SOUND_EFFECT: 'SFX',
        SONG_REVISED: 'Song Revised',
      },
      trackUseTypes: {
        BI: 'BI',
        BV: 'BV',
        'BI/BV': 'BI/BV',
        VI: 'VI',
        VV: 'VV',
        MT: 'MT',
        ET: 'ET',
        LOGO: 'Logo',
        OT: 'OT',
      },
      season: 'Temporada {{number}} ',
      fileTypes: {
        // Track Types
        QUOTE_REQUEST_SENT: 'Solicitud de cita',
        QUOTE: 'Cita',
        CONFIRMATION: 'Confirmación',
        APPROVAL: 'Aprobación',
        LICENSE: 'Licencia',
        LICENSE_REQUEST_SENT: 'Solicitud de Licencia',
        CHECK_REQUEST_SENT: 'Solicitud de control',
        ADDITIONAL_CORRESPONDENCE: 'Correspondencia adicional',
        END_TITLE_APPROVALS: 'Finalizar aprobaciones de títulos',
        SHEET_MUSIC: 'Partitura',
        LYRICS: 'Letras',
        AUDIOFILE: 'Archivo de audio',
        QUOTE_RENEWAL: 'Renovación de Cotización',
        CHECK_REQUEST: 'Solicitud de Cheque',
        PAYMENT: 'Pago',
        LICENSE_REQUEST: 'Solicitud de licencia',
        QUOTE_REQUEST: 'Solicitud de Presupuesto',
        // Production Types
        CUE_SHEET: 'Grilla de Pistas',
        PRODUCTION_INFO: 'Información de la Producción',
        CALENDAR: 'Calendario',
        SCRIPT: 'Libreto',
        OTHER: 'Otro',
      },
      payeeTypes: {
        INDIVIDUAL: 'Individual',
        COMPANY: 'Compañía',
      },
      roles: {
        divisionAdmin: 'Administrador de division',
        owner: 'Propietario',
        editor: 'Editor',
        reviewer: 'Crítico',
        none: 'Ninguna',
      },
    },
    styleguide: {
      repAvatar: {
        picture: 'imagen del rep',
        initials: 'iniciales del rep',
      },
    },
    charts: {
      budget: {
        budget: 'PRESUPUESTO',
        committed: 'Comprometido',
        potential: 'Potencial',
        uncommited: 'No Comprometido',
        over: 'sobrante',
      },
      tracks: {
        types: {
          effects: 'Efecto:',
          libraries: 'Librería:',
          scores: 'Puntaje:',
          songs: 'Canción:',
        },
        labels: {
          new: '<0>Nuevo</0> (<1>{{count}}</1>)',
          inProgress: '<0>En Progreso</0> (<1>{{count}}</1>)',
          cleared: '<0>Licenciado</0> (<1>{{count}}</1>)',
          confirmed: '<0>Confirmado</0> (<1>{{count}}</1>)',
          licensed: '<0>Licenciando</0> (<1>{{count}}</1>)',
          other: '<0>Otros</0> (<1>{{count}}</1>)',
          licensing: '<0>Licenciando</0> (<1>{{count}}</1>)',
          licenseSent: '<0>Licenciado</0> (<1>{{count}}</1>)',
          checkSent: '<0>Cheque Enviado</0> (<1>{{count}}</1>)',
          paid: '<0>Pago</0> (<1>{{count}}</1>)',
          licenseRequestSent: '<0>Pedido de Licencia Enviado</0> (<1>{{count}}</1>)',
          progress: 'PROGRESO',
          total: '{{count}} Canción',
          total_plural: '{{count}} Canciones Totales',
          totalUsed: '{{count}} Canción Usada',
          totalUsed_plural: '{{count}} Canciones Usadas',
        },
      },
    },
    topbar: {
      bookmarks: 'Marcadores',
      bookmarkDeleted: 'Marcador eliminado',
      noTracksBookmarked: 'No hay canciones marcadas',
      search: 'Busque...',
      recent: 'Reciente',
      results: 'Resultados de la Búsqueda',
      userMenu: {
        profile: 'Perfil',
        help: {
          title: 'Ayuda',
          emailUs: 'Envíanos un correo electrónico:',
          callUs: 'Llámenos: {{phone}}',
        },
        logout: 'Cerrar sesión',
      },
    },
    grid: {
      filters: {
        disclosureButton: 'Filtro',
        disclosureButton_plural: 'Filtros',
        addFilters: 'Agregar filtro',
      },
    },
    drawers: {
      advancedFilters: {
        drawerTitle: 'Filtros Avanzados',
        cuesTitle: 'Pistas',
        assetsTitle: 'Materiales',
        sceneFilters: {
          cueContainerIds: 'Escenas',
        },
        cuesFilters: {
          all: 'Todos',
          using: 'En uso',
          not_using: 'Sin uso',
          trackStatuses: 'Estado',
          trackTypes: 'Tipos de pista',
          trackUseTypes: 'Tipos de uso de pista',
          trackTags: 'Etiquetas de Pista',
          clearanceRepIds: 'Responsable de Autorización',
          libraryIds: 'Libreria',
        },
        assetsFilters: {
          assets: 'Nombre',
          assetStatuses: 'Estado',
          assetTypes: 'Tipo',
          vendors: 'Proveedor Creativo',
          completedStatuses: 'Completados',
        },
      },
      cueSheetDetails: {
        drawerTitle: 'Detalles de la Pista',
        artists: 'Artistas',
        title: 'Título',
        notes: 'Notas',
        iswc: 'ISWC',
        isrc: 'ISRC',
        cutNumber: 'Corte de Libreria',
        cdNumber: 'Libreria CD#',
        explicit: 'Explicito',
        theme: 'Tema',
        usageDetail: 'Detalle de uso',
        masterLicensor: 'Licenciante Maestro',
        syncLicensor: 'Licenciante Sync',
        share: 'Compartido',
      },
      cueSheetSubmissionHistory: {
        drawerTitle: 'Historial de presentaciones de pistas',
        newSubmission: 'Nueva Presentación',
        disabledSubmitTooltip: 'Sólo se puede enviar la versión actual',
        hint: 'Consejo:',
        hintText:
          'Abajo se encuentra el progreso de las Hojas de Pistas que ya están en el proceso de presentación, y puedes ver el progreso de la presentación expandiendo cada fila. El estado se puede actualizar clickeando en el botón de Actualizar para el PRO correspondiente.',
        hintSubtext: 'Para presentar una Hoja de Pistas adicional, use el botón de "Nueva Presentación".',
        table: {
          version: 'Versión',
          dateSubmitted: 'Fecha presentada',
          fileType: 'Tipo Archivo',
          method: 'Método',
          methodManually: 'Manualmente',
          methodElectronically: 'Electrónicamente',
          recipient: 'Destinatario',
          submissionStatus: 'Estado Presentación',
          user: 'Usuario',
          actions: {
            updateSubmission: 'Actualizar Presentación',
            viewResponse: 'Ver Respuesta',
            downloadResponse: 'Descargar Respuesta',
            downloadXML: 'Descargar XML',
          },
        },
        goToSubmit:
          '¿Estás seguro que deseás ir a la Presentación de Hoja de Pistas? Se perderán todos los cambios que no hayas guardado.',
        successMessage: 'Tus cambios han sido guardados',
        errorsAndWarnings: {
          errors: 'Errores',
          warnings: 'Advertencias',
          save: 'Guardar',
          close: 'Cerrar',
        },
      },
      submitCueSheet: {
        drawerTitle: 'Presentación de Pistas',
        hint: 'Pista:',
        hintText:
          'El proceso de presentación de hoja de pistas te ayudará a hacer un seguimiento de los PRO’s que hayas presentado, el tipo de file que hayas presentado y el estado de la presentación.',
        datestamp: 'Fecha',
        fileType: 'Tipo de archivo',
        hintSubtext: 'Siga los pasos a continuación para comenzar.',
        pro: 'Seleccione Recipientes',
        submit: 'Presentar',
        submissionStatus: 'Estado de Presentación',
        submissionStatusPlaceholder: 'Estado de Presentación',
        successMessage: 'Presentación de Hoja de Pistas creada con éxito',
        recipient: 'Pareja',
        rapidCueFailedValidationMessage:
          'Tienes datos incompletas. No puedes presentar la hoja cue a RapidCue hasta que todas las pistas esten completas. Por ayuda, contacta a support@qwire.com.',
        rapidCueFailedValidationDivision: 'Faltan datos en Division:',
        rapidCueFailedValidationProduction: 'Falta datos en Produccion:',
        rapidCueFailedValidationWithoutUsage: 'Sin uso:',
        rapidCueFailedValidationWithoutDuration: 'Sin duracion:',
        rapidCueFailedValidationWithoutRole: 'Sin rol:',
        rapidCueFailedValidationWithInvalidShares: 'Con porcentaje invalido:',
        rapidCueFailedValidationWithoutPublisherOrWriter: 'Sin editor o escritor:',
        rapidCueFileTooBig:
          'El archivo es muy pesado para presentar a RapidCue. Debe enviar un mail con un PDF a RapidCue.',
        rapidCueSubmitted: 'Presentacion satisfactoria a RapidCue.',
        rapidCueSubmissionPending: 'RapidCue Pendiente.',
        rapidCueSubmissionError: 'Error al presentar a RapidCue. Por ayuda, contacta a support@qwire.com',
      },
      importSaveAsTemplate: {
        cancel: 'Cancelar',
        drawerTitle: 'Guardar como Plantilla',
        name: 'Nombre',
        namePlaceholder: 'Ingrese Nombre',
        noResults: 'No hay plantillas existentes para esta división.',
        or: 'O',
        replaceExisting: 'Remplazar plantilla existente',
        saveAndImport: 'Guardar e Importar',
        notifications: {
          added_plural: '{{count}} Pistas Añadidas',
          added: '{{count}} Pista Añadida',
          download: '<0>Descargá</0> <1>para ver el archivo con pistas que fallaron</1>',
          failed_plural: '{{count}} Pistas Fallaron',
          failed: '{{count}} Pista Falló',
          importComplete: 'Importación Completada',
          ok: 'OK',
          replaceTemplate: '¿Reemplazar la plantilla {{name}}?',
        },
      },
      cueSheetVersion: {
        newVersion: 'Nueva Versión',
        editVersion: 'Editar Versión',
        deleteVersion: 'Eliminar Versión',
        duplicate: 'Duplicar',
        defaultVersion: 'Versión {{date}}',
        defaultDuplicate: '{{title}} - COPIA',
        duplicateVersion: 'Duplicar Versión',
        makeCurrent: 'Convertir en Versión Actual',
        hint: 'Esto creará una nueva versión de la Grilla de Pistas. Puedes cambiar y editar los detalles de la versión en cualquier momento.',
        name: 'Nombre de la versión',
        notes: 'Notas adicionales',
        notesPlaceholder: 'Escribe algo...',
        notifications: {
          create: 'Versión de la Grilla de Pistas creada con éxito',
          edit: 'Versión de la Grilla de Pistas editada con éxito',
          delete: 'Versión de la Grilla de Pistas eliminada con éxito',
          deletePrompt: '¿Estás seguro que deseás eliminar {{name}}?',
          duplicate: 'Versión de la Grilla de Pistas duplicada con éxito',
        },
      },
      importCueSheet: {
        drawerTitle: 'Importar Archivo',
        hintTitle: 'Consejo:',
        fileHint:
          'Iniciá el proceso de importación de hoja de pistas arrastrando tu archivo en el espacio debajo. Si deseás usar el buscador de archivos de tu computadora, simplemente hacé click en el área de abajo.',
        templatesHint: 'Elegí continuar con una plantilla o importar el a archivo usando nuestra herramienta de mapeo.',
        yourFile: 'Tu archivo',
        matchingMethod: 'Seleccionar un método',
        removeFile: 'Eliminar',
        noTemplate: 'Sin Plantilla',
        notifications: {
          addFav: 'Plantilla agregada a favoritos',
          remove: '¿Estás seguro que deseás eliminar {{name}}?',
          removed: 'Plantilla borrada correctamente',
          removeFav: 'Plantilla sacada de favoritos',
          cancel: '¿Estás seguro que deseás cancelar la importación? Se perderán todos los cambios',
          pleaseWait: 'Por favor, espere mientras se crea la Grilla de Pistas',
        },
        edlMaxLinesExceeded:
          'Este archivo supera las 500 cues. Divida su Hoja de referencia en archivos separados y vuelva a intentarlo.',
      },
      cueAdvancedSearch: {
        drawerTitle: 'Búsqueda Avanzada',
        warningMessage:
          'Esta a punto de actualizar la pista usando informacion de qwireTracks. ¿Está seguro que desea continuar?',
      },
      changeCueDetails: {
        drawerTitle: 'Cambiar Detalles de Pista',
        donotChange: 'NO CAMBIAR',
        successMessage: 'Cambios guardados',
        trackType: 'Tipo de canción',
        usage: 'Uso',
      },
      lettersExtraAttachmentsDrawer: {
        file: {
          name: 'Nombre del Archivo',
          type: 'Tipo',
        },
        title: 'Attach Files',
      },
      licensorsAddEdit: {
        searchContact: 'Buscar Contacto',
        contact: {
          contact: 'Contacto',
          firstName: 'Nombre *',
          lastName: 'Apellido *',
          email: 'Email *',
          type: 'Tipo',
          number: 'Número',
          territory: 'Territorio',
          currency: 'Moneda',
          address1: 'Dirección línea 1',
          address2: 'Dirección línea 1',
          city: 'Ciudad',
          state: 'Estado',
          zipCode: 'Código Postal',
          country: 'País',
        },
        subLabel: {
          subLabel: 'Etiqueta secundaria',
          name: 'Nombre de la Etiqueta',
          labelId: 'Etiqueta ID#',
          newSubLabel: 'Nueva Etiqueta Secundaria',
          addNew: 'Agregar Nueva',
          deleteSubLabel: 'Borrar Etiqueta',
          promptRemoveSubLabel: '¿Seguro quieres borrar la Etiqueta Secundaria?',
          updateContactPrompt: 'Está seguro de que desea actualizar la información de contacto?',
        },
        basicInfo: {
          repeatedLicensor: 'El licenciante ya existe en la canción',
          deleteLicensor: 'Borrar Licenciante',
          deletedLicensor: 'Licenciante Borrado ',
          deleteLicensorPrompt: '¿Seguro quieres borrar el Licenciante?',
          master: {
            name: 'Nombre del licenciante Master',
          },
          sync: {
            name: 'Nombre del licenciante Sync',
          },
          one_stop: {
            name: 'Nombre del licenciante Una Parada',
          },
          other: {
            name: 'Nombre del licenciante Otro',
          },
          share: 'Compartir',
          providesOwnLicense: 'Tiene Licencia Propia',
          updateLicensorNamePrompt: '¿Seguro quieres actualizar el nombre del Licenciante?',
          updateContactPrompt: 'Está seguro de que desea actualizar la información de contacto?',
        },
        sync: {
          addTitle: 'Agregar Nuevo Sync',
          editTitle: 'Editar Sync',
          success: 'Sync Editado con éxito',
          prompt: 'Seguro quiere cancelar?',
        },
        other: {
          addTitle: 'Agregar Nuevo Otro',
          editTitle: 'Editar Otro',
          success: 'Otro Editado con éxito',
          prompt: 'Seguro quiere cancelar?',
          basicInfo: 'Información básica',
          subLabel: 'Etiqueta secundaria',
        },
        master: {
          addTitle: 'Agregar Nuevo Master',
          editTitle: 'Editar Master',
          success: 'Master Editado con éxito',
          basicInfo: 'Información básica',
          subLabel: 'Etiqueta secundaria',
          prompt: 'Seguro quiere cancelar?',
        },
        one_stop: {
          addTitle: 'Agregar Nuevo Licenciante',
          editTitle: 'Editar Licenciante',
          success: 'Licenciante Editado con éxito',
          basicInfo: 'Información básica',
          prompt: 'Seguro quiere cancelar?',
          subLabel: 'Etiqueta secundaria',
        },
      },
      search: {
        cancelPrompt: '¿Estás seguro que deseás cancelar?',
        drawerTitle: 'Buscar',
        searchHint: 'Comienza a escribir para ver resultados',
        searchPlaceholder: 'Buscar...',
      },
      searchProductions: {
        drawerTitle: 'Seleccionar Producción',
        features: 'Lanzamiento',
        marketing: 'Campaña',
        noResults: 'Sin Resultados',
        project: 'Proyecto',
        release: 'Lanzamiento',
        season: 'Temporada',
        television: 'Episodio',
        productionType: 'Tipo de Producción',
        showInactived: 'Mostrar Producciones Desactivadas',
      },
      unassignedBin: {
        assetRights: 'Derechos de Producción del Activo',
        edit: 'Editar Activo - Contenedor No Asignado',
      },
      assets: {
        titles: {
          edit: 'Editar Material',
          new: 'Material nuevo',
          finishes: 'Acabados',
        },
        labels: {
          creativeVendors: 'Proveedores creativos',
          date: 'Fecha',
          dateUsed: 'Fecha de uso',
          finisher: 'Finalizador',
          finishers: 'Finalizadores',
          finishes: 'Finalizaciones',
          finishingDate: 'fecha de finalización',
          finishingVendors: 'Proveedores de finalización',
          isciNumber: 'ISCI#',
          notes: 'Notas',
          requiredRights: 'Derechos del Activo',
          title: 'Titulo del Activo',
          type: 'Tipo del Activo',
          versionName: 'Nombre de versión',
          version: '# de versión',
          versionTRT: 'TRT',
        },
        form: {
          noResults: 'Sin resultados',
          confirmRightsUpdateForAsset: 'Deseas aplicar los nuevos derechos a TODAS las canciones en este material?',
        },
        notifications: {
          create: {
            success: 'Material creado exitosamente',
            failed: 'Ha ocurrido un error al crear el Material. Por favor intentelo nuevamente',
          },
          update: {
            success: 'Material actualizado exitosamente',
            failed: 'Ha ocurrido un error al actualizar el Material. Por favor intentelo nuevamente',
          },
        },
      },
      watchingAssets: {
        approvedFees: 'Tarifa Aprobada Total ',
        assetType: 'Tipo de Material',
        creativeVendor: 'Proveedor Creativo',
        pending: '({{count}} Pendiente)',
        status: 'Estado',
        total_plural: '{{count}} Materiales',
        total: '{{count}} Material',
        version: 'Versión',
        cues: 'Pistas',
        incomplete: 'Incompletas',
        totalCues: 'Pistas Totales',
        incompleteCues: 'Pistas Incompletas',
      },
      qtracksSync: {
        header: {
          helpfulTip: 'Ayuda:',
          hint: 'Seleccioná la información nueva de qwireTracks que quieras aplicar a la canción en tu proyecto. Puedes seleccionar todos, algunos o ninguno de los cambios. También puedes optar por ignorar los cambios en qwireTracks y no volverás a ser notificado.',
          ignoreFuture: 'Ignorar futuras actualizaciones de qwireTracks para esta canción, en esta producción',
          title:
            'La herramienta de comparación de canciones resalta diferencias significativas entre la información de la canción en tu producción y las últimas actualizaciones de qwireTracks.',
          trackComparision: 'Comparación de Canción',
          trackVersion: 'Versión de qwireTracks',
        },
        diff: {
          selectAll: 'Seleccionar Todos',
          changes: '{{count}} Cambio',
          changes_plural: '{{count}} Cambios',
          qTracksDetails: 'Detalles de qwireTracks',
          productionDetails: 'Detalles de la Producción',
          trackDetails: {
            group: 'Details de la Canción',
            title: 'Título',
            artists: 'Artistas',
            copyright: 'Derechos de Autor',
            albums: 'Álbumes',
            writers: 'Autores',
            arrangers: 'Arregladores',
            duration: 'Duración',
            year: 'Año',
            isrc: 'ISRC',
            trackType: 'Tipo de Canción',
            library: 'Librería',
            containsSamples: 'Contiene Ejemplos',
            oneStop: 'Una Parada',
            unionDetails: {
              group: 'Detalles de Unión',
              nonUnion: 'Sin Unión',
            },
          },
          master: {
            group: 'Licenciante Maestro ({{position}})',
            name: 'Licenciante Maestro',
            share: 'Porcentaje Compartido',
            territory: 'Territorio',
            address1: 'Dirección 1',
            address2: 'Dirección 2',
            city: 'Ciudad',
            state: 'Estado/Provincia',
            zip: 'Código Postal',
            country: 'País',
            recordLabel: {
              group: 'Etiqueta de Registro {{position}}',
              name: 'Nombre de Sub-Etiqueta',
              id: 'ID de Etiqueta',
            },
          },
          sync: {
            group: 'Licenciante Sync ({{position}})',
            name: 'Licenciante Sync',
            share: 'Porcentaje Compartido',
            territory: 'Territorio',
            address1: 'Dirección 1',
            address2: 'Dirección 2',
            city: 'Ciudad',
            state: 'Estado/Provincia',
            zip: 'Código Postal',
            country: 'País',
            affiliation: 'Afiliación',
            ipi: 'IPI #',
            cueSheetPublisher: 'Editor de Hoja de Referencia {{position}}',
          },
          other: {
            group: 'Otro Licenciante ({{position}})',
            name: 'Otro Licenciante',
            share: 'Porcentaje Compartido',
            licensorType: 'Tipo de Licenciante',
            function: 'Función',
            territory: 'Territorio',
            isParentCompany: 'Es Compañía Padre',
            ownerSince: 'Dueño Desde',
            url: 'URL',
            address1: 'Dirección 1',
            address2: 'Dirección 2',
            city: 'Ciudad',
            state: 'Estado/Provincia',
            zip: 'Código Postal',
            country: 'País',
            ipi: 'IPI #',
          },
        },
        slider: {
          changes: '{{current}} de {{total}} Cambios',
        },
      },
      productionSetup: {
        drawers: {
          newProject: 'Nuevo Proyecto',
        },
        budget: {
          budget: 'Presupuesto',
          date: 'Fecha',
          drawerTitleBudget: 'Presupuesto',
          drawerTitleBudgetHistory: 'Histórico del presupuesto',
          drawerHeaderInitialNote:
            'Esta producción aún no tiene presupuesto. Haga clic en el botón Actualizar para agregar el presupuesto inicial. Puedes cambiarlo en cualquier momento.',
          reasonForChange: 'Razón para el cambio',
          reasonForChangeDefault: 'Presupuesto inicial',
          reasonForChangePlaceholder: 'presupuesto ajustado para acomodar los cambios de pista',
          user: 'Usuario',
        },
        project: {
          abbreviation: 'Abreviación',
          drawerTitle: 'Nuevo Proyecto',
          drawerEditTitle: 'Editar Proyecto',
          details: 'Detalles del Proyecto',
          name: 'Título del Proyecto',
          notes: 'Notas',
          notification: {
            edit: 'El proyecto {{name}} fue actualizado',
          },
          projectSynopsis: 'Sinopsis del Proyecto',
          status: 'Estado',
          items: {
            akaTitles: 'Títulos AKA',
            fkaTitles: 'Títulos FKA',
            seriesAbbreviation: 'Abreviatura de serie',
          },
          validations: {
            duplicateName: 'Ya existe un Proyecto con ese nombre',
          },
        },
        fkaTitles: {
          drawerTitle: 'Títulos FKA',
          delete: '¿Estás seguro que deseas borrar el FKA {{name}}?',
          placeholderEnterTitle: 'Ingrese un título',
        },
        akaTitles: {
          delete: '¿Estás seguro que deseas borrar el AKA {{title}}?',
          drawerTitle: 'Títulos AKA',
          drawerEditTitle: 'Editar AKA',
          placeholderCountry: 'País',
          placeholderTitle: 'Título',
        },
        productionCompanies: {
          addTitle: 'Añadir Empresa de producción',
          delete: '¿Estás seguro que deseas borrar {{name}}?',
          email1: 'Email 1',
          email2: 'Email 2',
          mainTitle: 'Empresas de producción',
          name: 'Nombre',
          namePlaceholder: 'Busca por companias',
          notes: 'Notas',
          phone1: 'Número Telefónico 1',
          phone2: 'Número Telefónico 2',
          address1: 'Dirección 1',
          address2: 'Dirección 2',
          city: 'Ciudad',
          state: 'Estado/Provincia',
          zip: 'Código Postal',
          country: 'País',
        },
        blanketAgreements: {
          delete: '¿Estás seguro que deseas borrar {{name}}?',
          drawerTitle: 'Acuerdos Generales',
        },
        season: {
          additionalCurrencies: 'Monedas Adicionales',
          animated: 'Animado',
          archivalOnly: 'Sólo Archivo',
          defaultRights: 'Derechos predeterminados',
          currency: 'Moneda',
          details: 'Detalles de la Temporada de TV',
          ratingDescriptor: 'Descriptor de calificación',
          internalSeasonProdId: 'Temporada interna de producción #',
          descriptorsPlaceholder: 'Elegir un Descriptor',
          drawerTitle: 'Nueva Temporada',
          drawerEditTitle: 'Editar Temporada',
          items: {
            blanketAgreements: 'Acuerdos Generales',
            networkBroadcaster: 'Red/Locutor',
            distributors: 'Distributors',
            actors: 'Actores',
            directors: 'Directores',
            editors: 'Editores',
            producers: 'Productores',
            productionCompanies: 'Compañias de Producción',
            keyPersonnel: 'Personal Clave',
          },
          name: 'Título de la Temporada',
          notification: {
            create: 'La  Temporada #{{name}} ha sido creada',
            edit: 'La Temporada #{{name}} ha sido actualizada',
          },
          validations: {
            duplicateNumber: 'Ya existe una temporada con ese numero',
          },
          personnel: 'Personal',
          version: 'Versión',
          category: 'Categoría',
          language: 'Idioma',
          territory: 'Territorio',
          internalSeasonProductionNumber: 'Temporada interna de producción #',
          eidr: 'EIDR #',
          isan: 'ISAN #',
          defaultAdditionalInformation: 'Información adicional predeterminada',
          defaultProductionInformation: 'Información de producción predeterminada',
          defaultPersonnel: 'Personal predeterminado',
          productionCurrency: 'Moneda de Producción',
          duration: 'Duración',
          productionYear: 'Año de Producción Predeterminado',
          description: 'Descripción de la temporada',
          preparedBy: {
            title: 'Hoja de referencia predeterminada preparada por',
            firstName: 'Nombre de pila',
            lastName: 'Apellido',
            email: 'Email',
            phone: 'Teléfono',
          },
          defaultRating: 'Calificación predeterminada',
          rating: 'Clasificación',
          ratingsPlaceholder: 'Elegir una Clasificación',
          saveAndAddRelease: 'Guardar y Agregar un Lanzamiento',
          seasonNumber: 'Número de temporada',
        },
        release: {
          drawerTitle: 'Nuevo Lanzamiento',
          drawerEditTitle: 'Editar Lanzamiento',
          eidr: 'EIDR #',
          isan: 'ISAN #',
          items: {
            networkBroadcaster: 'Estación de Red Predeterminada',
            distributors: 'Distribuidores',
            productionCompanies: 'Compañías de producción',
            keyPersonnel: 'Personal clave',
            directors: 'Directores',
            producers: 'Productores',
            editors: 'Editores',
            actors: 'Actores',
          },
          language: 'Lenguaje',
          notes: 'Notas',
          description: 'Descripción',
          notification: {
            create: 'El Lanzamiento {{name}} ha sido creado',
            edit: 'El Lanzamiento {{name}} ha sido actualizado',
          },
          releaseDetails: 'Detalles del Lanzamiento',
          duration: 'Duración',
          releaseTitle: 'Nombre del Lanzamiento',
          releaseNumber: 'Número de versión',
          animated: 'Animado',
          defaultRating: 'Calificación predeterminada',
          rating: 'clasificación',
          productionYear: 'Año productivo',
          ratingDescriptor: 'Calificación Descripción',
          currency: 'Moneda',
          defaultRights: 'Derechos por defecto',
          defaultProductionInformation: 'Información de producción predeterminada',
          defaultPersonnel: 'Personal predeterminado',
          requiredRights: 'Derechos de Producción',
          episodeBudget: 'Episodio Presupuesto',
          defaultAdditionalInformation: 'Información adicional predeterminada',
          version: 'Versión',
          category: 'Categoría',
          preparedBy: {
            title: 'Hoja de referencia Predeterminada, preparada por',
            firstName: 'Nombre',
            lastName: 'Apellido',
            email: 'Email',
            country: 'País',
            phone: 'Teléfono',
          },
          releasePlaceholder: 'Lanzamiento Mundial',
          saveAndAddEpisode: 'Guardar y Agregar Episodio',
          territory: 'Territorio',
        },
        currency: {
          drawerTitle: 'Monedas',
          drawerEditTitle: 'Editar Monedas',
          addCurrency: 'Agregar Moneda',
          table: {
            header: {
              currency: 'Moneda',
              exchangeRate: 'Tipo de Cambio',
            },
            errors: {
              existant: 'La moneda ya existe',
            },
          },
        },
        scenes: {
          drawerTitle: 'Agregar Escena',
          drawerEditTitle: 'Editar Escena',
          form: {
            [SCENE_DRAWER_FORM_FIELD.POSITION]: {
              label: 'Ubicación del Proyecto',
              placeholder: 'No hay escenas disponibles para seleccionar',
              divider: 'Añadir después de Escena',
            },
            [SCENE_DRAWER_FORM_FIELD.NUMBER]: {
              label: 'Escena #',
              placeholder: 'Número de Escena',
            },
            [SCENE_DRAWER_FORM_FIELD.LENGTH]: {
              label: 'Largo de la Escena',
              placeholder: 'MM:SS',
            },
            [SCENE_DRAWER_FORM_FIELD.TITLE]: {
              label: 'Título de la Escena',
              placeholder: 'Título de la Escena',
            },
            [SCENE_DRAWER_FORM_FIELD.DESCRIPTION]: {
              label: 'Descripción',
              placeholder: 'Escriba una descripción para la escena',
            },
            [SCENE_DRAWER_FORM_FIELD.NOTES]: {
              label: 'Notas',
              placeholder: 'Notas de la escena',
            },
          },
          notification: {
            save: 'La Escena {{name}} fue guardada',
            edit: 'La Escena {{name}} fue actualizada',
            prompt: '¿Hay Cambios sin guardar. Seguro quiere cancelar?',
          },
        },
        personnel: {
          delete: 'Estás seguro que deseas borrar {{name}}?',
          drawerTitle: 'Clave Personal',
          drawerEditTitle: 'Editar Clave Personal',
        },
        position: {
          drawerTitle: 'Posición',
          drawerEditTitle: 'Editar Posición',
        },
        addKeyPersonnel: {
          drawerTitle: 'Agregar Clave Personal',
          drawerEditTitle: 'Editar Clave Personal',
          prefix: 'Prefijo',
          firstName: 'Nombre',
          lastName: 'Apellido',
          phoneNumber: 'Número telefónico',
          email: 'Email',
          position: 'Posición',
          notes: 'Notas',
        },
        editors: {
          delete: '¿Estás seguro que deseas borrar {{name}}?',
          drawerTitle: 'Editores',
        },
        distributors: {
          delete: '¿Estás seguro que deseas borrar {{name}}?',
          drawerTitle: 'Distribuidores',
        },
        networkBroadcaster: {
          delete: '¿Estás seguro que deseas borrar {{name}}?',
          drawerTitle: 'Red/Locutor',
        },
        producers: {
          delete: '¿Estás seguro que deseas borrar {{name}}?',
          drawerTitle: 'Productores',
        },
        directors: {
          delete: '¿Estás seguro que deseas borrar {{name}}?',
          drawerTitle: 'Directores',
          role: 'Papel',
          director: 'Directore',
          actorDirector: 'Actore/Directore',
        },
        actors: {
          delete: '¿Estás seguro que deseas borrar {{name}}?',
          drawerTitle: 'Actores',
          role: 'Papel',
          actor: 'Actore',
          actorDirector: 'Actore/Directore',
        },
        defaultProducers: {
          delete: '¿Estás seguro que deseas borrar {{name}}?',
          drawerTitle: 'Productores Predeterminados',
        },
        defaultDirectors: {
          delete: '¿Estás seguro que deseas borrar {{name}}?',
          drawerTitle: 'Directores Predeterminados',
        },
        defaultActors: {
          delete: '¿Estás seguro que deseas borrar {{name}}?',
          drawerTitle: 'Actores Predeterminados',
        },
        defaultDistributors: {
          delete: '¿Estás seguro que deseas borrar {{name}}?',
          drawerTitle: 'Distribuidores Predeterminados',
        },
        defaultNetworks: {
          delete: '¿Estás seguro que deseas borrar {{name}}?',
          drawerTitle: 'Redes Predeterminadas',
        },
        tracks: {
          add: 'AGREGAR',
          added: 'AGREGADO',
          drawerTitle: 'Agregar Canción',
          createNew: 'Crear Nueva Canción',
          addTrackTo: 'Agregar a:',
          reset: 'Reiniciar',
          filter: {
            artist: 'Artista',
            artistPlaceholder: 'Buscar por Artista',
            library: 'Librería',
            search: 'Buscar',
            searchPlaceholder: 'Buscar por Título o ID de Pista',
            title: 'Título',
            titlePlaceholder: 'Buscar por Título',
            trackTitlePlaceholder: 'Introducir Título de Pista',
            trackType: 'Tipo de Canción',
            tracks: 'Canción',
            score: 'Puntaje',
            libraryTracks: 'Canción de Librería',
            soundEffects: 'Efecto de Sonido',
            contentMode: 'Modo de Contenido',
            createNew: 'Crear Nuevo',
            backToSearch: 'Volver a Buscar',
          },
          searchPlaceholder: {
            heading: 'Encuentra y añade tus pistas',
            bodyPrimary:
              'Busca pistas y si no puedes encontrar lo que estás buscando, haz clic en Crear Nuevo para añadir una nueva pista.',
          },
          createPlaceholder: {
            heading: '¿Intentando crear una nueva pista?',
            bodyPrimary:
              'Introduce el Título de tu pista para crear una nueva pista, el Artista es opcional. Una vez que hayas añadido el título, puedes añadir los titulares de derechos o añadirlos más tarde en la pantalla de detalles de la Pista.',
            bodySecondary: 'Haz clic en Volver a Buscar para encontrar pistas existentes.',
          },
          cuePopover: {
            usageDetails: 'Detalles de uso',
            dontShowAgain: 'No volver a mostrar',
            duration: 'Duración',
            next: 'Siguiente',
            setDuration: 'Especifique duración y uso',
            use: 'Uso',
            fullUse: 'Duración de Uso Completo',
          },
          hint: {
            title: 'Buscá y agregá una canción',
            subtitle:
              'Buscá en qwireTracks usando el buscador, y seleccioná la canción que desees agregar. Podés agregar tantas canciones como quieras.',
            legend: 'Si no puede encontrar tu canción, puedes agregarla con el botón de "Crear nueva canción"',
          },
          headers: {
            add: 'Agregar',
            artist: 'Artista',
            completeness: 'Completitud',
            duplicate: 'Duplicar',
            lastUpdated: 'Última Actualización',
            title: 'Título',
            trackId: 'ID de la pista',
            type: 'Tipo',
            writers: 'Escritor(es)',
            dataCompletenessHigh: 'Integridad de los datos: Alto',
            dataCompletenessLow: 'Integridad de los datos: Bajo',
            dataCompletenessMedium: 'Integridad de los datos: Medio',
            verified: 'VERIFICADO',
            totalCountSong: '{{totalCount}} pista(s) comerciales encontradas',
            totalCountLibrary: '{{totalCount}} pista(s) de la biblioteca encontradas',
            noResults: 'Tu búsqueda no encontró coincidencias',
            preCleared: 'PRE-AUTORIZADO',
          },
          details: {
            inProduction: 'En Producción',
            version: 'Versión',
            newPerformance: 'Crear Nueva Performance',
            trackDetails: 'DETALLES DE CANCIÓN',
            album: 'Álbum',
            artist: 'Artistas',
            writer: 'Escritor',
            duration: 'Duración',
            explicit: 'Explícito',
            label: 'Etiqueta',
            master: 'Maestro',
            pro: 'PRO',
            sync: 'Sync',
            role: 'Rol',
            share: 'Compartido',
            title: 'Título',
            verifiedByPublisher: 'VERIFICADO',
            library: 'Nombre de la librería',
            subLibraryName: 'Nombre de la sub-librería',
            libraryTrackId: 'Id de pista de biblioteca',
            albumDescription: 'Descripción del album',
            catalogNumber: 'Número de catálogo',
            subLibraryId: 'Id de sublibrería',
            trackDescription: 'Descripción de pista',
            albumId: 'Id de álbum',
            subAlbumId: 'Sub-Album ID',
            trackNumber: 'Track Number',
            trackIndex: 'Track Index',
            iswc: 'ISWC',
            isrc: 'ISRC',
            masterLicensor: 'Licenciante Principal',
            syncLicensor: 'Licenciante de Sincronización',
            publisher: 'Editor',
          },
          createTrack: {
            addTrack: 'Agregar Canción',
            duplicateTrack: 'Duplicar Canción',
            helpfulDetail: 'Podés modificar los detalles de una canción en los detalles de la Autorización',
            helpfulHint: 'Tips utiles:',
            newPerformance: 'Nueva Performance',
            newTrack: 'Crear Nueva Canción',
            form: {
              songType: 'Comercial',
              trackDetails: 'Detalles de la pista',
              scene: 'Escena',
              add: 'Agregar nuevo',
              addTrackTo: 'Agregar a:',
              noResults: 'Sin resultados',
              title: 'Titulo',
              artist: 'Artista',
              type: 'Tipo',
              savePerformance: 'Guardar y agregar nueva performance',
              saveTrack: 'Guardar y agregar canción',
              libraryType: 'Tipo de Libreria',
            },
            scenePicker: {
              label: 'Producción',
              disclosureButton: 'Seleccionar escena/recurso',
              cueContainerRequired: 'Seleccionar una escena/recurso dentro de una producción',
              noResults: 'No se encontraron coincidencias',
              noAssetSelected: 'Seleccione una escena/activo para agregar a una producción.',
              searchPlaceholder: 'Encuentra una escena/recurso dentro de una producción',
            },
          },
          createLicensors: {
            disclosureButton: 'Agregar titulares de derechos',
            licensors: 'Licenciantes',
            master: 'Master',
            one_stop: 'Una Parada',
            sync: 'Sync',
            other: 'Otro',
            writers: 'Escritores',
            writer: 'Escritor',
            publishers: 'Editores',
            masterLicensor: 'Licenciante Maestro',
            syncLicensor: 'Licenciante Sync',
            licensorNameRequired: 'Ingrese un nombre para agregar un licenciante',
            publisher: 'Editor',
            licensorName: 'Nombre del Licenciante',
            totalShare: 'Total de acciones',
            form: {
              save: 'Guardar',
              successMessage: 'Licenciantes creados satisfactoriamente',
              master: {
                name: 'Nombre del licenciante Master',
              },
              sync: {
                name: 'Nombre del licenciante Sync',
              },
              one_stop: {
                name: 'Nombre del licenciante Una Parada',
              },
              other: {
                name: 'Nombre del licenciante Otro',
              },
            },
          },
          notification: {
            addTrack: 'Se agregó una canción',
            createTrack: 'La canción: {{name}} ha sido creada',
          },
        },
      },
      firstNameLastName: {
        placeholderFirstName: 'Nombre',
        placeholderLastName: 'Apellido',
      },
      nameForm: {
        placeholder: 'Nombre',
      },
      fileAttachments: {
        drawerTitle: 'Archivo adjunto',
        fileName: 'Nombre del archivo',
        fileType: 'Tipo de archivo',
        clearance: 'Autorización',
        licensor: 'Licenciante',
        qclearLicensorStatusId: 'Estado del Licenciante',
        quoteExpiration: 'Dias hasta que expire',
        description: 'Descripción',
        bundled: 'Agrupado',
        fileAttachedTo: 'Archivo adjunto a {{title}}',
        chooseFileName: 'Elije un archivo',
        chooseFileType: 'Elija un nombre de archivo',
        chooseClearance: 'Elija un espacio libre',
        chooseStatus: 'Elija un estado',
        noLicensor: 'Sin licenciante',
        uploadText:
          '<0> PARA CARGAR UN ARCHIVO </0> <1> ARRASTRAR Y BAJAR </1> <2> O HAGA CLIC PARA ABRIR EL VISOR DE ARCHIVOS </2>',
        confirmDismiss: '¿Estás seguro de que deseas cancelar este archivo adjunto?',
      },
      settings: {
        rights: {
          territory: {
            title: 'Selecionar un Territorio',
            presetName: 'Nombre Preestablecido',
            populateLetter: 'Rellenar Letra como',
          },
        },
      },
      contacts: {
        drawerTitle: 'Nuevo contacto',
        drawerEditTitle: 'Editar contacto',
        drawerViewTitle: 'Ver contacto',
        form: {
          firstName: 'Nombre de contacto',
          lastName: 'Apellido del contacto',
          companyName: 'Nombre de empresa',
          licensor: 'Licenciante',
          rightsOwnerType: 'Tipo de Propietario de Derechos',
          selectType: 'Seleccione un tipo...',
          communication: 'Comunicación',
          email: 'Email',
          type: 'Tipo',
          number: 'Número',
          assistantFirstName: 'Asistente Nombre',
          assistantLastName: 'Apellido del asistente',
          addressLine1: 'Dirección Línea 1',
          addressLine2: 'Dirección línea 2',
          city: 'Ciudad',
          state: 'Estado / Probar',
          zipCode: 'Código postal',
          country: 'País',
          payee: {
            title: 'Información del beneficiario',
            type: {
              label: 'Tipo',
              company: 'Empresa',
              individual: 'Individual',
            },
            companyName: 'Nombre de Empresa',
            dba: 'DBA',
            tin: 'TIN #',
          },
        },
      },
      clearance: {
        rightsPresetPicker: {
          label: 'Derechos Predeterminados',
          placeholder: 'Seleccionar',
          createCustom: 'Crear nuevo',
        },
        fullList: {
          name: 'Nombre',
          asset: 'Material',
          scene: 'Escena',
          sceneNumber: 'Escena #',
          assetNumber: 'Material #',
          sceneName: 'Nombre de la Escena',
          assetName: 'Nombre del Material',
          using: 'Usando',
          reqUsage: 'Uso Requerido',
          actUsage: 'Uso Actual',
          feeAllocation: 'Tarifa',
          title: 'Manejo de Pista',
          activateConfirmation: '¿Seguro quieres activar {{name}}?',
          deactivateConfirmation: '¿Seguro quieres desactivar {{name}}?',
          deactivated: '"{{name}}" fue desactivado',
          activated: '"{{name}}" fue activado',
          deleteConfirmation: '¿Seguro quieres eliminar {{name}}?',
          deleted: '"{{name}}" fue eliminado',
          deleteClearance: 'Borrar Autorización',
          editClearance: 'Editar Autorización',
          deleteCue: 'Borrar Pista',
          makeActive: 'Activar',
          makeInactive: 'Desactivar',
          newOption: 'Nueva Opción',
          newClearance: 'Nueva Autorización',
        },
        addEdit: {
          drawerTitle: 'Nueva Autorización',
          drawerEditTitle: 'Editar Autorización',
          form: {
            name: 'Nombre de la Autorización',
          },
          setToOneHundred: 'Cambiar a 100%',
          totalFees: 'Total de Tarifa',
          clearanceGrandTotal: 'Total de la Autorización',
          emptyCuesMessage: 'No hay Pistas activas para mostrar',
          sceneNumber: 'Escena #',
          assetNumber: 'Material #',
          sceneName: 'Nombre de la Escena',
          assetName: 'Nombre del Material',
          using: 'Usando',
          usage: 'Uso',
          associatedFee: 'Tarifa asociada',
          percentage: 'Porcentaje',
          requiredRights: 'Derechos de Autorización',
          sceneOrAsset: 'Escena o Material',
        },
        option: {
          drawerTitle: 'Nueva Opción',
          drawerEditTitle: 'Editar Opción',
          territoryTitle: 'Territorio',
          form: {
            name: 'Nombre de la opción',
            date: 'Fecha',
            start: 'Fecha de inicio',
            end: 'Fecha de fin',
            fee: 'Tarifa de la opción',
            duration: 'Duración',
            use: 'Uso',
            requested: 'Solicitado',
            details: 'Detalles',
            fullUse: 'Duración de Uso Completo',
          },
        },
        excercise: {
          drawerTitle: 'Ejercitar Opción',
          form: {
            name: 'Nombre de la Opción',
            createClearance: 'Create Autorización',
            addToClearance: 'Agregar a Autorización',
            fee: 'Tarifas',
            territory: 'Territorio',
            dateRange: 'Rango de fechas',
            associatedCues: 'Pistas asociadas',
          },
        },
        validations: {
          allocationsMax: 'La suma de el porcentaje alocado en las pistas no puede ser mayor a 100%',
          allocationsMin: 'La suma de el porcentaje alocado en las pistas no puede ser menor a 0%',
        },
      },
      versionManagerDuplicate: {
        copy: 'Copiar',
        drawerTitle: 'Duplicar Versión',
        versionTitle: 'Título de la Versión',
        assetTitle: 'Título del Material',
        assetType: 'Tipo del Material',
        assetRequiredRight: 'Derechos de Producción para el Material',
        currentVersion: 'Versión Actual',
        version: 'Versión #',
        duration: 'TRT',
        currentAsset: 'Material Actual',
        newAsset: 'Nuevo Material',
        unsavedWarning: 'No puedes crear un Material nuevo si tienes cambios sin guardar en la versión actual',
        notes: 'Notas',
        items: {
          finishingVendors: 'Proveedores del Finalizador',
          finishers: 'Finalizador',
          creativeVendors: 'Proveedores Creativos',
        },
      },
      scenarioManagerAddEdit: {
        scenarioTitle: 'Título del Escenario',
        description: 'Descripción',
        scenarioDetails: 'Nueva Versión',
        lastUpdatedBy: 'Actualizado última vez por {{name}}',
        createdBy: 'Creado por {{name}}',
      },
      versionManagerAddEdit: {
        createdBy: 'Creado por',
        lastUpdatedBy: 'Actualizado última vez por',
        delete: 'Borrar',
        versionTitle: 'Título de la Versión',
        drawerTitle: 'Nueva Versión',
        drawerEditTitle: 'Editar Versión',
        version: 'Versión #',
        duration: 'TRT',
        currentVersion: 'Versión Actual',
        notes: 'Notas',
        confirmDelete: '¿Seguro quieres Borrar?',
        items: {
          finishingVendors: 'Proveedores de Finalización',
          finishers: 'Finalizadores',
          creativeVendors: 'Proveedores Creativos',
        },
        notifications: {
          update: {
            success: 'Versión Actualizada!',
          },
          create: {
            success: 'Versión Creada!',
          },
        },
      },
      completeMaterial: {
        title: 'Activo completo',
        finishes: 'Finalizado',
        assetTitle: 'Título del activo',
        version: 'Versión actual',
        chooseAction: 'Elegir Acción',
        use: 'Usar',
        deactivate: 'Desactivar',
        materialFees: 'Costo del Material',
        feesApply: 'Aplicar Costos',
        notes: 'Notas',
        dateUsed: 'Fecha de uso',
        finisher: 'Finalizador',
        addFinisher: 'Agregar Finalizador',
        isci: 'ISCI #',
        finishingDate: 'Fecha de finalización',
        confirmCancel: '¿Seguro quieres cancelar?',
        tooltip: 'Finalizadores disponibles desde la configuración de Detalles del Material',
        update: {
          success: 'Material editado exitosamente',
        },
        complete: {
          success: 'Material completado exitosamente',
        },
      },
      tasks: {
        create: {
          createTask: 'Agregar Tarea',
          editTask: 'Editar Tarea',
          task: 'Tarea',
          form: {
            subject: 'Sujeto',
            recipientUserId: 'Asignado',
            dueDate: 'Fecha de vencimiento',
            description: 'Descripción (opcional)',
            requestor: 'Solicitante',
            createdDate: 'Fecha de creación',
          },
          comments: 'Comentarios',
          noComments: 'No hay comentarios para mostrar',
          addComment: 'Agregar Comentario',
          notification: {
            complete: {
              success: 'La Tarea se ha completado exitosamente!',
              failed: 'Ha ocurrido un error al querer actualizar la tarea',
            },
            uncomplete: {
              success: 'La Tarea se ha marcado como incompleta',
              failed: 'Ha ocurrido un error al querer actualizar la tarea',
            },
          },
        },
      },
      inviteUsers: {
        buttonInvite: 'Invitar',
        descriptionHint:
          'Las personas son invitadas a qwireSuite a través de su dirección de correo electrónico. Para invitar a varias personas a la vez, agregue varias direcciones de correo electrónico a continuación',
        drawerTitle: 'Invitar Usuarios a la División',
        labelUserEmails: 'Emails de usuario',
        notifications: {
          multipleInvite: {
            success: 'Todas las invitaciones fueron enviadas exitosamente',
            failed: 'Ha ocurrido un error al enviar alguna de las Invitaciones. Por favor inténtelo nuevamente',
          },
        },
        repeatedOnDrawer: 'Email está duplicado en este formulario',
        repeatedOnDivision: 'Email ya existe. Por favor revisa la lista de usuarios',
      },
      shareWithUser: {
        drawerTitle: 'Compartir con un Usuario',
        divisionAdmin: 'Administrador de division',
        roleChanged: 'Rol cambiado con éxito',
        changingRoleFailed: 'Error al intentar cambiar el rol de usuario',
        accessRemoved: 'Acceso eliminado con éxito',
        removingAccessFailed: 'Error al intentar eliminar el acceso',
        productionUsersTable: {
          user: 'Usuario',
          email: 'Email',
          role: 'Rol',
        },
        labels: {
          user: 'Usuario',
          users: 'Usuarios',
          permissions: 'Permisos',
          role: 'Rol',
          searchUserPlaceholder: 'Empezar a escribir...',
        },
        notifications: {
          cancel: 'Cancelar',
          ok: 'OK',
          seasonEpisodePrompt: 'Al invitar a los usuarios a este episodio, les otorga acceso a toda la temporada.',
          seasonReleasePrompt: 'Al invitar a los usuarios al lanzamiento, les otorga acceso a toda la temporada.',
          singleInvite: {
            success: 'Invitación enviada exitosamente',
            failed: 'Ha ocurrido un error al enviar la Invitación. Por favor inténtelo nuevamente',
          },
        },
        placeholders: {
          searchByName: 'Buscar por nombre',
        },
      },
      usageNotes: {
        drawerTitle: 'Otros Usos',
        label: 'Descripción del Uso',
        notifications: {
          success: 'Uso actualizado Correctamente!',
          failed: 'Algo ocurrió actualizando el uso, por favor intente nuevamente!',
        },
      },
      rightPreset: {
        customRight: 'Derecho Personalizado',
        hints: {
          [RIGHTS_HINT_MESSAGE_TYPE.PRODUCTION]: 'Los cambios solo van a afectar los Derechos para esta Producción',
          [RIGHTS_HINT_MESSAGE_TYPE.SEASON]:
            'Los cambios solo van a afectar los Derechos para las Producciones en esta Serie',
          [RIGHTS_HINT_MESSAGE_TYPE.SEASON_RELEASE]:
            'Los cambios solo van a afectar los Derechos para las Producciones de este Lanzamiento',
          [RIGHTS_HINT_MESSAGE_TYPE.CLEARANCE]:
            'Los cambios solo van a afectar los Derechos Pedidos para esta Autorización',
        },
      },
      changeClearanceRep: {
        title: 'Agregar Asignado',
      },
      clearanceUsesAndDurations: {
        title: 'Agregar Uso',
        reqDuration: 'Duración Pedida',
        reqUsage: 'Uso Pedido',
        actDuration: 'Duración Real',
        actUsage: 'Uso Real',
        addNew: 'Agregar Nuevo',
        actualUsageNotes: 'Uso Real Descripción',
        requestedUsageNotes: 'Uso Pedido Descripción',
        reqUseAndDuration: 'Uso y Duración Solicitados',
        actUseAndDuration: 'Uso y Duración Real',
        scene: 'Escena',
        moveMessage: 'El uso ha sido movido a {{scene}}.',
      },
      clearanceOtherUseAndDuration: {
        drawerTitle: 'Otro',
        usageNotes: 'Descripción',
      },
      licensingFilters: {
        title: 'Filtros',
        division: 'División',
        production: 'Producción',
        track: 'Canción',
        licensor: 'Licenciante',
        enterProductionName: 'Ingrese el nombre de la Producción',
        enterTrackName: 'Ingrese el nombre de la Canción',
        enterLicensorName: 'Ingrese el nombre del Licenciante',
        status: 'Estado',
        clearFilters: 'Borrar Filtros',
        promptClearFilters: '¿Seguro quiere borrar todos los filtros?',
        productionType: 'Tipo de Producción',
      },
      payeesAddEdit: {
        addTitle: 'Agregar Receptor',
        editTitle: 'Editar Receptor',
        delete: 'ELIMINAR RECEPTOR',
        companyName: 'Nombre de la Compañia',
        name: 'Nombre',
        share: 'Porcentaje del Pago',
        firstName: 'Nombre del Receptor',
        lastName: 'Apellido del Receptor',
        email: 'Email del Receptor',
        address1: 'Dirección línea 1',
        address2: 'Dirección línea 1',
        city: 'Ciudad',
        state: 'Estado',
        zipCode: 'Código Postal',
        country: 'País',
        tin: 'TIN #',
        dba: 'DBA',
        vendorNumber: 'Número de Proveedor',
        deletePrompt: '¿Seguro quiere eliminar el Receptor?',
        notifications: {
          update: 'Receptor editado con éxito',
          create: 'Receptor creado con éxito',
          delete: 'Receptor eliminado con éxito',
        },
        updateContactPrompt: 'Está seguro de que desea actualizar la información de contacto?',
      },
      bundle: {
        title: 'Ver Pistas Agrupadas',
        unBundle: 'DESAGRUPAR',
        titleArtist: 'Título de la canción / Artista',
        production: 'Producción',
        usage: 'Uso',
        cues: 'PISTAS',
      },
      cueRightOwnersPreset: {
        titleSave: 'Guardar Plantilla',
        titleApply: 'Aplicar Plantilla',
        newPresetLabel: 'Nombre de la Nueva Plantilla',
        overwritePreset: '¿Estás seguro de que deseas sobreescribir esta plantilla?',
      },
      cueSheetExport: {
        title: 'Exportar',
        selectFileType: 'Seleccionar El Tipo de Archivo',
        includeNotes: 'Incluir Notas',
        addSegmentInfo: 'Incluir Información de Segmento',
        cueSheet: 'Lista de Pistas',
      },
      cueSheetSync: {
        title: 'Comparar con una Autorización',
        fallback: 'No hay canciones para sincronizar',
        changes: '{{count}} CAMBIO',
        changes_plural: '{{count}} CAMBIOS',
        howTo1:
          'Elige si deseas sincronizar la informacion en la Lista de Pistas con la informacion en la Autorización. Para cada fila',
        howTo2: 'Seleccionar "Información de la Autorización" copiará la información de la canción a la pista',
        howTo3: 'Seleccionar "Información de la Lista de Pistas" no copiará ningún cambio',
        trackItem: {
          missingCue: 'Sin Combinación',
          reqUse: 'Uso req:',
          actualUse: 'Uso Real',
          writers: 'Escritor',
          publishers: 'Editor',
          artists: 'Artista:',
          outOfSyncWithClearance: 'Fuera de sincronía con Autorización',
          outOfSyncWithCueSheet: 'Fuera de sincronía con Cue Sheet',
          requested: 'SOLICITADO',
          actual: 'EFECTIVO',
        },
        warning:
          'Por favor ejecute la acción de Comparar con una Autorización en el menu de acción de la lista de pistas.',
        outOfSyncMsg: 'Esta hoja de pistas esta desincronizada con la Autorización',
        selectAll: 'SELECCIONAR TODO',
        clearData: 'Información de Autorización',
        cueData: 'Información de Pista',
      },
      cueSheetCompare: {
        qTracksData: 'Información de qTracks',
        cueSheetData: 'Información de la lista de pistas',
        title: 'Sincronizar con QwireTracks',
      },
      cueSheetSegment: {
        segmentNameLabel: 'Nombre del Segmento',
        editTitle: 'Editar Segmento',
        createTitle: 'Crear Segmento',
        removeSegmentLabel: 'Quitar Segmento',
      },
      quickSetupProduction: {
        drawerHowCreateProjectTitle: 'Haga clic para aprender cómo crear un proyecto',
        drawerDefaultTitle: 'Crear Producciones',
        drawerExportSegmentsTitle: 'Exportar Segmentos',
        campaign: 'Paso 4: ingrese el nombre de la campaña',
        release: 'Paso 4: ingrese el nombre de la versión',
        releaseTV: 'Paso 5: ingrese el nombre de la versión',
        requiredProduction: 'Requerido para crear producción',
        cueSheetOnly: 'Solo hoja de referencia',
        clearanceOnly: 'Sólo liquidación',
        releaseToolTip:
          'El nivel de lanzamiento permite separar diferentes ' +
          'proyectos/temporadas si se necesita aprobarlas de manera diferente. ' +
          'Por ejemplo, puedes tener un lanzamiento en US y uno Internacional. ' +
          'La mayoria de los usuarios pueden usar el valor por default e ignorar ' +
          'este nivel',
        releaseRequiredRights: 'Derechos de Producción',
        releasePlaceholder: 'Lanzamiento Mundial',
        episode: 'Episodio',
        episodeNumber: 'Número de episodio',
        episodeName: 'Nombre Episodio',
        season: 'Paso 4: ingrese el número de temporada',
        seasonRequiredRights: 'Derechos Producción',
        synopsis: 'Sinopsis',
        category: 'Categoria Pred.',
        duration: 'Duración Pred.',
        watchProduction: 'Agregar Producción al Tablero',
        whereNext: {
          title: '¿A donde quieres ir?',
          continueToNew: 'Continuar a nueva producción',
          continueToExisting: 'A los segmentos exportados',
          goBack: 'Volver a producción',
        },
        exportSegmentSteps: {
          campaign: 'Campaña',
          release: 'Lanzamiento',
          season: 'Temporada',
          exportToLabel: 'Exportar para',
          exportToAssetLabel: 'Exportar para Material de Producción',
          existingOnly: 'Para exportar segmentos a una campaña de marketing, elija un proyecto existente.',
          export: 'Exportar',
          noAssetsMessage: 'Esta campaña de marketing no tiene ningún material aparte del contenedor no asignado.',
          noEpisodesMessage: 'Esta campaña de marketing no tiene ningún episodio.',
        },
        addNewEpisode: 'AÑADIR EPISODIO',
        oneOrMoreEpisodes: 'Paso 6: agregue uno o más episodios',
      },
      customStatuses: {
        title: 'Estados',
        edit: 'Editar',
        save: 'Guardar',
      },
      rightsDrawer: {
        title: 'Editar Derechos',
        newRights: 'Novos Derechos',
        newOption: 'Nueva Opción',
        editOption: 'Editar Opción',
        rightsPreset: 'Derechos Preestablecidos',
        rightsName: 'Nombre',
        rightsNamePlaceholder: 'Ingrese un nombre',
        descriptionOnLetters: 'En Carta Como',
        addTerritoryAndTerm: 'Agregar Territorio y Término',
        removeTerritoryAndTerm: 'Quitar Territorio y Término',
        territory: 'Territorio',
        territoryPlaceholder: 'Seleccione un territorio',
        term: 'Término',
        termPlaceholder: 'Seleccione un plazo',
        start: 'Fecha de inicio',
        end: 'Fecha de fin',
        otherDetails: 'Detalles',
        to: 'a',
        rightsSaved: 'Derechos guardados correctamente',
        optionsSaved: 'Opción guardados correctamente',
        failed: 'Hubo un error',
      },
      unionDrawer: {
        addTitle: 'Agregar Union',
        editTitle: 'Editar Union',
        name: 'Union',
        status: 'Estado',
        useType: 'Tipo',
        fee: 'Tarifa',
        feeCoveredByLabel: 'Paga la etiqueta',
        notes: 'Notas',
        unionSaved: 'Union guardada correctamente',
      },
    },
    login: {
      title: 'Bienvenido a Qwire',
      content: 'Por favor, inicie sesión en su cuenta.',
      logIn: 'Iniciar Sesión',
      lockedContentFirst: 'Su pantalla ha sido bloqueada por motivos de seguridad',
      lockedContentSecond: 'Por favor vuelva a iniciar sesión para resumir la misma.',
      loginError: 'Hubo un error al iniciar sesión',
      trademark: '©{{currentYear}} Qwire Inc. Todos los Derechos Reservados.',
    },
    episodeDetail: {
      inactive: 'INACTIVE',
    },
    productionDetail: {
      inactive: 'INACTIVE',
    },
    account: {
      title: 'Crear cuenta',
      subtitle: 'Has sido invitado a unirte a la familia Qwire. Ingrese su nombre para comenzar.',
      partner: {
        firstSubtitle: 'Qwire te habilita trabajar con trabajos episodicos y no-episodicos',
        secondSubtitle: 'Por favor, complete esta pagina para usar qwireSuite',
        fieldsSectionText1: '¿Para qué tipo de proyectos se usará Qwire?',
        fieldsSectionText2: 'Sugerimos aceptar los nombres por defecto. Siempre se podran cambiar a futuro',
        fieldsSectionHint: '(al menos 1 es requerido)',
        footer: 'Si ya tenés una cuenta en Qwire, podes usarla con {{partner}} para completar este formulario. ',
        login: 'De lo contrario, vaya directo a Iniciar Sesión.',
        divisionError: 'La división ya existe',
        signUpComplete: 'Registro completado',
        signUpCompleteEmailSent: 'Un email de verificación ha sido enviado',
        signUpCompleteInbox: 'Por favor, verifique su bandeja de entrada.',
        emailConfirmedQuestion: '¿Email confirmado?',
        emailNotReceived:
          '¿No has recibido el email de verificación? Chequeá la bandeja Junk. Podés contactarnos envíandonos un mail a support@qwire.com',
      },
      fields: {
        firstName: 'Nombre',
        lastName: 'Apellido',
        password: 'Contraseña',
        confirmPassword: 'Confirmar Contraseña',
        email: 'Correo electrónico',
        seriesEpisodic: 'Series (Episodic)',
        nonEpisodic: 'Non-Episodic',
      },
      validations: {
        password: {
          min: 'Debe tener {{min}} o más caracteres con una mezcla de letras, numeros y simbolos',
          matches: 'Las contraseñas no coinciden. Por favor, intentalo nuevamente',
        },
      },
      successMessage: 'Registro de usuario completado!. Bienvenido a qwireSuite',
      haveAccount: '¿Ya tienes una cuenta?',
      login: 'Conectarse',
      continue: 'Continuar',
    },
    apmSignup: {
      accept1: 'Al crear una cuenta, acepta nuestros',
      accept2: 'ha leído y aceptado la',
      createAccount: 'Crear Cuenta',
      createAccountDescription: 'Crea tu cuenta o',
      login: 'inicia sesión',
      terms: 'Términos',
      globalPrivacy: 'Declaración de Privacidad Global',
      title: 'Regístrese en Qwire',
      firstName: 'Primer Nombre',
      lastName: 'Apellido',
      email: 'Email',
      companyName: 'Nombre de Empresa',
      password: 'Contraseña',
      signUpCompleted: 'Gracias por registrarse en Qwire. Ahora puede iniciar sesión con sus nuevas credenciales.',
      oneLower: 'Un carácter en minúscula',
      oneUpper: 'Un carácter en mayúscula',
      oneNumber: 'Un número',
      oneSpecial: 'Un carácter especial',
      atLeast8Char: '8 caracteres como mínimo',
    },
    projects: {
      welcome: 'Bienvenido',
      title: 'Proyectos',
      newProduction: {
        cancel: 'Cancelar',
        createNew: 'Crear Nuevo',
        division: 'Elegir División',
        next: 'Siguiente',
        production: 'Nombre de la Producción',
        title: 'Nueva Producción',
        projectTitle: 'Paso 3: ingrese el nombre del proyecto',
        divisionTitle: 'Paso 1: seleccione una división',
        productionType: 'Paso 2: seleccione un tipo de producción',
        divisionDescription: {
          belongMoreThanOne: 'Si perteneces a más de una división, selecciona la división para esta producción.',
          belongOnlyOne: 'Si solo perteneces a una división, continúa con el paso 2.',
        },
        productionDescription: {
          features: 'Un proyecto "único" y no episódico, como un largometraje. Una característica tiene un único conjunto de derechos y una única hoja de referencia.',
          marketing: 'Una campaña de marketing contiene muchos activos (por ejemplo, avances, promociones, etc.). Cada activo tiene su propio conjunto de derechos y su propia hoja de referencia.',
          tv: 'Un grupo de producciones relacionadas, como una serie con múltiples episodios. Un proyecto de televisión contiene una temporada, el lanzamiento de una temporada y uno o más episodios. Cada episodio tiene un conjunto de derechos y una hoja de referencia.',
        },
        projectDescription: {
          createNewProject: 'Para crear un nuevo proyecto, ingrese el nombre de su proyecto.',
          addAdditionalProduction: 'Para agregar producciones adicionales a un proyecto existente (por ejemplo, nuevos episodios), seleccione el proyecto en el menú desplegable.',
          viewOrEditProject: 'Si está intentando ver o editar el proyecto, cierre este cajón y haga clic en el botón "Buscar proyecto" para localizar el proyecto.',
        },
      },
      tabs: {
        watching: {
          title: 'Observando ({{count}})',
        },
        files: {
          title: 'Archivos',
        },
        trackHistory: {
          title: 'Historial de canciones',
        },
      },
      productions: 'Producciones',
      newProject: 'Agregar Proyecto',
      projectHierarchy: {
        noResults: 'Para agregar tu primer proyecto, hacé click en el botón de Agregar Proyecto.',
        title: 'Título de la Producción',
        mixDate: 'Fecha Mezclada',
        airReleaseDate: 'Fecha de Lanzamiento',
        actions: 'Acciones',
        Season: 'Temporada',
        menuButtons: {
          viewEditSeason: 'Ver/Editar Detalles de la Temporada',
          viewEditRelease: 'Ver/Editar Detalles del Lanzamiento',
          viewEditProject: 'Ver/Editar Detalles del Proyecto',
          viewEditProduction: 'Ver/Editar Detalles de la Producción',
          add: 'Agregar {{division}}',
          addRelease: 'Agregar Lanzamiento',
          addEpisode: 'Agregar Episodio',
          share: 'Compartir',
          archive: 'Archivar',
          unwatch: 'Dejar de observar producción',
          watch: 'Observar producción',
          notifications: 'Notificaciones',
          notification: {
            watch: 'Producción observada',
            unwatch: 'Producción dejada de observar',
          },
          error: 'Error',
          features: 'Agregar Lanzamiento',
          television: 'Agregar Temporada',
          marketing: 'Agregar Campaña',
        },
      },
      watchingTab: {
        noResults:
          '<0>No estás observando niguna producción.</0><1>Pudes observar producciones clickeando </1> <2/> <3>junto a cualquier nombre de producción.</3>',
        unwatch: '¿Borrar de la lista de observaciones?',
        notifications: {
          unwatched: 'Producción eliminada de la lista de observaciones',
        },
        filters: {
          clearance: 'Autorización',
          cueSheet: 'Grilla de Pistas',
          findProject: 'Buscar Proyecto',
          licensing: 'Licenciando',
          projectName: 'Nombre del Proyecto',
          releaseDate: 'Fecha de Lanzamiento',
          sortBy: 'Ordenar por',
          status: 'Estado',
        },
        labels: {
          actions: {
            addEpisode: 'Agregar episodio',
            addAsset: 'Agregar material',
            archive: 'Archivar',
            notifications: 'Notificaciones',
            share: 'Compartir',
            viewDetails: 'Ver detalles',
            unwatchProduction: 'Dejar de observar producción',
            activateProduction: 'Activar Producción',
            inactivateProduction: 'Desactivar Producción',
            activateEpisode: 'Activar Episodio',
            inactivateEpisode: 'Desactivar Episodio',
          },
          asset: {
            assets: 'MATERIALES ({{count}})',
            countPending: '({{count}} Pendiente)',
            countPending_plural: '({{count}} Pendientes)',
            count: '{{count}} Material',
            count_plural: '{{count}} Materiales',
            creativeVendor: 'Proveedor creativo',
            fees: 'Total de tarifas aprobadas',
            title: 'Título del material',
            type: 'Tipo del material',
            versionNumber: 'Ver #',
            versionName: 'Nombre de la versión',
          },
          airReleaseDate: 'Fecha de Lanzamiento/Aire',
          assetsPending: 'Pendiente',
          budget: {
            productionBudget: 'Presupuesto de producción',
            committedFee: 'Tarifa comprometida',
            uncommittedFee: 'Tarifa no comprometida',
          },
          cueStatus: {
            new: 'Nuevo',
            inProgress: 'En Prog',
            cleared: 'Validado',
            confirmed: 'Confirmado',
            licensed: 'Autorizado',
            other: 'Otro',
            tracksTotal: 'Canciones en total',
          },
          productionStatus: 'Estado',
          quotesExpiring: 'Cotizaciones que vencen',
          licensesExpiring: 'Licencias que vencen',
          checksNotPaid: 'Cheques sin pagar',
          status: 'Estado',
          cuesWithErrors: 'Pistas con Errores',
          mixDate: 'Fechas Mezcladas',
          totalCues: 'Pistas Totales',
          incompleteCues: 'Pistas Incompletas',
        },
      },
    },
    importCueSheet: {
      title: 'Importar Planilla',
      stepper: {
        1: 'Elegir Archivo',
        2: 'Elegir Cabecera',
        3: 'Elegir Información',
        4: 'Mapear Datos',
        5: 'Comparación qwireTracks',
        6: 'Vista previa',
        7: 'Importar',
      },
      hints: {
        hint: 'Consejo: ',
        2: 'Hacé click en la fila que contiene las columnas, y luego hacé click en el botón continuar.',
        3: 'Hacé click en la primera fila que contiene información de pista, y luego hacé click en el botón continuar',
        4: 'Seleccioná una columa de tu archivo, y luego hacé click en los campos de la lista que coincidan. Hacé esto para cada columna.',
        5: 'Hemos encontrado información coincidente en nuestra base de datos de qwireTracks. Seleccione las canciones que deseas importar utilizando información de qwireTracks.',
        6: 'La tabla de abajo es una vista previa de tu información a importar. Hacé click en el botón de volver para corregir cualquier error, o hacé click en importar para proceder con la importación.',
      },
      mapData: {
        loadingCompare:
          'Espere un momento mientras buscamos qwireTracks para cualquier potencial coincidencia. Si conseguimos una, puedes elegir importar la información que ya tenemos en qwireTracks.',
        availableFields: 'Campos Disponibles',
        clearFields: 'Limpiar Campos',
        importPreview: 'Vista previa importación',
        selectedFields: 'Campos Seleccionados',
        selectedFieldsHint:
          'Clickeá los campos a la izquierda que coincidan con la información en la celda seleccionada.',
        selectToStart: 'Debes seleccionar una columna para empezar a mapear campos',
        goBackNotification: 'Borrarás todas las columnas mapeadas. ¿Deseas continuar?',
        unmappedNotification: 'No has mapeado todas las columnas. ¿Estás seguro que quieres continuar?',
        usage: {
          ignore: 'Ignorar',
          importedUsage: 'Uso Importado',
          mapTo: 'Mapear a',
        },
        usageMapping: 'Mapeo de usos',
        fields: {
          general: 'General',
          timing: 'Tiempo',
          rightsHolders: 'Derechos',
          dividers: 'Separadores',
          [IMPORT_FIELDS.CUE_NUMBER]: 'Número de Pista',
          [IMPORT_FIELDS.CUE_TITLE]: 'Título de Pista*',
          [IMPORT_FIELDS.USAGE]: 'Uso',
          [IMPORT_FIELDS.ARTIST]: 'Artista',
          [IMPORT_FIELDS.TRACK_ID]: 'Cancion ID',
          [IMPORT_FIELDS.TIME_IN]: 'Tiempo Entrada',
          [IMPORT_FIELDS.TIME_IN_HH]: 'Tiempo Entrada HH',
          [IMPORT_FIELDS.TIME_IN_MM]: 'Tiempo Entrada MM',
          [IMPORT_FIELDS.TIME_IN_SS]: 'Tiempo Entrada SS',
          [IMPORT_FIELDS.TIME_OUT]: 'Tiempo Salida',
          [IMPORT_FIELDS.TIME_OUT_HH]: 'Tiempo Salida HH',
          [IMPORT_FIELDS.TIME_OUT_MM]: 'Tiempo Salida MM',
          [IMPORT_FIELDS.TIME_OUT_SS]: 'Tiempo Salida SS',
          [IMPORT_FIELDS.DURATION]: 'Duración',
          [IMPORT_FIELDS.DURATION_MM]: 'Duración MM',
          [IMPORT_FIELDS.DURATION_SS]: 'Duración SS',
          [IMPORT_FIELDS.ROLE]: 'Rol',
          [IMPORT_FIELDS.WRITER]: 'Escritor',
          [IMPORT_FIELDS.WRITER_PRO]: 'Escritor PRO',
          [IMPORT_FIELDS.WRITER_SHARE]: 'Escritor %',
          [IMPORT_FIELDS.WRITER_FIRST_NAME]: 'Nombre Escritor',
          [IMPORT_FIELDS.WRITER_LAST_NAME]: 'Apellido Escritor',
          [IMPORT_FIELDS.PUBLISHER]: 'Editor',
          [IMPORT_FIELDS.PUBLISHER_PRO]: 'Editor PRO',
          [IMPORT_FIELDS.PUBLISHER_SHARE]: 'Editor %',
          [IMPORT_FIELDS.WRITER_PUBLISHER]: 'Escritor/Editor',
          [IMPORT_FIELDS.WRITER_PUBLISHER_PRO]: 'Escritor/Editor PRO',
          [IMPORT_FIELDS.WRITER_PUBLISHER_SHARE]: 'Escritor/Editor %',
          [IMPORT_DIVIDERS.SLASH]: '/',
          [IMPORT_DIVIDERS.OPEN_PARENTHESIS]: '(',
          [IMPORT_DIVIDERS.CLOSE_PARENTHESIS]: ')',
          [IMPORT_DIVIDERS.OPEN_SQUARE_BRACKET]: '[',
          [IMPORT_DIVIDERS.CLOSE_SQUARE_BRACKET]: ']',
          [IMPORT_DIVIDERS.DASH]: 'Guión',
          [IMPORT_DIVIDERS.SEMI_COLON]: ';',
          [IMPORT_DIVIDERS.SPACE]: 'Espacio',
          [IMPORT_DIVIDERS.COMMA]: 'Coma',
          colon: ':',
          period: 'Punto',
        },
      },
      qtracksCompare: {
        advancedSearch: 'Búsqueda Avanzada',
        artists: 'Artistas:',
        goBackNotification: 'Borrarás la información de esta página. ¿Proceder?',
        importedData: 'Información Importada',
        library: 'Librería:',
        qtracksData: 'Información de qwireTracks',
        selectAll: 'Seleccionar Todos',
        title: 'Título:',
        writers: 'Escritores:',
      },
      preview: {
        cueNumber: 'Número de Pista',
        duration: 'Duración',
        publishers: 'Afiliación Editor',
        seqNumber: 'Número de Secuencia',
        timeIn: 'Tiempo inicio',
        timeOut: 'Tiempo fin',
        title: 'Título',
        usage: 'Uso',
        writers: 'Afiliación Escritores/Compositores',
        prompt: {
          noImportFile: 'No, importar archivo',
          saveTemplateContent:
            'Al salvar la plantilla, puedes fácilmente volver a usar esta configuración en el futuro.',
          saveTemplateTitle: '¿Deseas salvar la configuración como plantilla?',
          yesCreateTemplate: 'Sí',
        },
      },
      notifications: {
        duplicatedTemplate: 'Ya existe un template llamado {{name}} en esta división.',
        templateDoesntMatch: 'El archivo cargado no coincide con la plantilla seleccionada.',
      },
    },
    productionOverview: {
      global: {
        groupByScenes: 'Agrupar por escenas',
        groupByAssets: 'Agrupar por materiales',
        scenes: 'Escenas',
        assets: 'Materiales',
        tracks: 'Canciones',
        addScene: 'Agregar Escena',
        addAsset: 'Agregar Material',
      },
      notifications: {
        unwatch: 'Producción borrada de las Observaciones',
        watch: 'Producción agregada a las Observaciones',
      },
      tracksFilter: {
        all: 'TODOS',
        filteredTracks_plural: '<0>Resultados filtrados</0> (<1>{{count}} Pistas</1>)',
        filteredTracks: '<0>Resultados filtrados</0> (<1>{{count}} Pista</1>)',
      },
      header: {
        intlRelease: 'Lanzamiento Internacional',
        mixDate: 'Fecha Mixta',
        usRelease: 'Lanzamiento en EE.UU.',
        assets: 'Materiales',
        campaign: 'Campaña',
        details: 'Detalles',
        scenes: 'Escenas',
        scenario: 'Escenario',
        tracks: 'Canciones',
        versions: 'Versiones',
        viewModes: 'View Modes',
        workflow: 'Flujo de trabajo',
        menu: {
          addAsset: 'Agregar Material',
          editAsset: 'Editar Material',
          addScene: 'Agregar Escena',
          editScene: 'Editar Escena',
          addTrack: 'Agregar Canción',
          archive: 'Archivar',
          createTask: 'Crear Tarea',
          currencies: 'Monedas',
          delete: 'Eliminar',
          duplicate: 'Duplicar',
          edit: 'Editar',
          hideInactiveCues: 'Ocultar Pistas Inactivas',
          hideInactiveTracks: 'Ocultar Canciones Inactivas',
          share: 'Compartir',
          unwatch: 'Dejar de Observar Producción',
          viewInactiveCues: 'Ver Pistas Inactivas',
          viewInactiveTracks: 'Ver Canciones Inactivas',
          watch: 'Observar Producción',
          filters: {
            ALL: 'TODOS',
            ARTIST: 'ARTISTAS',
            TRACKS: 'CANCIONES',
            LICENSORS: 'LICENCIANTES',
          },
        },
      },
      footer: {
        progress: 'PROGRESO',
        statusNew: 'Nuevo',
        statusInProg: 'En Prog',
        statusConfirmed: 'Confirmados',
        statusLicensed: 'Licensiados',
        totalEstFees: 'TOTAL TARIF. EST. (USADAS)',
        totalAppFees: 'TOTAL TARIF. APR (USADAS)',
      },
      tabs: {
        licensing: {
          title: 'Licenciantes',
        },
        versions: {
          title: 'Versiones',
        },
        budget: {
          addCost: 'AGREGAR UN COSTO',
          assignTo: 'ASIGNAR A',
          advanced: 'Avanzado',
          budgetOverUnder: 'Sobre/Por debajo',
          budgetType: 'Tipo de presupuesto',
          totalCommitted: 'Total comprometido',
          licenseFees: 'Tasas de licencia',
          committedFees: 'Tarifas comprometidas',
          displayAdditionalCurrency: 'Mostrar moneda adicional',
          domesticBudget: 'Presupuesto doméstico',
          domesticSplit: 'Fraccionamiento doméstico',
          edit: 'Editar',
          extraCosts: 'Costos extra',
          extraCostsAmount: 'Monto',
          extraCostsDate: 'Fecha',
          extraCostsDescription: 'Descripción',
          extraCostsGrandTotal: 'Costo adicional total',
          extraCostsProduction: 'Producción',
          extraCostsTotal: 'TOTAL DE COSTOS ADICIONALES:',
          extraCostsTrack: 'Canción',
          extraCostsConfirmDelete: '¿Estás seguro de que deseas eliminar el costo extra?',
          fees: 'Tarifas',
          history: 'Historial',
          internationalBudget: 'Presupuesto internacional',
          internationalSplit: 'Fraccionamiento internacional',
          musicBudget: 'Presupuesto de música',
          simplified: 'Simplificado',
          title: 'Presupuesto',
          tooltipBudgetOverUnder: 'La diferencia entre el presupuesto total y las tarifas comprometidas',
          tooltipCommittedFees: 'El total de todas las tarifas aprobadas de canciones marcadas como "En Uso"',
          tooltipDisplayAdditionalCurrency: 'Ver importes convertidos en otra moneda',
          tooltipProductionBudget: 'Presupuesto total de la producción',
          tooltipTotalPotentialSpend: 'Total de todas las tarifas',
          tooltipUncommittedFees: 'Tarifas totales aprobadas de todas las canciones que no están en uso',
          totalPotentialSpend: 'Gasto potencial total',
          uncommittedFees: 'Tarifas no comprometidas',
          errorFetchingAdditionalCurrencies: 'Se produjo un error al recuperar monedas adicionales.',
          form: {
            split: 'Please enter a number between 0 and 100',
          },
        },
        cueSheet: {
          menu: {
            addCue: 'Agregar Pista',
            attachFile: 'Adjuntar Archivo',
            export: 'Exportar Cue Sheet',
            hideInOut: 'Escoder Entra/Sale',
            importFile: 'Importar Hoja de Pistas',
            share: 'Compartir',
            showInOut: 'Mostrar Entra/Sale',
            submit: 'Presentar',
            syncWithClearance: 'Comparar con Autorización',
          },
          title: 'Lista de Pistas',
          emptyState:
            '<0>Esta campaña de marketing no tiene ningún</0><1>material aparte del contenedor no asignado.</1><2>Cree materiales para comenzar a usar hojas de referencia.</2>',
          header: {
            addRep: 'Agregar Asignado',
            version: 'Version',
            assets: 'Materiales',
            rep: 'Asignado',
            status: 'Estado',
            additionalNotes: 'Notas Adicionales',
            additionalNotesPlaceholder: 'Escribe notas adicionales para la grilla de pistas...',
            cueSheetIds: 'Numero de Grilla de Pistas',
            qwireCueSheetId: 'Qwire identificación de la hoja de referencia',
          },
          licensorRow: {
            role: 'Rol',
            writerComposer: 'Escritor/Compositor',
            pro: 'PRO',
            share: 'Porcentaje',
            publisher: 'Editor',
            sync: 'Licenciante Sync',
            updateContactPrompt: 'Está seguro de que desea actualizar la información de contacto?',
          },
          cueSheetTable: {
            selectAll: 'SELECCIONAR TODOS',
            action: 'ACCIÓN',
            menu: {
              applyPreset: 'Aplicar Plantilla',
              changeCueDetails: 'Cambiar Detailles',
              createSegment: 'Crear Segmento',
              deleteSegmentsPrompt:
                'Está seguro de que desea eliminar los segmentos seleccionados? (Las señales NO seran borradas)',
              exportSegments: 'Exportar Segmentos',
              delete: 'Borrar',
              deleteSegments: 'Borrar Segmentos',
              duplicate: 'Duplicar',
            },
            consecutiveCues:
              'Para crear un segmento, debe seleccionar uno o más señales consecutivas y ningún segmento.',
            segmentsOnly: 'Asegúrese de tener uno o más segmentos seleccionados y sin señales.',
            emptyMessage:
              'Esta hoja de pistas no tiene pistas. Puedes agregarlas manualmente clickeando en el botón Agregar Pista. O puedes importar una hoja de calculo o EDL eligiendo Importar Hoja de Pistas desde el Menú de Acciones de la Hoja de Pistas',
            reorderedCue: 'Hoja de pista Reordenada',
          },
          cueRow: {
            addNew: 'Agregar Nuevo',
            cancel: 'CANCELAR',
            distribute: '% DISTRIBUIR PARTICIPACIONES',
            saveCue: 'GUARDAR PISTA',
            saveCueNext: 'GUARDAR PISTA Y PRÓXIMA',
            saveCueNew: 'GUARDER Y AGREGAR NUEVO',
            writersComposers: 'Escritores/Compositores',
            publishers: 'Editores',
            notes: 'Notas',
            library: 'Librería',
            cancelPrompt: 'Hay cambios sin guardar. ¿Desea guardar?',
            total: 'TOTALES',
            addArtists: 'AGREGAR ARTISTAS',
            createNew: 'CREAR NUEVO',
            cueComesFromClearance: 'La pista proviene de la Autorización',
            errors: {
              rapid_cue_requires: 'RapidCue requiere',
              no_duration: 'Duración',
              no_usage: 'Uso',
              no_writer_or_publisher: 'Escritor o Editor',
              writers_share_percentage:
                'RapidCue requiere que los escritores tengan roles y que la distribución de ingresos del Escritor y Editor sea del 0% o del 100%',
            },
          },
          cueRowHeader: {
            seqNumber: 'Seq#',
            cueNumber: 'Pista#',
            trackType: 'Tipo de Canción',
            title: 'Título*',
            artist: 'Artiísta',
            usage: 'Uso',
            in: 'Entra',
            out: 'Sale',
            duration: 'Duración',
          },
          cueRowMenu: {
            delete: 'Eliminar',
            deleteCuePrompt: '¿Estás seguro que deseás eliminar {{trackTitle}} de la hoja de pistas?',
            deleteCuesPrompt: '¿Estás seguro que deseás eliminar {{count}} pista de la hoja de pistas?',
            deleteCuesPrompt_plural: '¿Estás seguro que deseás eliminar {{count}} pistas de la hoja de pistas?',
            viewDetails: 'Ver Detalles',
            duplicate: 'Duplicar',
            applyPreset: 'Aplicar Plantilla',
            savePreset: 'Guardar Plantilla',
            export: 'Exportar',
          },
          track: {
            completeEmpty: 'Completar campos vacíos',
            overwriteAll: 'Sobreescribir información',
            promptConfirm: '¿Qué deseas hacer?',
            promptUpdate: 'Estás a punto de actualizar tu información de pista usando información de qwireTracks.',
            qTracksSync: 'Sincronizar qwireTracks',
          },
          tracksTitleMenu: {
            title: 'Titulo',
            artists: 'Artista(s)',
            id: 'ID',
            writers: 'Autor(es)',
            library: 'Libreria',
            sourceLibrary: 'Libreria Fuente',
          },
          tracksTitleOption: {
            master: 'Maestro(s)',
            publisher: 'Editor(es)',
            writer: 'Autor(es)',
          },
        },
        details: {
          menu: {
            projectDetails: 'Ver/Editar Detalles de Proyecto',
            seasonDetails: 'Ver/Editar Detalles de Temporada',
            releaseDetails: 'Ver/Editar Detalles de Lanzamiento',
          },
          masterCurrencyExchange: '1 {{code}}',
          addedCurrencies: 'Monedas Agregadas',
          additionalCurrencies: 'Monedas Adicionales',
          additionalInformation: 'Información Adicional',
          releasePlaceholder: 'Lanzamiento Mundial',
          cueSheetBy: 'Hoja de referencia predeterminada, preparada por',
          cueSheetByCountryCode: 'Código de País',
          cueSheetByEmail: 'Email',
          cueSheetByLastName: 'Apellido',
          cueSheetByName: 'Nombre',
          cueSheetByPhone: 'Teléfono',
          masterCurrency: 'Moneda maestra',
          descriptors: 'Descriptor de calificación',
          description: 'Descripción',
          descriptorsPlaceholder: 'Seleccionar un Descriptor',
          eidr: 'EIDR #',
          edit: 'Editar',
          duration: 'duración',
          intlReleaseDate: 'Lanzamiento Int',
          version: 'Versión',
          category: 'Categoría',
          language: 'Idioma',
          territory: 'Territorio',
          releaseInformation: 'Información de lanzamiento',
          productionInformation: 'Información de producción',
          personnel: 'Personal',
          isan: 'ISAN #',
          mixDate: 'Fecha Mezclada',
          notification: {
            campaign: {
              create: 'La Campaña {{name}} ha sido creada',
              edit: 'La Campaña {{name}} ha sido actualizada',
            },
            episode: {
              create: 'El Episodio {{name}} ha sido creado',
              edit: 'El Episodio {{name}} ha sido actualizado',
            },
            release: {
              create: 'El Lanzamiento {{name}} ha sido creado',
              edit: 'El Lanzamiento {{name}} ha sido actualizado',
            },
          },
          notes: 'Notas',
          productionYear: 'Año de la Producción',
          rating: 'Clasificación',
          ratingsPlaceholder: 'Seleccionar una Clasificación',
          requiredRights: 'Derechos de Producción',
          rights: 'Derechos',
          title: 'Detalles',
          usReleaseDate: 'Fecha de lanzamiento en Estados Unidos',
          cueSheetInstructions: 'Instrucciones de hoja de referencia',
          currency: 'Moneda',
          features: {
            number: 'Número de versión',
            productionTypeTitle: 'Información de lanzamiento',
            internalId: 'ID Interno #',
            prompt: '¿Seguro que quieres abandonar la configuración?',
            title: 'Título del Lanzamiento',
          },
          television: {
            number: 'Número de Episodio',
            animated: 'Animado',
            productionTypeTitle: 'Información del episodio',
            internalId: 'Internal Episode Production ID #',
            title: 'Título del Episodio',
          },
          marketing: {
            productionTypeTitle: 'Información de campaña',
            internalId: 'Número interno de producción',
            title: 'Título de campaña',
            number: 'Campaña #',
            prompt: '¿Seguro que quieres abandonar la configuración?',
          },
          items: {
            actors: 'Actores',
            directors: 'Directores',
            distributors: 'Distribuidores',
            editors: 'Editores',
            keyPersonnel: 'Personal Clave',
            networkBroadcaster: 'Red/Locutor',
            productionCompanies: 'Compañías de producción',
            producers: 'Productores',
          },
          confirmRightsUpdateForProduction:
            'Deseas aplicar los nuevos derechos a TODAS las canciones en esta producción?',
          rightsAndOptionsTitle: 'Derechos y opciones',
          addRight: 'Agregar derechos',
          addOption: 'Agregar opción',
          onLetterAs: 'En la letra A',
        },
        tasks: {
          buttons: {
            addTask: 'Añadir Tarea',
            inbox: 'Buzón',
            sent: 'Enviado',
            completed: 'Completado',
            myTeam: 'Mi Equipo',
          },
          headers: {
            assignee: 'Asignado',
            subject: 'Asunto',
            trackTitleArtist: 'Artista de Pista/Título',
            createdDate: 'Fecha de creación',
            dueDate: 'Fecha de vencimiento',
          },
          title: 'Tareas',
        },
        trackHistory: {
          columns: {
            production: 'Producción',
            scene: 'Escena',
            releaseYear: 'Año de lanzamiento',
            use: 'Uso',
            duration: 'Duración',
            fee: 'Tarifa',
          },
        },
        clearanceLog: {
          columns: {
            user: 'Usuario',
            ipAddress: 'Dirección IP',
            timestamp: 'Marca de tiempo',
            changes: 'Cambios',
          },
          changes: {
            'Requested Use Type': 'Tipo de uso solicitado <1>set to</1> <2><0>{{value}}</0></2>',
            'Actual Use Type': 'Tipo de uso real <1>set to</1> <2><0>{{value}}</0></2>',
            'EMI Film and Television Music Licensor Share %':
              'EMI Film and Television Music Licensor Share % <1>set to</1> <2><0>{{value}}</0></2>',
          },
        },
        overview: {
          title: 'General',
          scenes: {
            addCueContainer: 'Agregar Escena',
            cues: '{{count}} Pista',
            addToTemplate: 'Agregar a la plantilla',
            cues_plural: '{{count}} Cues',
            addTrack: 'Agregar Canción',
            editCueContainer: 'Editar Escena',
            deletePrompt: '¿Estás seguro que deseas eliminar la escena?',
            deletedMessage: 'La escena ha sido eliminada',
            movedMessage: 'La escena ha sido movida',
            deleteCueContainer: 'Eliminar',
            deleteDisabled: 'No puedes eliminar una escena que contiene canciones',
            move: 'Mover',
            notes: 'NOTAS',
            popover: {
              moveAfterCueContainer: 'Mover después de la escena',
            },
            headers: {
              actions: 'Acciones',
              actualUse: 'Actual',
              approvedFees: 'Tarifa Aprobada',
              estimatedFees: 'Tarifa Estimada',
              using: 'Usando',
              rep: 'Rep',
              tags: 'Etiquetas',
              requestedUse: 'Solicitado',
              title: 'Título/Artista de la canción',
              trackStatus: 'Estado',
              type: 'Tipo',
            },
            hints: {
              sceneHint: {
                emptyCueContainer: 'Esta Escena está vacía o tiene canciones inactivas',
                gotIt: 'Entendido',
                subtitle:
                  'Puedes arrastrar una canción desde la Papelera sin Asignar o agregarlas a esta escena con el botón “Agregar Canción”.',
                title: 'Sugerencia útil de la nueva escena',
              },
              unassignedHint: {
                addingCueContainer: 'Agregar escenas y canciones dentro de sus escenas',
                addingTracks: 'Solo empiece a agregar canciones.',
                addCueContainer: 'Agregar Escena',
                addTrack: 'Agregar Canción',
                emptyBin: 'La Papelara sin Asignar está vacía o tiene canciones inactivas',
                subtitle: 'Hay 2 formas de comenzar',
                title: 'Tienes un nuevo Proyecto',
              },
            },
          },
          assets: {
            currentVersion: '{{title}} V{{number}} - {{name}}',
            addCueContainer: 'Agregar Material',
            addTrack: 'Agregar Canción',
            addVersion: 'Agregar Versión',
            editCueContainer: 'Editar Material',
            deactivated: 'Desactivado',
            deletePrompt: '¿Estás seguro que deseas eliminar la material?',
            deletedMessage: 'El material ha sido eliminada',
            movedMessage: 'El material ha sido movido',
            deleteCueContainer: 'Eliminar',
            deleteDisabled: 'No puedes eliminar un material que contiene canciones',
            feesApplied: 'Tarifas Aplicadas',
            complete: 'Completar Material',
            move: 'Mover',
            notes: 'Notas',
            cues: '{{count}} Pista',
            cues_plural: '{{count}} Pistas',
            requiredRights: 'Derechos de Producción',
            using: 'Usando',
            popover: {
              moveAfterCueContainer: 'Mover después de la material',
            },
            headers: {
              actions: 'Acciones',
              actualUse: 'Actual',
              approvedFees: 'Tarifa Aprobada',
              estimatedFees: 'Tarifa Estimada',
              using: 'Usando',
              rep: 'Rep',
              requestedUse: 'Solicitado',
              tags: 'Etiquetas',
              title: 'Título/Artista de la canción',
              trackStatus: 'Estado',
              type: 'Tipo',
            },
            footer: {
              total: 'Total de Materiales',
            },
            hints: {
              assetHint: {
                emptyCueContainer: 'Esta Material está vacía',
                gotIt: 'Entendido',
                subtitle:
                  'Puedes arrastrar una canción desde la Papelera sin Asignar o agregarlas a esta material con el botón “Agregar Canción”.',
                title: 'Sugerencia útil de la nueva material',
              },
              unassignedHint: {
                addingCueContainer: 'Agregar materials y canciones dentro de sus materials',
                addingTracks: 'Solo empiece a agregar canciones.',
                addCueContainer: 'Agregar Material',
                addTrack: 'Agregar Canción',
                emptyBin: 'La Papelara sin Asignar está vacía',
                subtitle: 'Hay 2 formas de comenzar',
                title: 'Tienes un nuevo Proyecto',
              },
            },
          },
          unassigned: {
            cues: '{{count}} Pista',
            cues_plural: '{{count}} Pistas',
            title: 'Sin asignar',
            addTrack: 'Agregar Canción',
          },
          tracks: {
            addTag: 'Agregar Etiqueta',
            addTrack: 'Agregar Canción',
            emptyHint: {
              title: 'Tienes un nuevo Proyecto',
              subtitle: 'En la vista de canciones, puedes agregar, ordenar y filtrar canciones',
            },
            filter: 'Filtrar por nombre de canción, artistas, licenciantes',
            overview: 'Descripción General',
            headers: {
              actions: 'Acciones',
              actualUse: 'Actual',
              approvedFees: 'Tarifa Apr.',
              estimatedFees: 'Tarifa Est.',
              using: 'Usando',
              rep: 'Rep',
              tags: 'Etiquetas',
              requestedUse: 'Solicitado',
              title: 'Título/Artista de la canción',
              trackStatus: 'Estado',
              type: 'Tipo',
              BLANK: '',
            },
            menu: {
              bookmark: 'Añadir Marcador',
              trackDetail: 'Detalle de la Pista',
              createTask: 'Crear Tarea',
              currencies: 'Monedas',
              deactivate: 'Desactivar',
              reactivate: 'Activar',
              removeBookmark: 'Quitar Marcador',
              moveTrack: 'Mover Canción',
              sendLetters: 'Enviar Cartas',
              compare: 'Comparar en qwireTracks',
              attachFile: 'Adjuntar Archivo',
              markAsNotUsing: 'Marcar como sin usar',
              markAsUsing: 'Marcar como usado',
              addToTemplate: 'Agregar a la plantilla',
            },
          },
        },
        files: {
          title: 'Files',
        },
      },
    },
    mail: {
      from: 'De',
      letterType: 'Tipo de Carta',
      sentAt: 'Fecha',
      subject: 'Asunto',
      track: 'Canción/Artista',
    },
    files: {
      title: 'Archivos',
      attachments: 'Archivos Adjuntos',
      mail: 'Mail',
      licensor: 'Licenciante',
      fileType: 'Tipo de archivo',
      addAttachment: 'Adjuntar Archivo',
      allOption: 'Todos',
      uploadText:
        '<0>PARA SUBIR UN ARCHIVO</0> <1>ARRASTRA Y SUELTALO AQUÍ</1> <2>O HAZ CLICK PARA ABRIR EL BUSCADOR DE ARCHIVOS</2>',
      table: {
        createdAt: 'Fecha',
        fileName: 'Nombre',
        fileType: 'Tipo',
        licensorName: 'Licenciante',
        fileDescription: 'Descripción',
        trackTitleArtist: 'Título/Artista de la Canción',
        clearanceName: 'Autorización',
        fileSize: 'Tamaño',
      },
      // @TODO below translations will be adjusted removed in CLR-2601
      attachFile: 'Adjuntar archivo',
      fileName: 'Nombre del archivo',
      description: 'Descripción',
      clearance: 'Autorización',
      fileSize: 'Tamaño del archivo',
      date: 'Fecha',
      actions: 'Acciones',
      confirmDelete: '¿Está seguro de que desea eliminar el archivo?',
      notification: {
        create: 'El Archivo se ha cargado',
        update: 'El Archivo {{name}} se ha actualizado',
      },
    },
    settings: {
      title: 'Configuración',
      divisionButton: 'División',
      userButton: 'Usuario',
      divisionDropdown: {
        placeholder: 'Elige una división',
      },
      tabs: {
        auditLog: 'Auditoría',
        divisions: 'Divisiones',
        rights: 'Derechos Predeterminados',
        territory: 'Territorios Predeterminados',
        users: 'Usuarios',
        contacts: 'Contactos',
        notifications: 'Notificaciones',
        letterTemplates: 'Plantillas de cartas',
        scheduleATemplates: 'Plantillas Schedule A',
        emailTemplates: 'Plantillas de correo electrónico',
        cueSheet: 'Hoja Cue',
        ariaLabel: 'Pestañas',
      },
      division: {
        companyDetails: 'Detalles de la compañía',
      },
      contactsPreference: {
        title: 'Lista de Contactos Preferida',
        description:
          'Los usuarios de qwireSuite que tengan acceso a más de una division pueden elegir una division para utilizar su lista de contactos',
        dropdownLabel: 'Seleccione una Division',
        dropdownPlaceholder: 'Por favor, seleccione una division',
      },
      cueSheet: {
        title: 'Detalles de Hoja Cue',
        subtitle: 'Selecciona los PRO que aparecerán por defecto cuando la hoja cue sea presentada:',
        successMessage: 'Las configuraciones de la division fueron actualizados satisfactoriamente',
        warningMessage:
          'ID del Remitente y/o Nombre del Remitente incompletos. Por favor, consulta a un admin de division para completar estos campos. Para ayuda, contacta a support@qwire.com',
        rapidCueInformation: 'Información RapidCue',
        senderID: 'ID del Remitente',
        senderName: 'Nombre del Remitente',
        senderType: 'Tipo del Remitente',
        defaultCategory: 'Categoría Predeterminados',
        companyID: 'ID de Empresa',
        companyName: 'Nombre de Empresa',
        defaultDuration: 'Duración Predeterminados',
        company: {
          title: 'Contacto de la Empresa para RapidCue',
          firstName: 'Nombre',
          lastName: 'Apellido',
          contactEmail: 'Email del Contacto',
          telephone: 'Telephono',
          address1: 'Dirección 1',
          address2: 'Dirección 2',
          city: 'Ciudad',
          state: 'Estado',
          country: 'País',
          zip: 'Código Postal',
        },
      },
      users: {
        name: 'Nombre',
        role: 'Rol por Defecto',
      },
    },
    auditLog: {
      filter: {
        applyFilters: 'Aplicar',
        filters: 'Filtros',
        clearFilters: 'Limpiar',
        subprojects: {
          drawerTitle: 'Título de Producción',
          label: 'Título de Producción',
          searchPlaceholder: 'Buscar por Título de Producción',
          searchHint: 'Puedes buscar por producciones',
        },
        users: {
          drawerTitle: 'Usuarios',
          label: 'Usuarios',
          searchPlaceholder: 'Buscar por Usuario',
          searchHint: 'Puedes buscar por usuarios',
        },
      },
      list: {
        changes: 'Cambios',
        date: 'Fecha y Hora',
        ip: 'IP',
        production: 'Producción',
        user: 'Usuario',
        update: '<0>Modificó </0><1>{{name}}</1><2> de </2><3>{{from}}</3><4> a </4><5>{{to}}</5>',
      },
    },
    territory: {
      header: {
        addNew: 'Agregar Nuevo',
        search: 'Buscar por nombre',
        column: {
          territoryName: 'Nombre',
          letterAs: 'Completar carta como',
          territories: 'Territorios',
          worldwide: 'Global',
        },
      },
      row: {
        form: {
          name: {
            label: 'Región',
            placeholder: '',
          },
          nameOnLetters: {
            label: 'A nombre',
            placeholder: '',
          },
          strategy: {
            label: 'Territorios',
            placeholder: 'Escribe algo',
            values: {
              worldwide: 'Global',
              specific: 'Set especifico',
            },
          },
          countryIds: {
            placeholder: 'Seleccionar paises',
          },
        },
      },
      notification: {
        get: {
          failed: 'Ha ocurrido un error al intentar mostrar los territorios disponibles',
        },
        add: {
          prompt: 'Tienes cambios sin guardar, ¿Seguro que continuar?',
          success: 'El nuevo Territoro ha sido guardado',
        },
        edit: {
          prompt: 'Modificar este territorio modificará los siguientes items, ¿Seguro quieres continuar?',
          success: 'Territorio {{name}} actualizado',
          failed: 'No se ha podido actualizar. Por favor, intente nuevamente',
        },
        delete: {
          prompt: '¿Seguro quieres borrar?',
          promptWithRights: 'Eliminar este territorio modificará los siguientes items, ¿Seguro quieres continuar?',
          success: 'Territorio eliminado',
          failed: 'No se ha podido eliminar, por favor intente nuevamente',
        },
      },
    },
    templates: {
      types: {
        letter: 'Carta',
        scheduleA: 'Schedule A',
        email: 'Correo electrónico',
      },
      creator: {
        template: 'Plantilla',
        tabs: {
          composeTemplate: {
            title: 'Crear plantilla',
            letterTemplateFields: 'Campos de plantilla de carta:',
            scheduleATemplateFields: 'Campos de plantilla de Schedule A:',
            search: {
              placeholder: 'Buscar...',
              label: 'Buscar campo',
            },
            letterInstructions: {
              what: {
                label: 'Qué:',
                text: 'Las plantillas de cartas se utilizan para generar automáticamente cartas que se envían a los titulares de derechos',
              },
              how: {
                label: 'Cómo:',
                text: 'Componga su carta en el editor de documentos a la derecha. Puede insertar detalles de la pista de inserción y personalizar la letra utilizando los "Campos de plantilla de letra" que se enumeran a continuación. Para insertar un campo de plantilla de letra, coloque el cursor donde desee que aparezca el campo en el documento y haga clic en el campo en la lista a continuación. Los campos se pueden eliminar eliminando el texto del campo en la carta.',
              },
            },
            schedule_aInstructions: {
              what: {
                label: 'Qué:',
                text: 'Schedule A se adjunta a las plantillas de cartas que se enviarán a los titulares de derechos.',
              },
              how: {
                label: 'Cómo:',
                text: 'Redacte su Schedule A en el editor de documentos a la derecha.',
              },
            },
            emailInstructions: {
              what: {
                label: 'Qué:',
                text: 'Las plantillas de emails se utilizan para generar automáticamente emails que se envían a los titulares de derechos',
              },
              how: {
                label: 'Cómo:',
                text: 'Componga su email en el editor de emails a la derecha. Puede insertar detalles de la pista de inserción y personalizar la letra utilizando los "Campos de plantilla de email" que se enumeran a continuación. Para insertar un campo de plantilla de email, coloque el cursor donde desee que aparezca el campo en el documento y haga clic en el campo en la lista a continuación. Los campos se pueden eliminar eliminando el texto del campo en la email.',
              },
            },
            form: {
              body: 'Mensaje:',
              bodyPlaceholder:
                'Ej.\nHola ##ContactFirst##,\nPor favor, mirá las licencias adjunta para "##TrackTitle##" por ##TrackArtist##.\n\n¡Gracias!\n\n##ClearanceRepName##',
              cc: 'CC:',
              ccPlaceholder: 'Ej. alguien@ejemplo.com',
              subject: 'Asunto:',
              subjectPlaceholder: 'Ej. Solicitud de Presupuesto para ##LetterType##',
            },
          },
          templateDetails: {
            title: 'Detalles de la plantilla',
            nameYourTemplate: 'Nombrá tu plantilla',
            preferredTooltip: 'Se guardará como primario si hay múltiples plantillas guardadas con las mismas reglas',
            form: {
              assignToProductions: 'Asignar a producciones',
              assignToLicensors: 'Asignar a licenciantes',
              licensor: 'Licenciante',
              preferred: 'Establecer como plantilla predeterminada',
              production: 'Producción',
              letterType: 'Tipo de Carta',
              licensorType: 'Tipo de Licenciante',
              next: 'Siguiente: Componer Plantilla',
              selectType: 'Seleccionar Tipo...',
              emailTitle: 'Nombre de la plantilla de Email',
              letterTitle: 'Nombre de la plantilla de carta',
              schedule_aTitle: 'Nombre de la plantilla de Schedule A',
              scheduleATemplate: 'Schedule A',
            },
            instructions: {
              letter: {
                what: {
                  label: 'Qué:',
                  text: 'Las plantillas de cartas son utilizadas para generar cartas automáticamente que son enviadas a los dueños de los derechos.',
                },
                how: {
                  label: 'Cómo:',
                  text: 'Iniciá asignándole a la plantilla de tu carta un nombre y seleccionando el tipo de carta que aplica. Opcionalmente, puedes asignar a producciones y licenciantes particulares para restringir el uso de plantillas.',
                },
              },
              email: {
                what: {
                  label: 'Qué:',
                  text: 'Las plantillas de emails son utilizadas para generar emails automáticamente que son enviados a los dueños de los derechos.',
                },
                how: {
                  label: 'Cómo:',
                  text: 'Iniciá asignándole a la plantilla de tu email un nombre y seleccionando el tipo de email que aplica. Opcionalmente, puedes asignar a producciones y licenciantes particulares para restringir el uso de plantillas.',
                },
              },
            },
          },
        },
        cancel: 'Tienes cambios sin guardar, ¿Estás seguro que deseas cerrar?',
      },
      notifications: {
        delete: 'Plantilla {{name}} borrada',
        deletePrompt: '¿Estás seguro que deseás eliminar la plantilla {{name}}?',
        duplicated: 'Ya existe una plantilla con el nombre {{name}}',
        save: 'Plantilla {{name}} guardada',
      },
      header: {
        addNewTemplate: 'Agregar nueva plantilla',
        search: {
          title: 'Buscar',
          placeholder: 'Buscar plantillas',
          ariaLabel: 'Buscar',
        },
        filter: {
          title: 'Filtrar',
        },
      },
      columns: {
        primary: 'Primaria',
        letterTemplateName: 'Nombre de plantilla de carta',
        schedule_aTemplateName: 'Nombre de plantilla de Schedule A',
        emailTemplateName: 'Nombre de plantilla de Email',
        licensorType: 'Tipo de licenciante',
        letterType: 'Tipo de carta',
        licensor: 'Licenciante',
        project: 'Proyecto',
        actions: 'Acciones',
        attachedTo: 'Adjunto a',
        attachment: 'Adjunto',
      },
      noTemplates: {
        letter: {
          title: 'Parece que no tienes plantillas de cartas creadas',
        },
        schedule_a: {
          title: 'Parece que no tienes plantillas de Schedule A creadas',
        },
        email: {
          title: 'Parece que no tienes plantillas de correo electrónico creada',
        },
        body: 'Puede crear nuevas plantillas con el botón <0> Agregar nueva plantilla </0> <br> en la esquina superior derecha',
      },
    },
    licensors: {
      types: {
        master: 'Maestro',
        one_stop: 'Una Parada',
        publisher: 'Editor',
        other: 'Otro',
        sync: 'Sincronizar',
        writer: 'Escritor',
      },
      functionOptions: {
        SIDE_ARTIST: 'Side Artist',
        ESTATE: 'Estado',
        LIKENESS: 'Semejanza',
      },
    },
    rightsOwners: {
      types: {
        master: 'Maestro',
        publisher: 'Editor',
        writer: 'Escritor',
        composer: 'Composer',
      },
      roles: {
        N: 'N',
        AD: 'AD',
        AR: 'AR',
        C: 'C',
        CA: 'CA',
        SR: 'SR',
        SA: 'SA',
        PR: 'PR',
        TR: 'TR',
        A: 'A',
      },
    },
    phone: {
      types: {
        Phone: 'Teléfono',
        Mobile: 'Móvil',
        Office: 'Oficina',
        Fax: 'Fax',
      },
    },
    composeLetters: {
      stepper: {
        next: 'Siguiente',
        send: 'Enviar',
        back: 'Atrás',
        skipToEmail: 'Saltar a email',
        selectLicensor: 'Seleccionar Licenciante',
        selectContact: 'Seleccionar Contacto',
        composeLetter: 'Redactar Carta',
        composeEmail: 'Redactar Correo',
        letterCurrentOfTotal: 'Carta {{current}} de {{total}}',
        queuedLetters: '<0>Cartas encoladas:</0><1></1><2>({{count}})</2>',
        singleLicensor: 'Carta de Único Licenciante',
        title: 'Gestor de Cartas',
      },
      selectContact: {
        stepTitle: 'Seleccionar Contacto',
        existingContactTab: 'Contacto Existente',
        newContactTab: 'Nuevo Contacto',
        selectContactMessage:
          'Por favor, ingrese los detalles del contacto antes de continuar con el paso de Redactar Carta.',
        existingContactLabel: 'Contacto',
        existingContactRequired: 'Por favor selecciona un contacto',
        noExistingContacts: 'No se encontraron contactos',
        emailLabel: 'Dirección de correo electrónico',
        firstNameLabel: 'Nombre',
        lastNameLabel: 'Apellido',
        address1Label: 'Dirección línea 1',
        address2Label: 'Dirección línea 2',
        cityLabel: 'Ciudad',
        stateLabel: 'Estado',
        postalCodeLabel: 'Código postal',
        countryNameLabel: 'País',
      },
      letterTemplate: {
        stepTitle: 'Redactar Carta',
        templateId: 'Plantilla de Carta',
        licensorStatusId: 'Estado del Licenciante',
        letterFileType: 'Enviar archivo como',
        setStatus: 'Establecer estado',
        setSendDate: 'Establecer fecha de envío',
        emptyDocumentOption: 'Documento vacío',
        downloadButton: 'Descargar',
      },
      composeEmail: {
        stepTitle: 'Redactar Correo',
        emptyEmailOption: 'Correo vacío',
        templateLabel: 'Plantilla de correo electrónico',
        licensorStatusLabel: 'Estado del Licenciante',
        toLabel: 'Para',
        ccLabel: 'CC',
        subjectLabel: 'Asunto',
        fromLabel: 'De',
        bodyLabel: 'Cuerpo',
      },
      attachments: {
        fieldLabel: 'Archivos adjuntos',
        letterInfo: 'La carta que redactó se adjuntará al correo electrónico.',
        noAttachments: 'No se encontraron archivos adjuntos.',
      },
    },
    letters: {
      emptyState:
        '<0>Elija un tipo de Carta y configure</0><1>los Filtros para seleccionar los Licenciantes a los que</1><2>se enviarán las cartas.</2>',
      types: {
        QUOTE_REQUEST: 'Requerir cita',
        CONFIRMATION: 'Confirmación',
        LICENSE_REQUEST: 'Solicitud de licencia',
        LICENSE: 'Licencia',
        new: 'Nova',
        edit: 'Editar',
        QUOTE_RENEWAL: 'Renovación de Cotización',
        CHECK_REQUEST: 'Consultar cita',
      },
      buttons: {
        SET_DATE: 'ESCRIBIR FECHA',
        REMOVE_DATE: 'ELIMINAR FECHA',
      },
      notifications: {
        completed: 'Buen trabajo. Todas tus cartas han sido enviadas!',
        draft: 'Borrador guardado con éxito',
        sent: 'Carta enviada con éxito',
        downloaded: 'Carta descargada y actualizada con éxito',
      },
      stepper: {
        addClearances: 'Agregá autorizaciones',
        backToFilters: 'Volver a los Filtros',
        composeEmail: 'Confeccionar Email',
        composeLetter: 'Confeccionar Carta',
        nextStepLetters: 'Siguiente Paso: Cartas',
        nextStepReviewQueue: 'Siguiente Paso: Revisar Cola',
        reviewQueue: 'Revisar Cola',
        selectContact: 'Seleccionar Contacto',
        title: 'Gestor de Cartas',
      },
      generate: {
        extraAttachments: {
          title: 'Adjuntos',
          attachFiles: 'ADJUNTAR ARCHIVO',
        },
        letterCurrentOfTotal: 'Carta {{current}} de {{total}}',
        licensorStatus: 'Establecer estado del Licenciante',
        missingTags: {
          continue: 'Continuar',
          skipLetter: 'Omitir Carta',
          subtitle: 'Falta la siguiente información para la plantilla seleccionada:',
          title: 'Información Faltante',
        },
        quoteExpiring: {
          currentExpiration: 'Expiración Actual',
          newExpiration: 'Expiración Nueva',
        },
        queuedLetters: '<0>Cartas encoladas:</0><1></1><2>({{count}})</2>',
        singleLicensor: 'Carta de único licenciante',
        skip: 'Omitir',
        skipPrompt: '¿Estás seguro que deseás omitir esta carta?',
        change: 'Cambiar',
        close: 'Cerrar',
        letter: 'Carta',
        composeLetter: {
          selectedOptions: 'Opciones elegidas',
          composeEmail: 'COMPONER EMAIL',
          noTemplate: 'Sin Plantilla',
          download: 'DESCARGAR',
          formats: {
            pdf: 'PDF',
            wordDoc: 'Word',
          },
          letterFormat: 'Formato de Carta',
          letterTemplate: 'Plantilla de Carta',
          skipToEmail: 'SALTAR A EMAIL',
          setStatus: 'Actualizar Estado',
          setSendDate: 'Actualizar Fecha de Envío',
        },
        composeEmail: {
          emailTemplate: 'Plantilla de Email',
          noTemplate: 'Sin Plantilla',
          template: {
            attachment: 'Adjunto:',
            body: 'Mensaje:',
            cc: 'CC:',
            ccPlaceholder: 'e.g. alguien@ejemplo.com',
            from: 'De:',
            subject: 'Asunto:',
            subjectPlaceholder: 'e.g. Solicitud de cotización',
            to: 'Para:',
          },
        },
        contact: {
          back: 'Volver',
          email: 'Email',
          licensor: 'Licenciante',
          name: 'Contacto',
          firstName: 'Nombre',
          lastName: 'Apellido',
          contactEmail: 'Email del Contacto',
          address1: 'Dirección 1',
          address2: 'Dirección 2',
          city: 'Ciudad',
          country: 'País',
          postalCode: 'Código Postal',
          state: 'Estado',
          skip: 'Salta a Email',
          next: 'Próximo paso',
        },
        steps: {
          contact: 'Elegir contacto',
          composeLetter: 'Escribir carta',
          composeEmail: 'Escribir email',
          editContact: 'Editar Contacto',
          editLetter: 'Editar Carta',
          updateContactPrompt: 'Está seguro de que desea actualizar la información de contacto?',
        },
        stepperFooter: {
          back: 'Volver',
          next: 'Siguiente',
          send: 'Enviar',
          skipToEmail: 'Saltar a Correo',
        },
      },
      clearancesList: {
        table: {
          bundle: 'AGRUPAR',
          clearance: 'Autorización',
          licensor: 'Licenciante',
          licensorType: 'Tipo de Licenciante',
          production: 'Producción',
          season: 'Temporada',
          selectAll: 'SELECCIONAR TODO',
          status: 'Estado',
          tooltip: 'Debe seleccionar varias pistas del mismo licenciante para agrupar',
          trackArtist: 'Canción  |  Artistas',
          unBundle: 'DESAGRUPAR',
        },
        filters: {
          all: 'Todos',
          addClearances: 'Agregar Autorizaciones',
          allTracks: 'Todas las canciones',
          applyFilters: 'Aplicar',
          clearFilters: 'Limpiar',
          division: 'División',
          filters: 'Filtros',
          include: 'Incluir',
          singleLetter: 'Enviar una única carta',
          note: 'Nota:',
          singleLicensorCaption:
            'Cuando se selecciona un único licenciante, puedes elegir combinar las autorizaciones correspondientes en una única carta.',
          letterType: 'Tipo de Carta',
          productions: { label: 'Producción' },
          licensors: {
            drawerTitle: 'Licenciantes',
            label: 'Licenciante',
            searchPlaceholder: 'Buscar por Licenciante',
            searchHint: 'Puedes buscar Licenciantes',
          },
          tracks: {
            drawerTitle: 'Títulos de Canciones',
            label: 'Canción',
            searchPlaceholder: 'Buscar por Título de Canción',
            searchHint: 'Puedes buscar Canciones',
          },
          status: {
            drawerTitle: 'Filtro por Estado',
            label: 'Estados',
          },
          library: {
            label: 'Librería',
          },
          notUsing: 'No usando',
          using: 'Usando',
        },
      },
      stepTwo: {
        trackCounts:
          '<0>Pistas seleccionadas:</0><1></1><2>{{countSelected}}</2><3>Pistas totales:</3><4></4><5>{{countTotal}}</5>',
        clearancesSelected: 'Autorizaciones seleccionadas',
        clearancesTotal: 'Autorizaciones totales',
      },
    },
    writersComposers: {
      types: {
        AD: 'AD - Adaptador',
        AR: 'AR - Arreglador',
        C: 'C - Compositor',
        CA: 'CA - Compositor/Autor',
        SR: 'SR – Sub-Arreglador',
        SA: 'SA – Sub-Autor',
        PR: 'PR - Artista Asociado',
        TR: 'TR - Traductor',
        A: 'A – Autor, Escritor, Letrista',
      },
    },
    publishers: {
      types: {
        E: 'E - Editor Original',
        SE: 'SE - Sub-Editor',
        ES: 'ES - Editor Substituto',
        PA: 'PA - Participante de la Ganancia del Editor',
        AM: 'AM - Administrador',
      },
    },
    cueThemes: {
      types: {
        Bumper: 'Parachoque',
        Closing: 'Cierre',
        EndTitle: 'Título final',
        Logo: 'Logo',
        MainTitle: 'Título principal',
        Opening: 'Apertura',
        Segment: 'Segmento',
        Note: 'Nota',
      },
    },
    cuesUsages: {
      types: {
        A_CAPELLA: 'A capella',
        DANCE: 'Danza',
        HUMMING: 'Zumbador',
        INDISTINGUISHABLE: 'Indistinguible',
        LIVE_PERFORMANCE_SYMPHONIC: 'Actuación Sinfónica en vivo',
        LYRIC_LESS_VOCAL: 'Voz sin letras',
        NOODLING: 'Improvisación',
        SPOKEN: 'Hablado',
        WHISTLING: 'Silbido',
      },
    },
    rapidCueSender: {
      types: {
        CABLE_SOURCE: 'Cable',
        DISTRIBUTOR: 'Distribuidor',
        NETWORK: 'Red',
        PRODUCTION_COMPANY: 'Compania de Produccion',
        WRITER: 'Escritor',
        PUBLISHER: 'Editor',
        OTHER: 'Otro',
      },
    },
    contacts: {
      typeahead: {
        createNew: 'Crear Nuevo',
        email: 'Email',
        name: 'Nombre del Contacto',
      },
      deleted: 'Contacto eliminado',
      viewContact: 'Ver contacto',
      searchContactNameOrLicensor: 'Buscar nombre de contacto o licenciante',
      confirmDelete: '¿Está seguro de que desea eliminar {{fullName}} de sus contactos',
      newContact: 'NUEVO CONTACTO',
    },
    rights: {
      drawer: {
        presetName: 'Nombre del Preset',
        defaultLabel: 'Establecer como Predeterminado',
        presetRightsTitle: 'Agregar Derechos del Preset',
        presetOptionsTitle: 'Agregar Opciones del Preset',
        saveAndCreateNewButton: 'Guardar y Crear Nuevo',
      },
      grid: {
        preset: 'Preestablecido',
        type: 'Tipo',
        territory: 'Territorio y Plazo',
        descriptionOnLetters: 'En Letra Como',
        default: 'Por Defecto',
        more: 'Más',
        addOptionsPreset: 'Agregar preajuste de opciones',
        addRightsPreset: 'Agregar preajuste de derechos',
      },
      header: {
        addNew: 'Agregar Nuevo',
        search: 'Buscar',
      },
      details: {
        ONE_DAY: 'Un día',
        DATE_RANGE: 'Rango de fechas',
        INPERPETUITY: 'En perpetuidad',
        OTHER: 'Otro',
        from: 'Desde',
        to: 'hasta',
        noRights: 'Sin derechos',
        editClearanceRights: 'Editar derechos de autorización',
      },
      row: {
        [RIGHTS_TERRITORIES_TERM.ONE_DAY]: 'Un día',
        [RIGHTS_TERRITORIES_TERM.DATE_RANGE]: 'Rango de fechas',
        [RIGHTS_TERRITORIES_TERM.INPERPETUITY]: 'En perpetuidad',
        nonSpecified: 'Sin especificar',
      },
      rowExpanded: {
        selectTerritory: 'Seleccione un territorio',
        otherDetails: 'Detalles',
        territory: 'Mundial',
        territoryTitle: 'Territorio',
        presetName: 'Nombre preestablecido',
        defaultPreset: 'Preestablecido por defecto',
        descriptionOnLetters: 'En carta como',
        mediaType: 'Tipo de Medio',
        allMediaTypes: 'Todos',
        materialType: 'Tipo de Material',
        allMaterialTypes: 'Todos',
        term: 'Término',
        name: 'Nombre',
      },
      notification: {
        territoryDrawer: {
          edit: {
            success: 'Territorios actualizados',
            failed: 'Algo salió mal, por favor intente de nuevo.',
          },
          get: {
            failed: 'Error al obtener el territorio',
          },
        },
        get: {
          failed: 'Hubo un error al obtener la lista de Derechos',
        },
        add: {
          prompt: 'Hay cambios sin guardar. ¿Estás seguro que deseas cancelar?',
          success: 'Derecho Preestablecido guardado',
          failed: 'No se pudo crear el Derecho Preestablecido, por favor intente de nuevo',
        },
        edit: {
          prompt: '¿Estás seguro que deseás guardar?',
          success: 'Derecho Preestablecido {{name}} actualizado',
          failed: 'No se pudo actualizar el Derecho Preestablecido, por favor intente de nuevo',
          editDefault: 'Esta seguro que desea actualizar el territorio por defecto para esta División?',
        },
        delete: {
          prompt: '¿Estás seguro que deseas eliminar?',
          success: 'El Derecho Preestablecido ha sido eliminado',
          failed: 'No se pudo eliminar el Derecho Preestablecido, por favor intente de nuevo',
        },
      },
      loading: 'Cargando Preestablecidos...',
    },
    tracks: {
      header: {
        uploadSuccess: 'Archivo subido exitosamente',
        using: 'En uso',
        status: 'Estado',
        rep: 'Rep',
        tags: 'Etiquetas',
        notes: 'Notas',
        uploadFile: 'SUBIR ARCHIVO',
        edit: 'EDITAR NOTAS',
        editNotesPlaceholder: 'Presiona el boton EDITAR NOTAS para añadir notas',
        save: 'GUARDAR',
        cancel: 'CANCELAR',
        continue: '¿Estás seguro de que deseas continuar?',
        preCleared: 'Pre-Autorización',
        removePreCleared:
          'Puede eliminar la designación de autorización previa solo para esta pista (cuando la autorización previa no es para todos los proyectos) o para todas las demás instancias de esta pista en esta división.',
        confirmPreClearanceRemoval: 'SÍ, ELIMINAR PREAUTORIZACIÓN',
        confirmPreClearanceRemovalOneTrack: 'ELIMINAR PARA ESTA PISTA',
        preClearTitle: 'Preautorizar Esta Cancione',
        preClear:
          'Para preparar esta cancione para cualquier persona en su división, seleccione el período y asigne fechas.',
        term: 'Período*',
        termSelect: 'Seleccione un Período',
        description: 'Descripción',
        enterDescription: 'Ingrese una descripción',
        done: 'HECHO',
        startDate: 'Fecha de Inicio*',
        endDate: 'Fecha de Finalización*',
        to: 'para',
        clearedBy: 'Despejado por',
        dateRange: 'Rango de Fechas',
        perpetuity: 'Perpetuidad',
        allProjectsInfoLabel: 'Todos los Proyectos',
        editPreCleared: 'Editar',
        startDateRequiredError: 'Fecha de inicio es obligatoria',
        startDateGreaterThanCurrentDateError: 'Fecha de inicio debe ser mayor o igual a la fecha actual',
        endDateRequiredError: 'Fecha de fin es obligatoria',
        endDateGreaterThanStartDateError: 'Fecha de fin debe ser mayor o igual a la fecha actual',
        termRequiredError: 'Período es obligatorio',
        allProjectsLabel: 'Todos los Proyectos',
        allProjectsPlaceholder: 'Todos los Proyectos',
        allProjectsRequiredError: 'Debes marcar una opción para Todos los Proyectos',
        yes: 'Sí',
        no: 'No',
      },
      tabs: {
        tasks: {
          buttons: {
            addTask: 'Añadir Tarea',
            inbox: 'Buzón',
            sent: 'Enviado',
            completed: 'Completado',
            myTeam: 'Mi Equipo',
          },
          headers: {
            assignee: 'Asignado',
            subject: 'Asunto',
            createdDate: 'Fecha de creación',
            dueDate: 'Fecha de vencimiento',
          },
        },
        titles: {
          clearances: 'Autorizaciones',
          licensing: 'Licenciantes',
          details: 'Detalles',
          writersPublishers: 'Autores / Editores',
          files: 'Archivos',
          useHistory: 'Historia de Uso',
          activity: 'Actividad',
          tasks: 'Tareas',
        },
        trackDetails: {
          master: 'Maestro',
          sync: 'Sync',
          other: 'Otros',
          actions: {
            addMaster: 'Agregar Licenciante Maestro',
            addSync: 'Agregar Licenciante Sync',
            addOther: 'Agregar Otro Licenciante',
          },
          trackInfoTitle: 'Información de la Canción',
          licensorsTitle: 'Licenciantes',
          oneStop: 'Una parada',
          combined: 'Combinados',
          separate: 'Separados',
          trackInfo: {
            title: 'Título',
            artists: 'Artistas',
            albums: 'Albumes',
            noResults: 'Sin resultados',
            duration: 'Duración',
            year: 'Año',
            isrc: 'ISRC',
            other: 'Otros',
            explicit: 'Explícito',
            companyDetails: 'Detalles de la Empresa',
            arrangers: 'Arreglador(es)',
            composers: 'Compositor(es)',
            unionDetails: 'Detalles sindicales',
            noUnion: 'Sin Sindicato',
            adCardCredits: 'Tarjeta Publicitaria / Fin de créditos de título',
            publicDomain: 'Dominio Público',
            mmss: 'MM:SS',
            master: 'Maestro',
            chooseOne: 'Elegir uno',
            publisher: 'Editor',
            genres: ' Género',
            containSamples: 'Contiene Muestra',
            newGenre: 'Crear nuevo Género',
            selectGenre: 'Seleccionar Género',
            notes: 'Notas del Género',
            additionalDetails: 'Datos Adicionales',
            ownedRights: 'Derechos de propiedad del Estudio',
            ownedSync: 'Derechos de sincronización del Estudio',
            createNewLibrary: 'Crear nueva Librería',
            copyrightTag: 'Etiqueta de Copyright',
            soundEffects: 'Efectos de sonido',
            libraryTracks: 'Libreria de canciones',
            score: 'Puntaje',
            tracks: 'Canciones',
            trackType: 'Tipo de Canción',
            libraryName: 'Nombre de la Librería',
            lastUpdatedByUserName: 'Última vez actualizado por {{userName}} en {{date}}',
          },
          licensors: {
            remove: 'Eliminar',
            removePrompt: '¿Estás seguro que deseás eliminar este licenciante: {{name}}?',
            master: {
              name: 'Nombre del Licenciante Maestro',
              share: '% Compartido',
              territory: 'Territorio',
              ownLicense: 'Tiene Licencia propia',
              contactFirstName: 'Nombre de Contacto',
              contactLastName: 'Apellido de Contacto',
              contactEmail: 'Email de Contacto',
              address1: 'Dirección Línea 1',
              address2: 'Dirección Línea 2',
              city: 'Ciudad',
              state: 'Estado/Prov',
              zipCode: 'Código Postal',
              country: 'País',
              addAnother: 'Agregar Master',
              remove: 'Eliminar',
              recordLabel: 'Etiqueta-Secundaria',
              payee: 'Receptor',
            },
            sync: {
              addAnother: 'Agregar Sync',
              remove: 'Eliminar',
              name: 'Nombre del Licenciante Sync',
              share: '% Compartido',
              territory: 'Territorio',
              ownLicense: 'Tiene Licencia propia',
              contactFirstName: 'Nombre de Contacto',
              contactLastName: 'Apellido de Contacto',
              contactEmail: 'Email de Contacto',
              address1: 'Dirección Línea 1',
              address2: 'Dirección Línea 2',
              city: 'Ciudad',
              state: 'Estado/Prov',
              zipCode: 'Código Postal',
              country: 'País',
              ownerSince: 'Dueño Desde',
              parentCompany: 'Compañía Padre',
            },
            other: {
              addAnother: 'Agregar Otro',
              remove: 'Eliminar',
              name: 'Nombre de la Compañía',
              share: '% Compartido',
              territory: 'Territorio',
              contactFirstName: 'Nombre de Contacto',
              contactLastName: 'Apellido de Contacto',
              contactEmail: 'Email de Contacto',
              address1: 'Dirección Línea 1',
              address2: 'Dirección Línea 2',
              city: 'Ciudad',
              state: 'Estado/Prov',
              zipCode: 'Código Postal',
              country: 'País',
              function: 'Función',
              otherLicensorType: 'Licenciante de Otro Tipo',
              create: 'Crear',
              noResults: 'Sin Resultados',
              company: 'Compania',
              individual: 'Individual',
              payee: 'Receptor',
            },
            recordLabel: {
              name: 'Nombre Etiqueta-Secundaria',
              labelId: 'ID de Etiqueta#',
              contactFirstName: 'Nombre de Contacto',
              contactLastName: 'Apellido de Contacto',
              contactEmail: 'Email de Contacto',
              address1: 'Dirección Línea 1',
              address2: 'Dirección Línea 2',
              city: 'Ciudad',
              state: 'Estado/Prov',
              zipCode: 'Código Postal',
              country: 'País',
              territory: 'Territorio',
              remove: 'Eliminar',
              addAnother: 'Agregar otro',
            },
            cueSheetPublishers: {
              title: 'Editores de hoja de Referencia',
              addAnother: 'Agregar Otro',
              remove: 'Eliminar',
              name: 'Nombre  de la Editorial',
              contactFirstName: 'Nombre de Contacto',
              contactLastName: 'Apellido de Contacto',
              contactEmail: 'Email de Contacto',
              address1: 'Dirección Línea 1',
              address2: 'Dirección Línea 2',
              city: 'Ciudad',
              state: 'Estado/Prov',
              zipCode: 'Código Postal',
              country: 'País',
              territory: 'Territorio',
              company: 'Compañía',
              ipi: 'IPI #',
            },
            payee: {
              type: 'Tipo',
              company: 'Compañía',
              individual: 'Individual',
              name: 'Nombre de la Compañía',
              contactFirstName: 'Nombre del Receptor',
              contactLastName: 'Apellido del Receptor',
              contactEmail: 'Email de contacto',
              tinId: 'TIN #',
              dba: 'DBA',
              sharePayment: 'Pago compartido',
              address1: 'Dirección 1',
              address2: 'Dirección 2',
              city: 'Ciudad',
              state: 'Estado/Provincia',
              zipCode: 'Código Postal',
              country: 'País',
              subtitle: 'Información del Cheque',
              void: 'Vacio',
              checkNumber: 'Numero de Cheque',
              checkRequestSent: 'Envio del Cheque',
              datePaid: 'Fecha de pago',
              remove: 'Eliminar',
              addAnother: 'Agregar otro',
              paymentAmount: 'Monto del pago',
            },
            oneStop: {
              title: 'Una Parada Combinada',
            },
          },
        },
        writersPublishers: {
          publishers: 'Editores',
          writersComposers: 'Autores / Compositores',
        },
      },
      notifications: {
        bookmarks: {
          created: 'Marcador creado',
          removed: 'Marcador eliminado',
        },
        library: {
          create: {
            success: 'Librería creada Exitosamente',
          },
        },
        genre: {
          create: {
            success: 'Género creado Exitosamente',
          },
        },
        option: {
          create: {
            success: 'Opción creada con éxito',
            failed: 'Ha ocurrido un error al crear la Opción. Por favor intentelo nuevamente',
          },
          update: {
            success: 'Opción actualizada con éxito',
            failed: 'Ha ocurrido un error al actualizar la Opción. Por favor intentelo nuevamente',
          },
        },
        update: {
          success: 'Canción Actualizada Exitosamente',
          failed: 'Hubo un error al actualizar la pista',
        },
      },
    },
    clearanceDetails: {
      requestedUse: 'Uso Requerido',
      actualUse: 'Uso Actual',
      updateSuccessMessage: 'Autorizacion Actualizada',
      sent: 'Enviada',
      received: 'Received',
      expires: 'Expires',
      notes: 'Notas de la Canción',
      edit: 'EDITAR NOTAS',
      editNotesPlaceholder: 'Añadir notas',
      save: 'GUARDAR',
      cancel: 'CANCELAR',
      lastupdated: 'Última actualización:',
      QUOTE_REQUEST: {
        sent: '<0>Cita</0> <1>Enviada</1>',
        received: '<0>Cita</0> <1>Recivida</1>',
        expires: '<0>Cita</0> <1>Expira</1>',
      },
      mfnWarning: 'No puede cambiar el MFN para este licenciante.',
      mfn: {
        BASE: 'BASE',
        WAIVED: 'RENUNC',
        SET_MFN: 'PONER',
        WAIVE: 'RENUNC',
        RESET: 'REINIC',
      },
      CONFIRMATION: {
        sent: '<0>Confirmación</0> <1>Enviada</1>',
        received: '<0>Confirmación</0> <1>Recivida</1>',
        expires: '<0>Confirmación</0> <1>Expira</1>',
      },
      LICENSE_REQUEST: {
        sent: '<0>Pedido de Licencia</0> <1>Enviada</1>',
        received: '<0>Pedido de Licencia</0> <1>Recivida</1>',
      },
      manual: 'Manual',
      grandTotal: 'IMPORTE TOTAL:',
      headerConfirmation: 'Confirmación ',
      headerFeeApproved: 'Tarifas Aprobadas',
      headerFeesEstimated: 'Tarifas Estimadas',
      headerLicense: 'Pedido de Licencia',
      headerMaster: 'Maestro',
      headerOther: 'Otro',
      headerQuote: 'Presupuesto',
      headerQuoteFull: 'Presupuesto completo',
      headerShare: 'Participación',
      headerSync: 'Sincronización',
      headerStatus: 'Estatus',
      tableMFN: 'NMF',
      tableTotals: 'TOTALES:',
      tableTotalsOption: 'TOTALES DE OPCIÓN:',
      clearances: 'Autorizaciones',
      rightsName: 'Derechos:',
      dateRange: 'Rango de Fechas:',
      date: 'Fecha:',
      duration: 'Duración',
      requested: 'Pedido',
      actual: 'Real',
      copyButton: 'COPIAR ==>',
      addUse: 'Agregar Uso',
      addTag: 'Agregar etiqueta',
      headerOneStop: 'Una Parada',
      addLicensorButton: 'AGREGAR LICENCIANTE',
      options: 'Opciones',
      fees: 'Tarifas:',
      exercise: 'Ejercitar',
      unexercise: 'Desejercitar',
      optionExercisePrompt: 'Por favor confirme que está ejercitando {{name}}.',
      optionUnexercisePrompt: '¿Seguro quiere desejercitar esta opción?',
      optionUnexerciseWithLettersPrompt:
        'La opción contiene Cartas de Autorización. ¿Seguro quiere desejercitar esta opción?',
      licensors: 'Licenciantes',
      details: 'Detalles',
      newOption: 'Nueva Opcion',
      editClearances: 'Modificar Autorización',
      newClearance: 'Nueva Autorización',
      addNew: 'Nuevo',
      clickOn: 'Cliquee ',
      toAddLicensor: 'para agregar Licenciante',
      waived: 'Renunciado',
      headerName: 'Nombre',
      letters: 'Cartas',
      lettersMenu: {
        CONFIRMATION: '{{newOrEdit}} Confirmación',
        edit: 'Editar',
        LICENSE_REQUEST: '{{newOrEdit}} Solicitud de Licencia',
        CHECK_REQUEST: '{{newOrEdit}} Solicitud de Cheque',
        LICENSE: '{{newOrEdit}} Solicitud de Licencia',
        new: 'Nueva',
        QUOTE_RENEWAL: '{{newOrEdit}} Renovación de Presupuesto',
        QUOTE_REQUEST: '{{newOrEdit}} Solicitud de Presupuesto',
      },
      letterPill: {
        QUOTE_SENT: 'PRES ENV',
        QUOTE_RECEIVED: 'PRES RCB',
        QUOTE_EXPIRED: 'PRES EXP',
        LICENSE_SENT: 'LIC ENV',
        LICENSE_RECEIVED: 'LIC RCVD',
        LICENSE_EXPIRED: 'LIC EXP',
        CHECK_REQUEST_SENT: 'CHK SOL ENV',
        CHECK_REQUEST_RECEIVED: 'CHK SOL RCVD',
        CONFIRMATION_SENT: 'CONF ENV',
        LICENSE_REQUEST_SENT: 'LIC SOL ENV',
        LICENSE_REQUEST_RECEIVED: 'LIC SOL RCB',
        VIEW_DATES: 'VER FECHAS',
        SET_DATES: 'ESTABLECER FECHAS',
      },
      featuredArtistSampleBadge: 'Artista Destacado / Muestra',
      licensorsMenu: {
        addUnion: 'Agregar Union',
        markAsNonUnion: 'Marcar como Non-Union',
        markAsFeaturedArtist: 'Marcar como Artista Destacado/Muestra',
        unmarkFeaturedArtist: 'Desmarcar Artista Destacado/Muestra',
      },
      unionOneStopChangeDialogContent:
        'Las uniones se eliminarán para los licenciatarios que no son maestros, pero se mantendrán para los licenciatarios maestros. ¿Estás seguro de que quieres proceder?',
      markAsFeaturedArtistMessage: 'El licenciante establecido como Artista Destacado/Muestra para esta pista',
      unmarkFeaturedArtistMessage:
        'El licenciante ya no está establecido como Artista Destacado/Muestra para esta pista',
    },
    userManagement: {
      userPermissions: {
        headers: {
          globalPermissions: 'Permisos globales',
          productionList: 'Lista de producción',
          tooltip:
            'Lista de proyectos y producciones para los que el permiso del usuario es DIFERENTE de su rol de proyecto predeterminado. Vaya a la acción Producción > Compartir para cambiar o eliminar el permiso del usuario.',
          userBreadcrumb: 'Usuarios',
          userDeactivated: 'DESACTIVADO',
        },
        buttons: {
          deactivateUser: 'DESACTIVAR USUARIO',
          reactivateUser: 'REACTIVAR USUARIO',
          resendInvite: 'REACTIVAR USUARIO',
        },
        labels: {
          defaultPermissions: 'Rol de proyecto predeterminado',
          email: 'Correo electrónico',
          firstName: 'Nombre',
          lastName: 'Apellido',
          permissions: 'Permisos',
          phone: 'Teléfono',
          productionName: 'Nombre de producción',
          title: 'Título',
        },
        tooltip:
          'Este rol será asignado al usuario en TODAS las producciones, a menos que sean sobreescritas con un permiso superior en producciones específicas. Elegí NINGUNO si no querés otorgarle acceso a TODAS las Producciones.',
        prompts: {
          deactivateUser: '¿Estás seguro que quieres desactivar este usuario?',
          deletePermission: '¿Estás seguro que quieres eliminar este permiso?',
          reactivateUser: '¿Estás seguro que quieres reactivar a este usuario?',
        },
        apiCall: {
          success: 'La Invitación se re-envió con éxito',
          failed: 'Ha ocurrido un error al re-enviar la Invitación. Por favor intentelo nuevamente',
          permissionsUpdated: 'Los permisos se han actualizado con éxito',
          permissionsFailed: 'Ha ocurrido un error al actualizar los permisos',
        },
        globalPermissions: 'Permisos Globales',
        divisionAdmin:
          'Este usuario es el administrador de la división y tiene acceso a todas las producciones de esta división.',
        defaultRoleDescription: 'Este usuario es un {{role}} para todas las producciones de esta división.',
      },
    },
    userProfile: {
      password: {
        changePassword: 'Cambiar contraseña',
        labelConfirmPassword: 'Confirmar contraseña nueva',
        labelCurrentPassword: 'Contraseña actual',
        labelNewPassword: 'Contraseña nueva',
        successMessage: 'Su contraseña ha sido actualizada',
        title: 'Contraseña',
      },
      personalDetails: {
        labelEmail: 'Correo electrónico',
        labelFirstName: 'Nombre',
        labelLastName: 'Apellido',
        labelPhone: 'Teléfono',
        labelTitle: 'Título',
        title: 'Detalles personales',
      },
      signature: {
        title: 'Archivo de firma',
        uploadSignature: 'Agregar una firma',
      },
      supportedFileTypes: 'Tipos de archivo soportados: JPG, PNG',
      title: 'Mi Perfil',
      uploadPicture: 'Agregar una foto',
    },
    cueContainerVersionManager: {
      current: 'Actual',
      assets: 'Materiales',
      chooseAsset: 'Elegir Material',
      trackList: 'Lista de Canciones',
      cueList: 'Lista de Pistas',
      cues: 'Pistas',
      searchCues: 'Buscar Pistas',
      searchCuesPlaceholder: 'Buscar Pistas por Nombre',
      close: 'Cerrar',
      populate: 'Popular',
      actionMenu: {
        markAsUsing: 'Marcar en Uso',
        edit: 'Editar',
        duplicate: 'Duplicar',
        populateFromCurrent: 'Popular desde Versión Actual',
        makeCurrentVersion: 'Convertir en Versión Actual',
        confirmMakeCurrentVersion: 'Esto actualizará esta versión para que sea la versión actual',
        confirmPopulateFromCurrent:
          '¿Está seguro de que desea reemplazar todas las señales en esta versión con las señales de la versión actual?',
        confirmDelete: '¿Seguro desea eliminar?',
        delete: 'Borrar',
      },
      getStarted: 'Para empezar puedes:',
      populateFirst: 'Toma un atajo para popular la versión con la versión actual o arrastra pistas dentro',
      notifications: {
        copy: {
          success: 'Material copiado con éxito',
        },
        update: {
          success: 'Material actualizado con éxito',
        },
      },
      addVersion: 'Agregar Versión',
    },
    scenarioManager: {
      tracksMarkedUsing: 'Pistas marcadas en uso',
      confirmCurrentContent:
        'Aplicando el escenario, el órden de los cues va a cambiar para coincidir con el mismo, las Pistas que no estén en éste escenario serán desactivadas.',
      confirmCurrentTitle: 'Marcar como actuál',
      alphabetical: 'Alfabéticamente',
      cues: 'Pistas',
      searchCues: 'Buscar Pistas',
      searchCuesPlaceholder: 'Buscar Pistas por Nombre',
      close: 'Cerrar',
      totals: 'Totales',
      populate: 'Popular',
      current: 'Actual',
      getStarted: 'Para empezar puedes:',
      populateFirst: 'Toma un atajo para popular la versión con la versión actual o arrastra pistas dentro',
      approved: 'Aprovado',
      estimated: 'Estimado',
      copy: 'Copia',
      cueList: 'Lista de Cues',
      scenes: 'Escenas',
      versions: 'Versiones',
      addScenario: 'Agregar Version',
      actionMenu: {
        markAsUsing: 'Marcar canciones como "En Uso"',
        edit: 'Editar',
        duplicate: 'Duplicar',
        populateFromCurrent: 'Popular desde el actual',
        makeCurrentVersion: 'Convertir en Actual',
        delete: 'Borrar',
        confirmPopulateFromCurrent:
          '¿Está seguro de que desea reemplazar todas las señales en esta versión con las señales de la versión actual?',
        confirmDelete: 'Seguro quieres eliminar?',
      },
      notification: {
        update: 'Escenario actualizado con éxito!',
      },
    },
    qwireAdmin: {
      title: 'Qwire Admin',
      tenants: {
        audit: 'Registro de Auditoría',
        title: 'Lista de Tenants',
        name: 'Nombre',
        tenantSearchPlaceholder: 'Busca por nombre de tenant',
        divisionSearchPlaceholder: 'Busca por nombre de división',
        divisions: 'Divisiones',
        editLogo: 'Editar Logo',
        idleTimeout: 'Tiempo de Bloqueo',
        tenantName: 'Nombre del tenant',
        addDivision: 'Agregar División',
        addTenant: 'Agregar Tenant',
        divisionUsers: 'Usuarios de la Division',
        clearApp: 'Clear',
        cueApp: 'Cue',
        permissionsTableColumns: {
          defaultProjectRole: 'Rol de proyecto por defecto',
          canAccessClear: 'Acceder a Clear',
          canAccessCue: 'Acceder a Cue',
          isDivisionAdmin: 'Administrador de division',
          viewUpdateFinantial: 'Ver y Actualizar Informacion Financiera',
          createUpdateContacts: 'Crear y Actualizar Contactos',
          viewReports: 'Ver Reportes',
          viewTrackHistory: 'Ver Toda la Historia de la Cancion',
          createUpdateRight: 'Crear y Actualizar Derechos',
          createUpdateLetters: 'Crear y Actualizar Cartas',
          createUpdateLetterTemplates: 'Crear y Actualizar Plantillas de Cartas',
          createTasks: 'Crear Tareas',
          createProductions: 'Crear Producciones',
          features: 'Crear Lanzamientos',
          marketing: 'Crear Campañas',
          television: 'Crear Temporadas',
        },
        userName: 'Nombre de Usuario',
        activeApps: 'Aplicaciones Activas',
        theLogo: 'El logo aparecerá en distintos lugares de la aplicación',
        inviteUsers: 'Invitar Usuarios',
        inviteInfo:
          'IMPORTANTE: Invitando a un usuario y configurando su Rol de Trabajo por Defecto, estas autorizando acceso al usuario a TODAS las Producciones de esta Division. Si solo querés invitar a una sola producción, navega a esa producción y clickea en “Compartir” en el menu.',
        cueSheetPartner: {
          select: 'Selecciona una opción',
          title: 'Asociado de Lista de Pistas',
          submitToTenant: 'Presentar Tenant?',
          removable: 'Removible?',
          preferredFileType: 'Tipo de Archivo Preferido',
          email: 'Email',
          electronic: 'Electronico',
        },
      },
      employees: {
        buttons: {
          addUser: 'Agregar Usuario',
          deactivateUser: 'Desactivar User',
          reactivateUser: 'Reactivar User',
        },
        labels: {
          addUser: 'Agregar Usuario',
          email: 'Correo Electrónico',
          emails: 'Correos Electrónicos',
          firstName: 'Nombre',
          lastName: 'Apellido',
          userDeactivated: 'DESACTIVADO',
          users: 'Usuarios',
        },
        prompts: {
          deactivateUser: '¿Estás seguro que quieres desactivar este usuario?',
          reactivateUser: '¿Estás seguro que quieres reactivar a este usuario?',
        },
        searchPlaceholder: 'Buscar empleados por nombre',
        title: 'Empleados Qwire',
      },
      libraries: {
        title: 'Librerias',
        header: {
          title: 'Importar un archivo de una Librería',
          dropdownLabel: 'Librería',
          dropdownPlaceholder: 'Selecionar Libraría',
          buttonCancel: 'Cancelar',
          buttonImport: 'Importar',
        },
        dropzone: {
          fileInputLabel: 'File',
        },
        notifications: {
          import: 'El archivo ha sido importado satisfactoriamente',
        },
      },
      rapidcue: {
        title: 'RapidCue',
        header: {
          title: 'RapidCue Información de Conexión',

          subTitle: 'RapidCue Credenciales de Acceso',
        },
        labels: {
          productionMode: 'Modo de Producción (apagar para el modo de prueba)',
          pollInterval: 'Intervalo de Encuesta (en minutos)',
          login: 'Acceso',
          password: 'Contraseña',
        },
      },
    },
    divisionSettings: {
      divisionName: 'Nombre de la División',
      billingDetails: 'Detalles de Facturación',
      billingContactName: 'Contacto de Facturación',
      billingContactEmail: 'Email de Contacto de Facturación',
      billingContactPhone: 'Teléfono de Contacto de Facturación',
      billingContactAddress: 'Dirección de Contacto de Facturación',
      editStatuses: 'Editar Estados',
      pathForDashboard: 'Dirección al Tablero de Reportes',
      pathForReports: 'Dirección a los Reportes',
      statuses: 'Estados',
      theLogo: 'El logo aparecerá en distintos lugares de la aplicación',
      quoteExpirationNumberOfDays: 'Presupuestos-Dias hasta que expire',
      licenseExpirationNumberOfDays: 'Licencias-Dias hasta que expire',
    },
    tasks: {
      title: 'Tareas',
      tabs: {
        inbox: 'Recibidos',
        sent: 'Enviados',
        completed: 'Completados',
        myTeam: 'Mi Equipo',
      },
      columns: {
        assignee: 'Asignado',
        division: 'División',
        subject: 'Asunto',
        track: 'Título de la canción / Artista',
        production: 'Producción',
        requestor: 'Solicitante',
        created: 'Fecha de creación',
        due: 'Fecha de vencimiento',
      },
      emptyHint: 'No hay Tareas para mostrar',
    },
    notifications: {
      category: {
        /** @see src/redux-core/notifications/constants.js */
        filmAlerts: 'Alertas de Películas',
        general: 'General',
        marketingAlerts: 'Alertas de Marketing',
        tvAlerts: 'Alertas de TV',
        taskAlerts: 'Alertas de Tareas',
        trackAlerts: 'Alertas de canciones',
      },
      defaultProjectName: 'Proyecto',
      defaultCampaignName: 'Campaña',
    },
    reports: {
      title: 'Reportes',
      askForAI: 'Preguntar a la IA',
      selectDivision: 'Seleccione una división',
      selectProductionType: 'Seleccione un tipo de producción',
      selectReport: 'Seleccione un reporte',
      division: 'División',
      reportName: 'Nombre del Reporte',
      onErrorContent: 'Hubo un problema para conseguir los reportes',
      export: 'Exportar',
    },
    globalSearch: {
      advancedSearch: 'Búsqueda avanzada',
      searchTerm: 'Término de Búsqueda',
      filters: {
        divisions: 'Divisiones',
        date: 'Fecha',
        startDate: 'Fecha de Inicio',
        endDate: 'Fecha de Fin',
        status: 'Estado',
        division: 'División',
        showArchived: 'Mostrar Archivados',
        showInactived: 'Mostrar Producciones Desactivadas',
        filterBy: {
          productions: 'Producciones',
          licensors: 'Licenciantes',
          tracks: 'Canciones',
        },
        productionSearch: {
          PROJECT_NAME: 'Nombre de proyecto',
          EPISODE_NAME: 'Nombre de episodio',
          PRODUCTION_NUMBER: 'Número de producciones',
          CUE_SHEET_SEGMENT_NAME: 'Nombre de segmento de la Hoja de Referencia',
        },
        trackSearch: {
          ARTIST: 'Artista',
          LIBRARY_NAME: 'Librería',
          PUBLISHER_NAME: 'Publicante',
          TRACK_TITLE: 'Canción',
          WRITER_NAME: 'Escritor',
        },
        licensorSearch: {
          CONTACT_NAME: 'Contacto',
          LICENSOR_NAME: 'Licenciante',
          SUB_LABEL_NAME: 'Sub Etiqueta',
        },
        dateTypes: {
          ALL: 'Todos',
          INTL_RELEASE: 'Lanzamiento Internacional',
          US_RELEASE: 'Lanzamiento US',
        },
      },
      results: {
        approvedFees: 'Tarifa Aprobada',
        requestedUse: 'Uso Requerido',
        status: 'Estado',
        season: 'Temporada {{number}}',
        inactive: 'INACTIVO',
      },
      noResults: {
        description: 'Sin resultados.',
        clear: 'Borre',
        yourSearch: 'su búsqueda',
        or: 'o',
        addProject: 'Agregue un Proyecto',
      },
      details: {
        title: 'Detalles',
        options: {
          view: 'Ver',
          viewProduction: 'Ver Producción',
          viewEditProjectDetails: 'Editar Detalles',
          share: 'Compartir',
          activateProduction: 'Activar Producción',
          inactivateProduction: 'Desactivar Producción',
          activateEpisode: 'Activar Episodio',
          inactivateEpisode: 'Desactivar Episodio',
          export: 'Exportar',
          archive: 'Archivar',
          add: {
            features: 'Agregar Lanzamiento',
            marketing: 'Agregar Campaña',
            television: 'Agregar Temporada',
            episode: 'Agregar Episodio',
          },
        },
        viewTrack: 'Ver Canción',
        track: {
          actualUse: 'Uso Real',
          approvedFees: 'Tarifa Aprobada',
          artists: 'Artistas',
          assetDescription: 'Descripción del Material',
          assetTitle: 'Título del Material',
          estimatedFees: 'Tarifa Estimada',
          library: 'Librería',
          master: 'Licenciantes Maestros',
          project: 'Proyecto',
          requestedUse: 'Uso Requerido',
          sceneDescription: 'Descripción de la Escena',
          sceneTitle: 'Título de la Escena',
          sync: 'Licenciantes Sync',
          trackTitle: 'Título de Canción',
          writers: 'Escritores',
        },
        licensor: {
          licensorName: 'Nombre de Licenciante',
          licensorType: 'Tipo de Licenciantes',
          trackTitle: 'Título de Canción',
          writers: 'Escritores',
          subLabel: 'Etiqueta secundaria',
          project: 'Proyecto',
          assetTitle: 'Título del Material',
          assetDescription: 'Descripción del Material',
          sceneDescription: 'Descripción de la Escena',
          sceneTitle: 'Título de la Escena',
          requestedUse: 'Uso Requerido',
          actualUse: 'Uso Real',
          approvedFees: 'Tarifa Aprobada',
          estimatedFees: 'Tarifa Estimada',
        },
        feature: {
          cueSheetSegments: 'Nombre de segmento de la Hoja de Referencia',
          intlReleaseDate: 'Lanzamiento Internacional',
          project: 'Proyecto',
          usReleaseDate: 'Lanzamiento en Estados Unidos',
        },
        marketing: {
          assetName: 'Nombre del Material',
          assetType: 'Tipo de Material',
          version: 'Versión',
        },
        tv: {
          episode: 'Episodio',
          add: 'Agregar',
          filter: 'Filtrar',
          search: 'Buscar episodios',
        },
      },
      searchResults: 'Search Results ({{count}})',
      projectsSearchResults: 'Visualizando {{count}} proyectos',
      tabs: {
        productions: 'Producciones',
        tracks: 'Canciones',
        licensors: 'Licenciantes',
      },
    },
    requotePrompt: {
      content:
        'Hizo un cambio en los derechos para una o mas canciones que no estan cubiertas actualmente. Puede RECOTIZAR la Autorización actual y los nuevos derechos se aplicaran a la misma, o puede crear una NUEVA AUTORIZACION que le permitirá autorizar los derechos por separado',
      firstOption: 'NUEVA AUTORIZACION',
      secondOption: 'RECOTIZAR',
      title: 'Cambio en el uso de la(s) Cancion(es)',
    },
    containerPosition: {
      move: 'Mover',
    },
    searchItem: {
      Campaign: 'Campaña de Marketing',
      FilmRelease: 'Lanzamiento de Película',
      Episode: 'Episodio de serie',
      Track: 'Pista',
    },
    licensing: {
      typeahead: {
        companyName: 'Compañía',
        email: 'Email',
        name: 'Contacto',
      },
      startUsing: 'Comience a trabajar con Licencias apretando en',
      filterButton: '<0>el botón</0><1/><2> de filtros</2>',
      title: 'Licenciante',
      filters: 'FILTROS',
      showInactived: 'Mostrar Inactivos',
      showingCount: 'Mostrando {{firstItem}}-{{lastItem}} de {{total}}',
      groupByLicensors: 'Agrupar por Licenciantes',
      cues: 'Pistas',
      noResultsFilter: 'Sin resultados. Prueba diferentes filtros',
      noResults: 'Sin resultados.',
      filterTags: {
        tracks: 'Canciones',
        productions: 'Producciones',
        status: 'Estado',
        licensors: 'Licenciantes',
      },
      clearanceTable: {
        production: 'Proyecto / Producción',
        trackTitleArtists: 'Título de la cación / Artista',
        clearance: 'Autorización',
        licensor: 'Licenciante',
        approvedFee: 'Tarifa Aprovada',
        usage: 'Uso Actual',
        status: 'Estado',
        rep: 'Representante',
        term: 'Término',
        territory: 'Territorio',
        rights: 'Derechos',
        details: 'Detalles',
      },
      licensingTable: {
        tableLabel: 'Tabla de Licencias',
        trackTitle: 'Título de la Pista',
        licensor: 'Licenciante',
        clearance: 'Aprobación',
        rights: 'Derechos y Opciones',
        approvedFee: 'Tarifa Aprobada Total',
        usage: 'Uso Real',
        status: 'Estado',
        rep: 'Representante',
        expandRow: 'Expandir fila',
      },
      payeesTable: {
        name: 'Nombre',
        share: 'Compartir',
        po: 'Orden de Compra #',
        invoice: 'Factura #',
        checkAmount: 'Cantidad del Cheque',
        check: 'Cheque #',
        datePaid: 'Fecha de Pago',
        void: 'Anular',
        add: 'Agregar',
        addPayee: 'Agregar beneficiario',
        addPayeeHintMessage: 'Agrega las empresas o individuos a los que necesitas pagar',
        totalAmount: 'Monto Total',
        totalAmountHintMessageMatch: 'El Monto Total es igual a los Honorarios Aprobados Totales',
        totalAmountHintMessageMismatch: 'El Monto Total no es igual a los Honorarios Aprobados Totales',
        letters: 'Cartas',
        createLetter: 'Crear Carta',
        fileUpload: 'Subir Archivo',
        addDates: 'Agregar Fechas',
      },
      payees: {
        letters: 'CARTAS',
        editLicenseReq: 'Editar Requisito de Licenciante',
        newLicense: 'Nuevo Licenciante',
        newCheckReq: 'Nuevo Requisito de Cheque',
        title: 'Receptor',
        confirmCancel: '¿Seguro quiere cancelar?',
        name: 'Nombre',
        sharePayments: 'Porcentaje compartido',
        purchaseOrder: 'Orden de Compra',
        invoice: 'Número de Factura',
        checkAmount: 'Monto del Cheque',
        checkNumber: 'Número del Cheque',
        datePaid: 'Fecha de Pago',
        void: 'Vacío',
        check: 'CHEQUE',
        upload: 'CARGAR',
        newPayee: 'NUEVO RECEPTOR',
        confirmDelete: '¿Seguro quiere eliminar el cheque?',
        licenseRequest: 'Pedido de Licencia',
        license: 'Licencia',
        checkRequest: 'Pedido de Cheque',
        total: 'Total',
        clickOn: 'Cliquee ',
        toAddPayee: 'para agregar Receptor',
        notifications: {
          update: 'Receptor editado con éxito',
          create: 'Receptor creado con éxito',
        },
      },
      track: {
        clearance: 'Autorización',
        usage: 'Uso',
        status: 'Estado',
      },
    },
    mainCueSheets: {
      title: 'Hojas de Pistas',
      loading: 'Cargando Hojas de Pistas...',
      filters: {
        action: 'Acción',
        showCompleted: 'Incluir Completado',
        showInactived: 'Mostrar Producciones Desactivadas',
        fields: {
          all: 'Todos',
          artist: 'Artista',
          cueSheetIds: 'IDs de Hoja de Pistas',
          cueSheetSegments: 'Segmentos de Hoja de Pistas',
          productionName: 'Nombre de la Producción',
          productionNumber: 'Número de Producción',
          projectName: 'Nombre del Proyecto',
          segmentName: 'Nombre de Segmento',
          trackTitle: 'Título de la Pista',
        },
      },
      tableHeader: {
        cueSheetUrgency: 'Urgente',
        cueSheet: 'IDs de Hoja de Pistas',
        project: 'Proyecto',
        production: 'Producción',
        version: 'Versión',
        status: 'Estado',
        rapidCue: 'RapidCue',
        assignee: 'Asignado',
        airDate: 'Airdate',
        notes: 'Notas',
      },
      urgencyLevel: {
        Off: 'No urgente',
        Medium: 'Medio',
        High: 'Alto',
        clear: 'Limpiar',
      },
      forms: {
        assignee: {
          header: 'Asignar Rep',
          label: 'Nombre',
          disabledHint: 'Los usuarios pueden ser deshabilitados si no tienen acceso a una o más Hoja de Pistas',
        },
        notes: {
          header: 'Editar Notas',
          label: 'Notas',
        },
        status: {
          header: 'Cambiar Estado',
          label: 'Estado',
        },
        filters: {
          header: 'Filtrar',
          urgency: {
            label: 'Urgente',
            Off: 'No urgente',
            Medium: 'Medio',
            High: 'Alto',
          },
          division: 'División',
          airStartDate: 'Fecha de Inicio',
          airEndDate: 'Fecha de Fin',
          status: 'Estado',
          rapidCue: 'RapidCue',
          rapidCueStartDate: 'RapidCue Fecha de Inicio',
          rapidCueEndDate: 'RapidCue Fecha de Fin',
          assignee: 'Asignado',
          broadcaster: 'Emisor',
          productionCompany: 'Compañía de Producción',
          allOption: 'Todos',
          clearFilters: 'Limpiar Filtros',
          productionType: 'Tipo de Producción',
          movie_cuesheet: 'Lanzamiento',
          campaign_cuesheet: 'Campaña',
          series_cuesheet: 'Episodio',
          rapidCueStatus: {
            none: 'Ninguna',
            warning: 'Aceptado (Advertencia)',
            missingInfo: 'Información faltante',
            readytoSend: 'Listo para enviar',
          },
        },
        edit: 'Editar',
      },
      actionMenu: {
        assignee: 'Cambiar Asignado',
        status: 'Cambiar Estado',
        rapidCue: 'Presentear a la RapidCue',
        rapidCueSubmitting: 'Presentando',
        export: 'Exportar',
        disabledItemMessage: 'No puede seleccionar hojas de referencia de varias divisiones',
      },
      rapidCueModal: {
        title: 'Presentar a la RapidCue',
        primaryHeading: 'A la hoja de referencia le faltan datos y no se puede enviar a RapidCue.',
        secondaryHeading: 'Corrija los siguientes errores. Envíe de nuevo a RapidCue.',
        goToCueSheet: 'Ir a la hoja de referencia',
        toastSuccess: '{{count}} hoja(s) de referencia enviada(s) con éxito.',
        toastError: '{{count}} hoja(s) de referencia fallaron en la validación.',
        validationSuccess: 'Cue Sheet se puede enviar a RapidCue.',
        readytoSend: 'Listo para enviar',
      },
    },
    mainLicensors: {
      title: 'Licenciatarios',
      subtitle: 'Mostrando {{pageInterval}} de {{totalCount}} licenciatarios',
      loading: 'Cargando licenciatarios',
      tableHeader: {
        project: 'Proyecto',
        production: 'Producción',
        track: 'Pista',
        licensorType: 'Tipo de licenciante',
        licensorName: 'Nombre del licenciante',
        share: 'Compartir',
        status: 'Estado',
        notes: 'Notas',
        rights: 'Derechos',
        approvedFees: 'Tarifas aprobadas',
        artist: 'Artista',
        division: 'División',
        using: 'Usando',
        options: 'Opciones',
        tags: 'Etiquetas',
        sceneTitle: 'Título de la escena',
        sceneDescription: 'Descripción de la escena',
        requestedUseDuration: 'Uso y duración solicitados',
        rep: 'Representante',
        quotedFees: 'Tarifas Cotizadas',
      },
      drawer: {
        letter: {
          disclosureButton: 'Crear una carta',
          title: 'Crear una carta del Anexo A',
          licensorSelect: 'Licenciante para la carta',
          typeSelect: 'Tipo de carta',
          submit: 'Crear carta',
          typeRequired: 'Por favor selecciona un tipo de letra',
        },
        uploadFile: {
          disclosureButton: 'Cargar archivo',
          title: 'Cargar archivo',
          categorySelect: 'Categoría',
          status: 'Estado',
          submit: 'Guardar',
          filePicker: 'Adjuntar archivo',
          filePickerPlaceholder: 'Haz clic para cargar o arrastra y suelta',
          filePickerPlaceholderSecondary: 'Máximo 3MB',
          fileIsTooLarge: 'Archivo demasiado grande',
          fileComplete: 'Completado',
          successMessage: 'Archivo(s) subido(s) con éxito.',
          noFileAttached: 'Por favor, sube al menos un archivo.',
          categoryRequired: 'Por favor selecciona una categoría',
        },
        updateStatus: {
          disclosureButton: 'Actualizar estado',
          title: 'Actualizar Estado',
          status: 'Estado',
          submit: 'Guardar',
          successMessage: 'Estado actualizado con éxito.',
          statusRequired: 'Por favor selecciona un estado',
        },
        tracks: {
          heading: 'Pistas',
          mixedTypesHint:
            'Tu lista contiene tanto licenciatarios de Master como de Sync. Por favor, edita la lista para incluir licenciatarios de Master, Sync, o One-Stop.',
          removeTooltip: 'Quitar de la lista',
        },
        alerts: {
          letterEmptySelection: 'Para crear una carta, seleccione uno o más licenciantes',
          uploadEmptySelection: 'Para subir un archivo, seleccione uno o más licenciantes',
          updateStatusEmptySelection: 'Para actualizar un estado, seleccione uno o más licenciantes',
          multipleDivisions:
            'Ha seleccionado licenciantes que pertenecen a diferentes divisiones. Por favor, actualice su selección y limite a una sola división.',
        },
      },
      filters: {
        disclosureButton: 'Filtro',
        disclosureButton_plural: 'Filtros',
        addFilters: 'Agregar filtros',
        divisionPlaceholder: 'Seleccione una división',
        statusPlaceholder: 'Seleccione un estado',
        showInactive: 'Mostrar Desactivadas',
      },
      emptyState: {
        noResults: 'No hay resultados.',
        noResultsForSearch: {
          closingHint: '. Intenta ajustar tu búsqueda.',
        },
        noResultsForFilters: {
          closingHint: '. Intenta ajustar tus filtros.',
        },
        noResultsForSearchAndFilters: {
          preSearch: 'No hay resultados para',
          postSearch: 'y',
          postFilters: 'filtros',
          closingHint: '. Intenta ajustar tus filtros y/o búsqueda.',
        },
      },
    },
    trackDetails: {
      asset: 'Material',
      scene: 'Escena',
      actual: 'Real',
      requested: 'Solicitado',
      licensors: 'Licenciantes',
      trackNotes: 'Notas de Trilha',
      trackUsage: 'Uso',
      rightsAndOptions: 'Derechos y Opciones',
      total: 'Total',
      totals: 'Totales',
      totalShare: 'Participación Total',
      master: 'Master',
      sync: 'Sync',
      oneStop: 'Una Parada',
      addMaster: 'Agregar maestro',
      addSync: 'Agregar sincronización',
      addOther: 'Añadir otro',
      addOneStop: 'Agregar una parada',
      addNotes: 'Agregar notas',
      setDates: 'Establecer fechas',
      addUsage: 'Agregar uso',
      addOption: 'Añadir opciones',
      addRights: 'Agregar derechos',
      addLicensors: 'Agregar Licenciantes',
      editLicensor: 'Editar Licenciante',
      addUnion: 'Agregar Union',
      markNonUnion: 'Marcar como Non-Union',
      noLicensors: 'Sin licenciantes',
      noLicensorsDescription: 'Licenciantes se enumerarán aquí',
      noTotals: 'Sin totales',
      noTotalsDescription: 'Las tarifas totales y las acciones se enumerarán aquí',
      noRightsAndOptions: 'Sin Derechos/Opciones',
      noRightsAndOptionsDescription: 'Los derechos y las opciones se enumerarán aquí.',
      noUsage: 'Sin uso',
      noUsageDescription: 'El uso activo se enumerará aquí',
      addClearance: 'Añadir Liquidación',
      rename: 'Renombrar',
      delete: 'Borrar',
      showInactive: 'Mostrar Inactivos',
      makeActive: 'Hacer Activo',
      makeInactive: 'Hacer Inactivo',
      onLetterAs: 'En Carta Como',
    },
    tracksView: {
      title: 'Canciones',
      subtitle: 'Mostrando {{pageInterval}} de {{totalCount}} canciones',
      loading: 'Cargando canciones',
      tableHeader: {
        division: 'División',
        project: 'Proyecto',
        production: 'Produccion',
        sceneTitle: 'Título de la Escena',
        sceneDescription: 'Descripción de la Escena',
        clearance: 'Autorización',
        track: 'Cancion',
        artist: 'Artista',
        status: 'Estado',
        masterLicensorNames: 'Licenciantes Maestros',
        labels: 'Etiqueta de Registro',
        masterLicensorFees: 'Tarifa de Licenciantes Maestros',
        syncLicensorNames: 'Licenciantes Sync',
        syncLicensorFees: 'Tarifa de Licenciantes Sync',
        publishers: 'Editores',
        writers: 'Escritores',
        using: 'En uso',
        tags: 'Etiquetas',
        notes: 'Notas',
        term: 'Término',
        territories: 'Territorios',
        rights: 'Derechos',
        options: 'Opciones',
      },
      filters: {
        disclosureButton: 'Filtro',
        disclosureButton_plural: 'Filtros',
        addFilters: 'Agregar filtros',
        divisionPlaceholder: 'Seleccione una división',
        statusPlaceholder: 'Seleccione un estado',
        showInactive: 'Mostrar Desactivadas',
      },
      addTrackButton: 'Agregar una pista',
    },
    moveTrackToProduction: {
      button: 'Mover a Producción',
      trackMoved: 'Movida para {{sceneDescription}}',
      selectAScene: 'Por favor, seleccione una escena.',
      visit: 'Visitar',
      warningAboutMove: 'Si esta pista se incluye varias veces en su producción, todas las pistas se moverán.',
      searchInputPlaceholder: 'Mover pista a...',
    },
    qwireDataGridUIConfigManager: {
      renameView: 'Renombrar vista',
      deleteView: 'Eliminar vista',
      addView: 'Agregar vista',
      deleteConfirmation: 'Está seguro de que desea eliminar esta vista?',
    },
    unions: {
      details: {
        status: 'Estado',
        useType: 'Tipo',
        fee: 'Tarifa',
        editButton: 'Editar',
        removeButton: 'Eliminar',
        unionRemoved: 'Union/No-Union eliminada correctamente',
        removeUnionPromptMessage: 'La Union será eliminada. ¿Seguro desea continuar?',
        removeNonUnionPromptMessage: 'La Non-Union será eliminada. ¿Seguro desea continuar?',
      },
    },
    contactSearch: {
      placeholder: 'Buscar por compañía o contacto',
    },
  },
};

export default es;
