/* ---------------- Reordering deps for eslint breaks the app --------------- */
/* eslint-disable */
import React, { lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import ViewsContainer from './Layout/ViewsContainer';
import LoginContainer from './Layout/LoginContainer';
import LoginCallback from '../LoginCallback';
import ROUTES from './routes';
import Letters from 'components/Letters';
import { CLEAR_PERMISSIONS_GROUPS, CLEAR_PERMISSIONS, CUE_PERMISSIONS } from 'redux-core/permissions/constants';
import { APMSignUp } from 'components/APMSignUp';
import { ComposeLetter } from 'components/ComposeLetter';

export const HOMEPAGE = ROUTES.PROJECTS.CLEARANCE;
const Projects = lazy(() => import('components/Projects'));
const Tasks = lazy(() => import('components/Tasks'));
const Settings = lazy(() => import('components/Settings'));
const Mail = lazy(() => import('components/Mail'));
const UserProfile = lazy(() => import('components/UserProfile'));
const Account = lazy(() => import('components/Account'));
const Login = lazy(() => import('components/Login'));
const Logout = lazy(() => import('components/Logout'));
const ProductionOverview = lazy(() => import('components/ProductionOverview'));
const QwireAdmin = lazy(() => import('components/QwireAdmin'));
const Reports = lazy(() => import('components/Reports'));
const NewReports = lazy(() => import('components/NewReports'));
const TrackLayout = lazy(() => import('components/TrackLayout'));
const Licensing = lazy(() => import('components/Licensing'));
const ImportCueSheet = lazy(() => import('components/ImportCueSheet'));
const MainCueSheet = lazy(() => import('components/MainCueSheet'));
const Tracks = lazy(() => import('components/Tracks'));
const Licensors = lazy(() => import('components/Licensors'));
// Re-enabling eslint again so get lint for the rest of the file, ignoring only the imports.
/* eslint-enable */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable arrow-body-style */

const DefaultView = () => <Redirect to={HOMEPAGE} />;

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        component={() => (
          <ViewsContainer px={2}>
            <DefaultView />
          </ViewsContainer>
        )}
      />
      <Route
        exact
        path={ROUTES.LOGIN}
        component={() => (
          <LoginContainer>
            <Login />
          </LoginContainer>
        )}
      />
      <Route
        exact
        path={ROUTES.ACCOUNT}
        component={() => (
          <LoginContainer>
            <Account />
          </LoginContainer>
        )}
      />
      <Route exact path={ROUTES.PARTNER} component={() => <APMSignUp />} />
      <PrivateRoute
        exact
        path={ROUTES.TASKS.BASE_PATH}
        component={() => (
          <ViewsContainer pl={1.5} pr={2.5} pt={2}>
            <Tasks />
          </ViewsContainer>
        )}
      />
      <PrivateRoute
        exact
        path={`${ROUTES.TASKS.BASE_PATH}/:tab`}
        component={() => (
          <ViewsContainer pl={1.5} pr={2.5} pt={2}>
            <Tasks />
          </ViewsContainer>
        )}
      />
      <PrivateRoute
        path={ROUTES.PROJECTS.BASE_PATH}
        component={() => (
          <ViewsContainer p={2}>
            <Projects />
          </ViewsContainer>
        )}
      />
      <PrivateRoute
        exact
        path={ROUTES.REPORTS}
        component={() => (
          <ViewsContainer pl={1.5} pr={2.5} pt={2}>
            <Reports />
          </ViewsContainer>
        )}
      />
      <PrivateRoute
        exact
        path={ROUTES.NEW_REPORTS}
        component={() => (
          <ViewsContainer pl={1.5} pr={2.5} pt={2}>
            <NewReports />
          </ViewsContainer>
        )}
      />
      <PrivateRoute
        exact
        path={ROUTES.SETTINGS.BASE_PATH}
        component={() => (
          <ViewsContainer p={2}>
            <Settings />
          </ViewsContainer>
        )}
      />
      <PrivateRoute
        exact
        path={`${ROUTES.SETTINGS.BASE_PATH}/:tab`}
        component={() => (
          <ViewsContainer p={2}>
            <Settings />
          </ViewsContainer>
        )}
      />
      <PrivateRoute
        path={ROUTES.TRACK.BASE_PATH}
        component={() => (
          <ViewsContainer pl={0} pr={0}>
            <TrackLayout />
          </ViewsContainer>
        )}
      />
      <PrivateRoute
        exact
        path={ROUTES.PRODUCTION.IMPORT_CUE_SHEET}
        component={() => (
          <ViewsContainer grey pl={7} pr={4}>
            <ImportCueSheet />
          </ViewsContainer>
        )}
      />
      <PrivateRoute
        path={ROUTES.PRODUCTION.BASE_PATH}
        component={() => (
          <ViewsContainer pl={0} pr={0}>
            <ProductionOverview />
          </ViewsContainer>
        )}
      />
      <PrivateRoute
        exact
        path={ROUTES.LETTERS.BASE_PATH}
        component={() => <Letters />}
        clearPermissions={CLEAR_PERMISSIONS_GROUPS.LETTERS}
      />
      <PrivateRoute
        exact
        path={ROUTES.MAIL.BASE_PATH}
        component={() => (
          <ViewsContainer>
            <Mail />
          </ViewsContainer>
        )}
      />
      <PrivateRoute
        path={`${ROUTES.LETTERS.BASE_PATH}/:step`}
        component={Letters}
        clearPermissions={CLEAR_PERMISSIONS_GROUPS.LETTERS}
      />
      <PrivateRoute
        exact
        path={ROUTES.USER_PROFILE}
        component={() => (
          <ViewsContainer pl={1.5} pr={2.5} py={2}>
            <UserProfile />
          </ViewsContainer>
        )}
      />
      <PrivateRoute
        exact
        path={ROUTES.QWIRE_ADMIN}
        component={() => (
          <ViewsContainer pl={1.5} pr={2.5} pt={2}>
            <QwireAdmin />
          </ViewsContainer>
        )}
      />
      <PrivateRoute
        exact
        path={ROUTES.MAIN_CUE_SHEET}
        component={() => (
          <ViewsContainer pl={1.5} pr={2.5} pt={2}>
            <MainCueSheet />
          </ViewsContainer>
        )}
      />
      <PrivateRoute
        exact
        path={ROUTES.LICENSORS}
        component={() => (
          <ViewsContainer pl={1.5} pr={2.5} pt={2}>
            <Licensors />
          </ViewsContainer>
        )}
      />
      <PrivateRoute
        exact
        path={ROUTES.COMPOSE_LETTER}
        component={() => (
          <ViewsContainer p={0} minHeightThemeMinus={0} marginBottom={0} height="1px">
            <ComposeLetter />
          </ViewsContainer>
        )}
      />
      <PrivateRoute
        exact
        path={ROUTES.TRACKS}
        component={() => (
          <ViewsContainer pl={1.5} pr={2.5} pt={2}>
            <Tracks />
          </ViewsContainer>
        )}
      />
      <Route exact path={ROUTES.LOGIN_CALLBACK} component={LoginCallback} />
      <PrivateRoute
        exact
        path={ROUTES.LOGOUT}
        component={() => (
          <LoginContainer>
            <Logout />
          </LoginContainer>
        )}
      />
      <Route component={DefaultView} />
    </Switch>
  );
};
export default Routes;
