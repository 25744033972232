/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDebounce, useFetch } from 'utils/hooks';
import { TypeAhead } from 'components/styleguide';
import c from 'ramda/src/compose';
import filter from 'ramda/src/filter';
import find from 'ramda/src/find';
import prop from 'ramda/src/prop';
import { useTranslation } from 'react-i18next';
import { getIn } from 'formik';
import { filterSearch } from 'utils/object';
import { withFormField } from 'components/common/Form/utils';

const handleShowOrHiddenOption = ({
  setOptionsToUpdate,
  optionsToUpdate,
  setIdsToUpdate,
  idsToUpdate,
  setStateToHandled,
  stateToHandled,
  setStateToRemoveOption,
  setCachedOptions,
  cachedOptions,
  setFieldValue,
  showHidden,
  qwireTracksRightsOwnerId,
  hidden,
}) => {
  setIdsToUpdate([...idsToUpdate, qwireTracksRightsOwnerId]);

  const items = cachedOptions.map((item) => {
    if (item.qwireTracksRightsOwnerId === qwireTracksRightsOwnerId) {
      const updatedItem = { ...item, hidden };
      setStateToHandled([...stateToHandled, updatedItem]);

      setStateToRemoveOption((prev) =>
        removeItemByKeyValue(prev, 'qwireTracksRightsOwnerId', qwireTracksRightsOwnerId),
      );

      const existingItem = optionsToUpdate.filter((item) => item.qwireTracksRightsOwnerId === qwireTracksRightsOwnerId);

      const list = [...optionsToUpdate];

      if (existingItem) {
        const index = list.findIndex((obj) => obj.qwireTracksRightsOwnerId === qwireTracksRightsOwnerId);

        if (index !== -1) {
          list[index] = updatedItem;
        } else {
          list.push(updatedItem);
        }
      }

      setOptionsToUpdate(list);
      setFieldValue('optionsToUpdateHidden', list);

      return updatedItem;
    }
    return item;
  });

  setCachedOptions(
    showHidden ? items : items.filter((item) => (!item.hidden)),
  );
};

const mapHiddenOptionsByDivision = (options, divisionId) => options.map((item) => {
    const qwireTracksRightsOwnersHiddenByDivision = item?.qwireTracksRightsOwnersHiddenByDivision?.filter(
      (i) => i.divisionId === divisionId,
    )[0];

    return {
      id: item?.id,
      name: item?.name,
      pro: item?.pro,
      qwireTracksRightsOwnerId: item?.qwireTracksRightsOwnerId,
      hidden: qwireTracksRightsOwnersHiddenByDivision ? qwireTracksRightsOwnersHiddenByDivision.isHidden : false,
    };
  });

const removeItemByKeyValue = (array, key, value) => {
  const index = array.findIndex((item) => item[key] === value);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
};

const QTracksTypeAhead = ({
  alwaysShowCreateButton,
  call,
  isCreatable,
  isShowHidden,
  label,
  isMultiple,
  form: { isSubmitting, setFieldValue, values },
  field: { name },
  divisionId,
  ...props
}) => {
  const value = getIn(values, name);
  const { t } = useTranslation();
  const [input, setInput] = useState();
  const [cachedOptions, setCachedOptions] = useState(value ? (isMultiple ? value : [value]) : []);
  const debounced = useDebounce(input);
  const [options, loadingOptions] = useFetch(() => (debounced ? call(debounced) : options), [debounced]);
  const [showHidden, setShowHidden] = useState(false);
  const [shouldBeHidden, setShouldBeHidden] = useState([]);
  const [shouldBeShow, setShouldBeShow] = useState([]);
  const [hiddenOptions, setHiddenOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [optionsToUpdate, setOptionsToUpdate] = useState([]);

  const handleCreate = async (payload) => {
    const newValue = { name: payload };
    if (name) {
      setFieldValue(name, isMultiple ? [...(value || []), newValue] : newValue);
    }
  };

  const handleHidden = async () => {
    setShowHidden(!showHidden);

    setCachedOptions(
      !showHidden ? [...filteredOptions, ...hiddenOptions] : filteredOptions,
    );
  };

  const handleClickOption = async (payload) => {
    const { hidden, qwireTracksRightsOwnerId } = payload;

    if (!hidden) {
      handleShowOrHiddenOption({
        setOptionsToUpdate,
        optionsToUpdate,
        setIdsToRemove: setShouldBeShow,
        idsToRemove: shouldBeShow,
        setIdsToUpdate: setShouldBeHidden,
        idsToUpdate: shouldBeHidden,
        setStateToHandled: setHiddenOptions,
        stateToHandled: hiddenOptions,
        setStateToRemoveOption: setFilteredOptions,
        setCachedOptions,
        cachedOptions,
        setFieldValue,
        showHidden,
        qwireTracksRightsOwnerId,
        hidden: true,
      });
    } else {
      handleShowOrHiddenOption({
        setOptionsToUpdate,
        optionsToUpdate,
        setIdsToRemove: setShouldBeHidden,
        idsToRemove: shouldBeHidden,
        setIdsToUpdate: setShouldBeShow,
        idsToUpdate: shouldBeShow,
        setStateToHandled: setFilteredOptions,
        stateToHandled: filteredOptions,
        setStateToRemoveOption: setHiddenOptions,
        setCachedOptions,
        cachedOptions,
        setFieldValue,
        showHidden,
        qwireTracksRightsOwnerId,
        hidden: false,
      });
    }
  };

  const handleChange = (val) => {
    const getValue = isMultiple ? filter(({ name }) => val.includes(name)) : find(({ name }) => name === val);
    if (name) c((v) => setFieldValue(name, v), getValue)(cachedOptions);
  };

  useEffect(() => {
    if (options) {
      const hiddenItems = mapHiddenOptionsByDivision(options, divisionId).filter((item) => (item.hidden));
      const filteredItems = mapHiddenOptionsByDivision(options, divisionId).filter((item) => (!item.hidden));
      setHiddenOptions(hiddenItems);
      setFilteredOptions(filteredItems);
      setCachedOptions(showHidden ? [...filteredItems, ...hiddenItems] : filteredItems);
    }
  }, [options, divisionId, showHidden]);
  // !!! VALIDATE THE REMOVE DUPLICATES !!!
  // useEffect(() => {
  //   const items = showHidden ?
  //     mapHiddenOptionsByDivision(options, divisionId) :
  //     mapHiddenOptionsByDivision(options, divisionId).filter(item => (!item.hidden))
  //   setCachedOptions((prevCached) =>
  //     handleRemoveDuplicates([...(value ? (isMultiple ? value : [value]) : []), ...prevCached, ...items]).filter(
  //       ({ name }) => !!name,
  //     ),
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [showHidden, value, isMultiple]);

  return (
    <TypeAhead
      disabled={isSubmitting}
      isMultiple={isMultiple}
      label={t(label)}
      loading={loadingOptions}
      name={name}
      noResultsText={input ? t('global.noResults') : t('global.startTyping')}
      onChange={handleChange}
      onCreate={
        isCreatable &&
        input &&
        !loadingOptions &&
        (alwaysShowCreateButton || !filterSearch({ name: input })(cachedOptions).length) &&
        handleCreate
      }
      onHandleHidden={
        isShowHidden &&
        input &&
        !loadingOptions &&
        handleHidden
      }
      onClickOption={
        isShowHidden &&
        input &&
        !loadingOptions &&
        handleClickOption
      }
      hiddenItemsText={!showHidden ? t('global.showHidden') : t('global.removeHidden')}
      onInputChange={setInput}
      options={cachedOptions}
      placeholder=""
      value={isMultiple ? (value || []).map(prop('name')) : prop('name')(value)}
      valueKey="name"
      {...props}
    />
  );
};

QTracksTypeAhead.propTypes = {
  alwaysShowCreateButton: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isShowHidden: PropTypes.bool,
  call: PropTypes.func.isRequired,
  defaultInput: PropTypes.string,
  removeDuplicatesFn: PropTypes.func, // To remove duplicates entities in a different way
};

QTracksTypeAhead.defaultProps = {
  alwaysShowCreateButton: false,
  isCreatable: true,
  isShowHidden: true,
};

QTracksTypeAhead.displayName = 'FormQTracksTypeAhead';

export default withFormField(QTracksTypeAhead);
