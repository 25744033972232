/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-await */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { getSetupFlowProjectSelector } from 'redux-core/productions/selectors';
import { savePosterId } from 'redux-core/productions/actions';
import { getLocationParametersSelector } from 'redux-core/router/selectors';
import equals from 'ramda/src/equals';
import identity from 'ramda/src/identity';
import mergeAll from 'ramda/src/mergeAll';
import omit from 'ramda/src/omit';
import pick from 'ramda/src/pick';
import pathOr from 'ramda/src/pathOr';
import {
  getSelectedSelector,
  getSelectedBudgetIdSelector,
  getExpandedTrackSelector,
} from 'redux-core/overview/selectors';
import { NORMALIZED_PRODUCTION_TYPES, PRODUCTION_TYPES } from 'utils/constants';
import i18next from 'i18next';
import { showSnackbar } from 'redux-core/global-error/actions';
import { requestWithError } from 'utils/request';
import { addParams } from 'redux-core/router/actions';
import { getCampaignByIdCall } from 'redux-core/overview/production-marketing/services';
import { batch } from 'react-redux';
import { getClearanceFormDirty } from 'redux-core/tracks/selectors';
import { tracksNotExpanded } from 'components/ProductionOverview/Overview/components/constants';
import { openPrompt } from 'redux-core/prompt/actions';
import { getProjectById, updateFilmReleaseCall, getFooterInfo } from 'redux-core/productions/services';
import { insertMultipleRights, upsertRights } from 'redux-core/rights/services';
import { fetchRecentSearchList, fetchBookmarkList } from 'redux-core/header/actions';
import { clearTracksApi } from 'services/clear/tracks';
import {
  getEpisodeById,
  getFilmReleaseById,
  getBudgetByProductionId,
  createBudgetVersionCall,
  createProjectAndFilmRelease,
  createSeasonEpisodeCall,
  createFilmReleaseCall,
  updateEpisodeCall,
  getProductionUseInfoCall,
} from './services';

const actionsDefinition = {
  CLEAN_EXPANDED_TRACK: identity,
  CLEAN_OVERVIEW: () => undefined,
  SET_PRODUCTION_BUDGET: identity,
  SET_PROJECT: identity,
  SET_SELECTED: identity,
  SET_SHOW_INACTIVE_CUES: identity,
  /**
   * Use it to override specific props (not related to the API model)
   * @param {['dirty']}
   */
  REMOVE_METADATA: identity,
  SET_USE_INFO: identity,
  SET_FOOTER_INFO: identity,
  SET_EXPANDED_TRACK: identity,
};

export const {
  cleanExpandedTrack,
  cleanOverview,
  setProductionBudget,
  setProductionVersion,
  setProject,
  setSelected,
  removeMetadata,
  setUseInfo,
  setFooterInfo,
  setExpandedTrack,
} = createActions(actionsDefinition);

const rootT = 'productionOverview.tabs';

export const fetchFooterInfo = () => async (dispatch, getState) => {
  const state = getState();
  const { id } = getLocationParametersSelector(state);
  const footerInfo = await getFooterInfo({ id });
  await dispatch(setFooterInfo(footerInfo));
  dispatch(clearTracksApi.util.invalidateTags(['getTrackClearanceFees']));
};

/**
 * Returns relevant data for fetching a Production
 * @param {object} state
 */
export const getProjectContextData = () => (dispatch, getState) => {
  const state = getState();

  const params = pick(['id', 'type', 'projectId', 'divisionId', 'tenantId'], getLocationParametersSelector(state));

  const newProject = getSetupFlowProjectSelector(state);

  if (!params.projectId) return dispatch(setProject(newProject || {}));

  return params;
};

/**
 * Request Project Data only
 */
export const getProjectOnly = () => async (dispatch) => {
  const { id, projectId, tenantId, divisionId } = await dispatch(getProjectContextData());
  if (!projectId) return;

  const payload = { id, divisionId, tenantId };

  const project =
    (await requestWithError(getProjectById, {
      ...payload,
      id: projectId,
    })) ?? {};
  return [project];
};

export const getProject = () => async (dispatch) => {
  const { id, type, projectId, tenantId, divisionId } = await dispatch(getProjectContextData());
  if (!projectId) return;

  const payload = { id, divisionId, tenantId };

  if (type === NORMALIZED_PRODUCTION_TYPES.FEATURES && id) {
    const [release, project] = await Promise.all([
      requestWithError(getFilmReleaseById, payload),
      requestWithError(getProjectById, { ...payload, id: projectId }),
    ]);

    if (release) {
      batch(() => {
        dispatch(setSelected(release));
        dispatch(addParams({ objectId: release.objectId }));
        dispatch(
          savePosterId({
            type: PRODUCTION_TYPES.FILM_RELEASE,
            posterId: release.posterId,
          }),
        );
      });
    }
    if (project) {
      batch(() => {
        dispatch(setProject(project));
        dispatch(addParams({ tenantId: project.tenantId }));
        dispatch(
          savePosterId({
            type: PRODUCTION_TYPES.PROJECT,
            posterId: project.posterId,
          }),
        );
      });
    }
    return [project, release];
  }

  if (type === NORMALIZED_PRODUCTION_TYPES.TV && id) {
    const [episode, project] = await Promise.all([
      requestWithError(getEpisodeById, payload),
      requestWithError(getProjectById, { ...payload, id: projectId }),
    ]);
    if (episode) {
      batch(() => {
        dispatch(setSelected(episode));
        dispatch(
          addParams({
            objectId: episode.objectId,
            releaseId: episode.releaseId,
          }),
        );
        dispatch(
          savePosterId({
            type: PRODUCTION_TYPES.EPISODE,
            posterId: episode.posterId,
          }),
        );
      });
    }
    if (project) {
      batch(() => {
        dispatch(setProject(project));
        dispatch(addParams({ tenantId: project.tenantId }));
        dispatch(
          savePosterId({
            type: PRODUCTION_TYPES.PROJECT,
            posterId: project.posterId,
          }),
        );
      });
    }
    return [project, episode];
  }

  if (type === NORMALIZED_PRODUCTION_TYPES.MKT && id) {
    const [campaign, project] = await Promise.all([
      requestWithError(getCampaignByIdCall, { id }),
      requestWithError(getProjectById, { id: projectId, divisionId }),
    ]);

    if (campaign) {
      batch(() => {
        dispatch(setSelected(campaign));
        dispatch(addParams({ objectId: campaign.objectId }));
        dispatch(
          savePosterId({
            type: PRODUCTION_TYPES.CAMPAIGN,
            posterId: campaign.posterId,
          }),
        );
      });
    }
    if (project) {
      batch(() => {
        dispatch(setProject(project));
        dispatch(addParams({ tenantId: project.tenantId }));
        dispatch(
          savePosterId({
            type: PRODUCTION_TYPES.PROJECT,
            posterId: project.posterId,
          }),
        );
      });
    }
    return [project, campaign];
  }

  return [];
};

export const getProductionUseInfo = () => async (dispatch, getState) => {
  const state = getState();
  const { id, divisionId, projectId, tenantId } = getLocationParametersSelector(state);
  const info = await getProductionUseInfoCall({
    subprojectId: id,
    divisionId,
    projectId,
    tenantId,
  });
  dispatch(setUseInfo(info));
  return info;
};

export const getProductionBudget = () => async (dispatch, getState) => {
  const budgetId = getSelectedBudgetIdSelector(getState());
  if (!budgetId) return;
  const productionBudget = await getBudgetByProductionId({ id: budgetId });

  dispatch(setProductionBudget(productionBudget));
  return productionBudget;
};

export const saveProductionBudgetVersion = (payload) => async (_, getState) => {
  const budgetId = getSelectedBudgetIdSelector(getState());
  await createBudgetVersionCall(mergeAll([{ budgetId }, payload]));
};

export const saveSeasonEpisode =
  ({ rightsPresetId, territories, requiredRights, ...payload }) =>
    async (dispatch, getState) => {
      const { tenantId, divisionId } = getLocationParametersSelector(getState());

      const episode = await createSeasonEpisodeCall(mergeAll([{ tenantId, divisionId }, payload]));
      await insertMultipleRights({
        divisionId,
        subprojectId: episode.id,
        rights: requiredRights,
      });

      const selected = {
        ...episode,
        rightsPresetId,
        territories,
      };
      await dispatch(addParams({ id: episode.id, objectId: episode.objectId }));
      dispatch(refetchProduction());
      dispatch(setSelected(selected));
      return episode.id;
    };

export const saveFilmRelease =
  ({ rightsPresetId, territories, requiredRights, ...payload }) =>
    async (dispatch, getState) => {
      const { tenantId, divisionId } = getLocationParametersSelector(getState());

      const release = await createFilmReleaseCall(mergeAll([{ tenantId, divisionId }, payload]));

      await upsertRights({
        divisionId,
        subprojectId: release.id,
        rights: requiredRights,
      });

      const selected = {
        ...release,
        rightsPresetId,
        territories,
      };
      await dispatch(addParams({ id: release.id, objectId: release.objectId }));
      dispatch(refetchProduction());
      dispatch(setSelected(selected));
      return release.id;
    };

export const saveProjectAndFilmRelease =
  ({ rightsPresetId, territories, requiredRights, ...release }) =>
    async (dispatch, getState) => {
      const state = getState();
      const { tenantId, divisionId } = getLocationParametersSelector(state);
      const project = omit(['projectId'], getSetupFlowProjectSelector(state));

      const { release: createdRelease, project: createdProject } = await createProjectAndFilmRelease(
        mergeAll([
          { tenantId, divisionId },
          { release, project },
        ]),
      );

      await upsertRights({
        divisionId,
        subprojectId: createdRelease.id,
        rights: requiredRights,
      });

      const newRelease = {
        ...createdRelease,
        rightsPresetId,
        territories,
      };
      await dispatch(
        addParams({
          divisionId,
          projectId: createdProject.id,
          tenantId,
          id: createdRelease.id,
          objectId: createdRelease.objectId,
        }),
      );
      dispatch(setProject(createdProject));
      dispatch(setSelected(newRelease));
      return createdRelease.id;
    };

export const updateReleaseOrEpisode = (valuesRaw, options) => async (dispatch, getState) => {
  const { requiredRights, ...values } = valuesRaw;
  const state = getState();
  const { type, patch, refetch, refetchRightsCall, overwriteExistingClearancesRights } = options;
  const { tenantId, divisionId } = getLocationParametersSelector(state);
  const selectedProduction = getSelectedSelector(state);

  const payload = patch
    ? mergeAll([
      { tenantId, divisionId },
      pick(['id', 'name', 'number', 'projectId', 'releaseId'], selectedProduction),
      {
        networks: pathOr(selectedProduction.networks, ['networks'], values),
      },
      {
        distributors: pathOr(selectedProduction.distributors, ['distributors'], values),
      },
      values,
    ])
    : mergeAll([{ tenantId, divisionId }, selectedProduction, values]);

  /**
   * Payload without rights info
   */
  const { rightsPresetId, territories, ...cleanPayload } = payload;

  const updateSelected = async () => {
    if (!cleanPayload.id) return cleanPayload;

    if (type === NORMALIZED_PRODUCTION_TYPES.TV) {
      return await updateEpisodeCall(cleanPayload);
    }

    if (type === NORMALIZED_PRODUCTION_TYPES.FEATURES) {
      return await updateFilmReleaseCall(cleanPayload);
    }
  };

  const [newProduction] = await Promise.all([
    updateSelected(),
    !patch &&
    upsertRights({
      divisionId,
      subprojectId: selectedProduction.id,
      rights: requiredRights,
      overwriteExistingClearancesRights,
    }),
  ]);

  // Refetch recent list
  dispatch(fetchRecentSearchList());
  // Refetch bookmarks list
  dispatch(fetchBookmarkList());

  if (!patch) {
    const message = i18next.t(
      `${rootT}.details.notification.${type === NORMALIZED_PRODUCTION_TYPES.TV ? 'episode.edit' : 'release.edit'}`,
      {
        name: cleanPayload.name,
      },
    );
    dispatch(showSnackbar({ message }));
  } else if (cleanPayload.id) {
    const message = i18next.t('global.forms.notification.save');
    dispatch(showSnackbar({ message }));
  }

  const selected = {
    ...selectedProduction,
    ...omit(['rightsPresetId', 'territories'], newProduction),
    rightsPresetId,
    territories,
  };

  refetchRightsCall && (await refetchRightsCall());
  return refetch ? dispatch(refetchProduction()) : dispatch(setSelected(selected));
};

export const refetchProduction = () => async (dispatch) => {
  const { id, type, tenantId, divisionId } = await dispatch(getProjectContextData());

  const payload = { id, divisionId, tenantId };

  const call =
    type === NORMALIZED_PRODUCTION_TYPES.FEATURES
      ? getFilmReleaseById
      : type === NORMALIZED_PRODUCTION_TYPES.TV
        ? getEpisodeById
        : getCampaignByIdCall;

  const production = await call(payload);

  dispatch(setSelected(production));
};

export const toggleExpandedTrack = (cueContainerId, track) => (dispatch, getState) => {
  const state = getState();
  const currentExpanded = getExpandedTrackSelector(state);
  const isClearanceFormDirty = getClearanceFormDirty(state);
  const trackPlacement = [cueContainerId, track];
  const toggle = () => {
    if (equals(currentExpanded, trackPlacement)) dispatch(setExpandedTrack(tracksNotExpanded));
    else dispatch(setExpandedTrack(trackPlacement));
  };

  if (isClearanceFormDirty) {
    return new Promise((resolve) =>
      // eslint-disable-next-line no-promise-executor-return
      dispatch(
        openPrompt({
          onConfirm: () => {
            toggle();
            resolve();
          },
        }),
      ),
    );
  }
  toggle();
};
