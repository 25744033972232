import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { IconButton, ListItem, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { rootTranslation } from 'utils/format';
import { Grid, List, makeStyles } from '@material-ui/core';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import AddCircleOutlineRounded from '@mui/icons-material/AddCircleOutlineRounded';
import { useFormContext } from 'react-hook-form';
import { EditOptionInput } from '../../AutocompleteWithEdit/EditOptionInput';
import { EditOptionPopover } from '../../AutocompleteWithEdit/EditOptionPopover';
import { TCreateOption, TOption } from '../../AutocompleteWithEdit/types';

const globalT = rootTranslation('global');

type ForwardedAutocompleteProps = Pick<
  React.ComponentProps<typeof Autocomplete>,
  'onInputChange' | 'loading' | 'noOptionsText'
>;

type ForwardedTextFieldProps = Pick<
  React.ComponentProps<typeof TextField>,
  'aria-labelledby' | 'placeholder' | 'error' | 'id'
>;

type AutocompleteWithEditProps = ForwardedAutocompleteProps & {
  options: TOption[];
  value: TOption;
  onChange: (option: TOption | null) => void;
  onCreate: (name: string) => void;
  onEdit?: (newName: string) => void;
  inputProps?: ForwardedTextFieldProps & {
    'data-testid'?: string;
  };
  paperMinWidth?: string;
};

type TAutocompleteValue = TOption | TCreateOption | null;

const removeItemByKeyValue = (array, key, value) => {
  const index = array.findIndex((item) => item[key] === value);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
};

const handleShowOrHiddenOption = ({
  setOptionsToUpdate,
  optionsToUpdate,
  setIdsToUpdate,
  idsToUpdate,
  setStateToHandled,
  stateToHandled,
  setStateToRemoveOption,
  setCachedOptions,
  cachedOptions,
  setFieldValue,
  showHidden,
  qwireTracksRightsOwnerId,
  hidden,
}) => {
  setIdsToUpdate([...idsToUpdate, qwireTracksRightsOwnerId]);

  const items = cachedOptions.map((item) => {
    if (item.qwireTracksRightsOwnerId === qwireTracksRightsOwnerId) {
      const updatedItem = { ...item, hidden };
      setStateToHandled([...stateToHandled, updatedItem]);

      setStateToRemoveOption((prev) =>
        removeItemByKeyValue(prev, 'qwireTracksRightsOwnerId', qwireTracksRightsOwnerId),
      );

      const existingItem = optionsToUpdate.filter(
        (option) => option.qwireTracksRightsOwnerId === qwireTracksRightsOwnerId,
      );

      const list = [...optionsToUpdate];

      if (existingItem) {
        const index = list.findIndex((obj) => obj.qwireTracksRightsOwnerId === qwireTracksRightsOwnerId);

        if (index !== -1) {
          list[index] = updatedItem;
        } else {
          list.push(updatedItem);
        }
      }

      setOptionsToUpdate(list);
      setFieldValue('optionsToUpdateHidden', list);

      return updatedItem;
    }
    return item;
  });

  setCachedOptions(
    showHidden ? items : items.filter((item) => (!item.hidden)),
  );
};

const filter = createFilterOptions<TOption | TCreateOption>();

const useStyles = makeStyles((theme) => ({
  listbox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
  },
  footer: {
    padding: '8px',
    borderTop: '1px solid #e0e0e0',
    textAlign: 'end',
  },
  create: {
    width: '20%',
    alignItems: 'end',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    overflow: 'hidden',
  },
  hidden: {
    display: 'none',
  },
  visible: {
    display: 'block',
  },
  optionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  optionButton: {
    display: 'none',
    marginLeft: 'auto',
    zIndex: 9999,
  },
  optionHovered: {
    '&:hover $optionButton': {
      display: 'block',
    },
  },
  optionClicked: {
    display: 'block',
  },
}));

const ListboxComponent = React.forwardRef(({ ...props }: any, ref) => {
  const classes = useStyles();
  return (
    <Box ref={ref} {...props} className={classes.listbox}>
      <List {...props} />
      <Box className={classes.footer}>
        <Button
          onClick={props.onClickHiddenItems}
          disabled={false}
          data-testid="typeahead-hidden-items"
        >
          {props.hiddenItemsText}
        </Button>
      </Box>
    </Box>
  );
});

export function AutocompleteHiddenLicensors({
  options,
  onChange,
  onCreate,
  onEdit,
  value,
  inputProps,
  paperMinWidth = '100%',
  ...props
}: AutocompleteWithEditProps) {
  const { setValue } = useFormContext();

  const anchorElRef = React.useRef(null);
  const [editPopoverOpen, setEditPopoverOpen] = React.useState(false);
  const [editingOption, setEditingOption] = React.useState(null);
  const [hiddenOptions, setHiddenOptions] = React.useState([]);
  const [filteredOptions, setFilteredOptions] = React.useState([]);
  const [cachedOptions, setCachedOptions] = React.useState([]);
  const [showHidden, setShowHidden] = React.useState(false);
  const [optionsToUpdate, setOptionsToUpdate] = React.useState([]);
  const [shouldBeShow, setShouldBeShow] = React.useState([]);
  const [shouldBeHidden, setShouldBeHidden] = React.useState([]);
  const [clickedOptions, setClickedOptions] = React.useState({});
  const { error, placeholder, ...restInputProps } = inputProps;

  const classes = useStyles();

  const handleClose = () => {
    setEditPopoverOpen(false);
  };

  const handleHidden = async () => {
    setShowHidden(!showHidden);

    setCachedOptions(
      !showHidden ? [...filteredOptions, ...hiddenOptions] : filteredOptions,
    );
  };

  React.useEffect(() => {
    const hiddenItems = options.filter((item) => (item.hidden));
    const filteredItems = options.filter((item) => (!item.hidden));
    setHiddenOptions(hiddenItems);
    setFilteredOptions(filteredItems);
    setCachedOptions(showHidden ? [...filteredItems, ...hiddenItems] : filteredItems);
    hiddenItems.map((item) => setClickedOptions((prev) => ({
      ...prev,
      [item.name]: !prev[item.name],
    })));
  }, [options, showHidden]);

  return (
    <Box ref={anchorElRef}>
      <EditOptionPopover
        open={editPopoverOpen}
        anchorElRef={anchorElRef}
        onClose={handleClose}
        paperMinWidth={paperMinWidth}
      >
        <EditOptionInput option={editingOption} persistValue={onEdit} />
      </EditOptionPopover>
      <Autocomplete<TAutocompleteValue, false, false, true>
        id="autocomplete-editable"
        key={`AutocompleteWithEdit-${value?.id}-${value?.name}`}
        value={value}
        onChange={(_, newValue) => {
          if (newValue == null) {
            // @ts-ignore
            onChange(newValue);
            return;
          }
          if (typeof newValue === 'string') {
            onCreate(newValue);
          } else if ('inputValue' in newValue) {
            // Create a new value from the user input
            onCreate(newValue.inputValue);
          } else {
            onChange(newValue);
          }
        }}
        filterOptions={(currentOptions, params) => {
          const filtered = filter(currentOptions, params);
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = currentOptions.some((option) => inputValue === option?.name);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              id: uuidv4(),
              name: `${globalT('forms.createNew')} "${inputValue}"`,
              inputValue,
              hidden: false,
            });
          }
          return filtered;
        }}
        isOptionEqualToValue={(option, valueOption) => option?.id === valueOption?.id}
        options={cachedOptions}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if ('inputValue' in option) {
            return option?.inputValue;
          }
          // Regular option
          return option?.name;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            error={error}
            inputProps={{
              ...params.inputProps,
              ...restInputProps,
              'data-testid': inputProps['data-testid'] ?? 'autocomplete-editable',
            }}
          />
        )}
        /* eslint-disable react/no-unstable-nested-components */
        ListboxComponent={(listboxProps) => (
          <ListboxComponent
            {...listboxProps}
            onClickHiddenItems={handleHidden}
            hiddenItemsText={!showHidden ? globalT('showHidden') : globalT('removeHidden')}
          />
        )}
        renderOption={(optionProps, option, state) => {
          const renderEditAction = onEdit != null && state.selected;

          const Icon = option.hidden
           ? <AddCircleOutlineRounded />
           : <RemoveCircleOutlineRoundedIcon />;

          const handleClickOption = async (event, payload) => {
            event.stopPropagation();
            setClickedOptions((prev) => ({
              ...prev,
              [payload.name]: !prev[payload.name],
            }));
            const { hidden, qwireTracksRightsOwnerId } = payload;

              if (!hidden) {
                handleShowOrHiddenOption({
                  setOptionsToUpdate,
                  optionsToUpdate,
                  // setIdsToRemove: setShouldBeShow,
                  // idsToRemove: shouldBeShow,
                  setIdsToUpdate: setShouldBeHidden,
                  idsToUpdate: shouldBeHidden,
                  setStateToHandled: setHiddenOptions,
                  stateToHandled: hiddenOptions,
                  setStateToRemoveOption: setFilteredOptions,
                  setCachedOptions,
                  cachedOptions,
                  setFieldValue: setValue,
                  showHidden,
                  qwireTracksRightsOwnerId,
                  hidden: true,
                });
              } else {
                handleShowOrHiddenOption({
                  setOptionsToUpdate,
                  optionsToUpdate,
                  // setIdsToRemove: setShouldBeHidden,
                  // idsToRemove: shouldBeHidden,
                  setIdsToUpdate: setShouldBeShow,
                  idsToUpdate: shouldBeShow,
                  setStateToHandled: setFilteredOptions,
                  stateToHandled: filteredOptions,
                  setStateToRemoveOption: setHiddenOptions,
                  setCachedOptions,
                  cachedOptions,
                  setFieldValue: setValue,
                  showHidden,
                  qwireTracksRightsOwnerId,
                  hidden: false,
                });
              }
          };

          const handleEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
            /**
             * Stop propagation used to prevent calling the option onChange when clicking the edit button
             */
            event.stopPropagation();
            setEditingOption(option);
            setEditPopoverOpen(true);
          };

          return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <ListItem
                {...optionProps}
                secondaryAction={
                  renderEditAction && (
                    <IconButton aria-label="edit option" onClick={handleEdit}>
                      <EditIcon />
                    </IconButton>
                  )
                }
              >
                <Box
                  className={`${classes.optionBox} ${classes.optionHovered}`}
                  title={option.name}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: renderEditAction ? 'calc(100% - 40px)' : '100%',
                  }}
                >
                  {option.name}
                  <Grid
                    className={
                      `${classes.optionButton} ${clickedOptions[option.name]
                      ? classes.optionClicked
                      : ''}`}
                  >
                    <Button
                      sx={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            '&:hover': {
                              border: 'none',
                              backgroundColor: 'transparent',
                            },
                          }}
                      size="small"
                          // value={value}
                      color="primary"
                      variant="outlined"
                      startIcon={Icon}
                      onClick={(event) => handleClickOption(event, option)}
                    />
                  </Grid>

                  {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div style={{backgroundColor: '#f5f5f5'}}>
                      <Grid item className={showComponent || option.hidden ? classes.visible : classes.hidden}>
                        <Button
                          sx={{
                            border: 'none',
                            backgroundColor: '#f5f5f5',
                            '&:hover': {
                              border: 'none',
                              backgroundColor: 'transparent',
                            }
                          }}
                          size="small"
                          // value={value}
                          color="primary"
                          variant="outlined"
                          startIcon={Icon}
                          onClick={() => handleClickOption(option)}
                          // disabled={disabled || canExecuteActionOnTrack.isUserReadOnly}
                        />
                      </Grid>
                    </div>
                  </div> */}
                </Box>
              </ListItem>
            </div>
          );
        }}
        fullWidth
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        componentsProps={{
          paper: {
            sx: {
              minWidth: paperMinWidth,
              width: '100%',
            },
          },
        }}
        {...props}
      />
    </Box>
  );
}
