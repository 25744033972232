/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import compose from 'ramda/src/compose';
import find from 'ramda/src/find';
import prop from 'ramda/src/prop';
import map from 'ramda/src/map';
import propEq from 'ramda/src/propEq';
import { Loading } from 'components/styleguide';
import { useRootTranslation, useFetch } from 'utils/hooks';
import { getCountriesCall, getTerritoriesListCall } from 'redux-core/territories/services';
import { getProductionCurrenciesCall } from 'redux-core/productions/services';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import { getRightsOwnersByIdCall, updateRightsOwners, createRightsOwners } from 'redux-core/rights-owners/services';
import PropTypes from 'prop-types';
import { concatStrings } from 'utils/index';
import { SecuredForm } from 'components/common/Secured';
import { COMMON_ROLES } from 'redux-core/permissions/constants';
import Box from '@material-ui/core/Box';
import { getLicensorContacts } from 'redux-core/contacts/services';
import { updateQwireTracksHiddenLicensors } from 'redux-core/tracks/services';
import FormHeader from '../common/FormHeader';
import { getPayload, getMasterValidationSchema } from '../utils';
import enhancer from './enhancer';
import BasicInfoForm from '../common/BasicInfoForm';

const getMasterValue = compose(prop('id'), find(propEq('isMaster', true)));

const SyncLicensor = ({
  name,
  isEditing,
  licensorId,
  productionId,
  divisionId,
  clearancesFetch,
  showSnackbar,
  onClose,
  showGenericError,
  type,
  originalType,
  trackId,
  isVerifiedTrack,
  licensorsExist,
  readOnly,
}) => {
  const [openPrompt, setOpenPrompt] = useState(false);
  const [territories] = useFetch(() => divisionId && getTerritoriesListCall({ divisionId }), [divisionId]);
  const [countries] = useFetch(getCountriesCall);
  const [currencies] = useFetch(() => getProductionCurrenciesCall(productionId), [productionId]);
  const dispatch = useDispatch();
  const currenciesOptions = map(
    (currency) => ({
      ...currency,
      name: concatStrings(' ')(currency.code, currency.symbol),
    }),
    currencies,
  );
  const masterCurrencyId = useMemo(() => getMasterValue(currencies), [currencies]);

  const [contacts] = useFetch(
    () =>
      licensorId &&
      getLicensorContacts({
        qclearRightsOwnerId: licensorId,
        divisionId,
      }),
    [licensorId],
  );

  const t = useRootTranslation(`drawers.licensorsAddEdit.${type}`);
  const [licensor, loading, refetchLicensor] = useFetch(
    () => licensorId && getRightsOwnersByIdCall({ id: licensorId }),
    [licensorId],
    {},
  );

  const validationSchema = useMemo(
    () =>
      getMasterValidationSchema({
        initialRightsOwnerId: licensor.qwireTracksRightsOwnerId,
        licensorId,
        trackId,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [licensor.qwireTracksRightsOwnerId],
  );

  const handleSubmit = async (values) => {
    try {
      const payload = getPayload({
        values,
        trackId,
        isEditing,
        type,
        originalType,
        divisionId,
        licensorsExist,
      });

      if (isEditing) {
        await updateRightsOwners(payload);
      } else {
        await createRightsOwners(payload);
      }
      await dispatch(
        updateQwireTracksHiddenLicensors({
          qwireTracksRightsOwners: values.optionsToUpdateHidden,
          divisionId,
        }),
      );
      showSnackbar({ message: t('success') });
      await clearancesFetch();
      onClose();
    } catch (error) {
      showGenericError();
    }
  };

  const sharedInfo = {
    currencies: currenciesOptions,
    clearancesFetch,
    countries,
    territories,
    divisionId,
    onClose,
    type,
    rootKey: 'licensor',
    contactKey: 'licensor.contact',
    licensorContacts: contacts,
    licensorContact: {
      id: licensor?.contact?.id,
      name: `${licensor?.contact?.firstName} ${licensor?.contact?.lastName}`,
    },
    isVerifiedTrack,
  };
  const initialValues = {
    licensor: {
      ...licensor,
      currencyId: licensorId ? licensor.currencyId : masterCurrencyId,
      qTrack: {
        name: licensor.name,
        qwireTracksRightsOwnerId: licensor.qwireTracksRightsOwnerId,
      },
    },
  };
  const handleCancel = () => {
    setOpenPrompt(false);
    onClose();
  };
  if (loading) return <Loading />;
  return (
    <Box width="100%" mb={10}>
      <SecuredForm
        drawerName={name}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={initialValues}
        objectId={licensor.objectId}
        permissions={COMMON_ROLES.EDITOR}
        validationSchema={validationSchema()}
        // Override default Form prop
        isInitialValid={undefined}
      >
        <FormHeader
          onClose={onClose}
          isEditing={isEditing}
          openPrompt={() => setOpenPrompt(true)}
          t={t}
          showSnackbar={showSnackbar}
          trackId={trackId}
          clearancesFetch={clearancesFetch}
          createRightsOwners={createRightsOwners}
          showGenericError={showSnackbar}
          type={type}
          divisionId={divisionId}
        />
        <BasicInfoForm
          {...sharedInfo}
          refetchLicensor={refetchLicensor}
          isEditingLicensor={isEditing}
          readOnly={readOnly}
        />
      </SecuredForm>
      <ConfirmationDialog
        onClose={() => setOpenPrompt(false)}
        onConfirm={handleCancel}
        open={openPrompt}
        content={t('prompt')}
      />
    </Box>
  );
};

SyncLicensor.propTypes = {
  name: PropTypes.string,
  isEditing: PropTypes.bool,
  showSnackbar: PropTypes.func,
  showGenericError: PropTypes.func,
  clearancesFetch: PropTypes.func,
  licensorId: PropTypes.number,
  trackId: PropTypes.number,
  divisionId: PropTypes.number,
  productionId: PropTypes.number.isRequired,
  type: PropTypes.string,
  originalType: PropTypes.string,
  onClose: PropTypes.func,
  isVerifiedTrack: PropTypes.bool,
  licensorsExist: PropTypes.bool,
  readOnly: PropTypes.bool,
};
export default enhancer(SyncLicensor);
