/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ListItemButton, Button } from '@mui/material';
import Box from '@material-ui/core/Box';
import path from 'ramda/src/path';
import { Grid, makeStyles } from '@material-ui/core';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import AddCircleOutlineRounded from '@mui/icons-material/AddCircleOutlineRounded';

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.action.selected,
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .dotted': {
      width: '1rem',
      height: '1rem',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      marginRight: theme.spacing(1),
    },
  },
  hidden: {
    display: 'none',
  },
  visible: {
    display: 'block',
  },
  hiddenButton: {
    border: 'none',
  },
}));

function Option(props) {
  const dense = path(['selectProps', 'extraProps', 'denseItem'])(props);
  const dotId = path(['selectProps', 'extraProps', 'dotId'])(props);
  const onClickOption = path(['selectProps', 'extraProps', 'onClickOption'])(props);
  const Icon = props.data.hidden
  ? <AddCircleOutlineRounded />
  : <RemoveCircleOutlineRoundedIcon />;

  const [showComponent, setShowComponent] = useState(false);

  const handleMouseEnter = () => {
    setShowComponent(true);
  };

  const handleMouseLeave = () => {
    setShowComponent(false);
  };

  const classes = useStyles();
  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ListItemButton
        ref={props.innerRef}
        selected={props.isSelected}
        component="div"
        data-testid="typeahead-option"
        dense={dense}
        {...props.innerProps}
        classes={classes}
      >
        {dotId && dotId === props.value ? <Box className="dotted" /> : null}
        <Grid container justifyContent="space-between">
          {props.children}
        </Grid>
      </ListItemButton>
      <div sx={{ backgroundColor: '#f5f5f5' }}>
        <Grid item className={showComponent || props.data.hidden ? classes.visible : classes.hidden}>
          <Button
            sx={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    border: 'none',
                    backgroundColor: 'transparent',
                  },
                }}
            size="small"
                // value={value}
            color="primary"
            variant="outlined"
            startIcon={Icon}
            onClick={() => onClickOption(props.data)}
          />
        </Grid>
      </div>
    </Grid>
  );
}

Option.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    key: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
  }).isRequired,
  /**
   * Inner ref to DOM Node
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
  /**
   * Whether the option is focused.
   */
  isFocused: PropTypes.bool.isRequired,
  /**
   * Whether the option is selected.
   */
  isSelected: PropTypes.bool.isRequired,
};

export default Option;
