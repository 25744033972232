import { GridSortModel } from '@mui/x-data-grid';
import { fetchFooterInfo } from 'redux-core/overview/actions';
import { baseApi, TBaseEntityDto, TPagination } from 'services';
import { TFileType } from 'utils/constants-types';
import { TLicensorContact } from './types';

export type TUseAndDuration = {
  requestedUseType: string;
  requestedUseDuration: string;
  actualUseType: string;
  actualUseDuration: string;
};

type TScene = {
  id: number;
  title: string;
  description: string;
};

export type TTrack = {
  id: number;
  title: string;
  using: boolean;
  oneStopCombined: boolean;
  usesAndDuration: TUseAndDuration[];
  artists: TBaseEntityDto[];
  cueContainers: TScene[];
};

export type TClearanceBundleLicensor = TBaseEntityDto & {
  type: string;
  rightsOwner: any;
  rightsOwnerId: number;
  quoteSentAt?: Date;
  quoteReceivedAt?: Date;
  quoteExpiresAt?: Date;
  confirmationSentAt?: Date;
  confirmationReceivedAt?: Date;
  licenseSentAt?: Date;
  licenseReceivedAt?: Date;
  licenseRequestSentAt?: Date;
  licenseRequestReceivedAt?: Date;
  checkRequestSentAt?: Date;
  checkRequestReceivedAt?: Date;
  checkRequestExpiresAt?: Date;
  confirmationExpiresAt?: Date;
  licenseExpiresAt?: Date;
  licenseRequestExpiresAt?: Date;
}

export type TLicensor = TBaseEntityDto & {
  type: string;
  share: number;
  status: TBaseEntityDto & {
    color: string;
  };
  approvedFees: number;
  estimatedFees: number;
  quotedFees: number;
  notes?: string;
  rightsOwner: any;
  rightsOwnerId: number;
  clearanceStatusId?: number;
  clearanceStatus?: any;
  fullQuote?: any;
  dates?: any;
  mfn?: any;
  quoteSentAt?: Date;
  quoteReceivedAt?: Date;
  quoteExpiresAt?: Date;
  confirmationSentAt?: Date;
  confirmationReceivedAt?: Date;
  licenseSentAt?: Date;
  licenseReceivedAt?: Date;
  licenseRequestSentAt?: Date;
  licenseRequestReceivedAt?: Date;
  checkRequestSentAt?: Date;
  checkRequestReceivedAt?: Date;
  checkRequestExpiresAt?: Date;
  confirmationExpiresAt?: Date;
  licenseExpiresAt?: Date;
  licenseRequestExpiresAt?: Date;
  unions: any;
};

export type TCue = {
  id: number;
  title: string;
  cueContainerId: number;
  subprojectName: string;
  allocation: number;
  requestedUseType: string;
  requestedUseDuration?: string;
  requestedUseDurationFull?: boolean;
  requestedUsageNotes: string;
  actualUseType: string;
};

export type TRights = {
  id?: number;
  territoryId: number;
  territory?: {
    id: number;
    name: string;
  };
  name: string;
  term: 0 | 1 | 2 | 3 | 4;
  start?: string | Date;
  end?: string | Date;
  otherDetails?: string;
  descriptionOnLetters?: string;
  cues: TCue[];
};

export type TMainLicensor = {
  licensor: TLicensor;
  track: TTrack;
  clearance: TBaseEntityDto;
  rights: TRights;
  rep: TBaseEntityDto;
  project: TBaseEntityDto;
  production: TBaseEntityDto;
  division: TBaseEntityDto;
  options: string[];
  tags: string[];
};

export type TLicensorListResponse = {
  data: TMainLicensor[];
  pagination: TPagination;
};

export type TMainLicensorsFilters = {
  divisionIds?: number[];
  projectIds?: number[];
  productionIds?: number[];
  licensorNames?: string[];
  statuses?: string[];
};

export type TLicensorGetAllPayload = {
  page: number;
  limit: number;
  filters?: TMainLicensorsFilters;
  searchTerm?: string;
  showInactiveProductions?: boolean;
  sort?: GridSortModel;
};

export type TFileInfoRequest = {
  fileName: string;
  fileSize: number;
  fileType: TFileType;
  uploadedFileId: number;
};

export type NestedLicensorDates = {
  quoteSentAt?: Date;
  quoteReceivedAt?: Date;
  quoteExpiresAt?: Date;
  confirmationSentAt?: Date;
  confirmationReceivedAt?: Date;
  licenseSentAt?: Date;
  licenseReceivedAt?: Date;
  licenseExpiresAt?: Date;
  licenseRequestSentAt?: Date;
  licenseRequestReceivedAt?: Date;
  checkRequestSentAt?: Date;
  checkRequestReceivedAt?: Date;
};

export type MfnType = 'NONE' | 'BASE' | 'WAIVED';

export type UpdateLicensor = {
  id: number;
  share?: number;
  fullQuote?: number;
  estimatedFees?: number;
  approvedFees?: number;
  clearanceStatusId?: number;
  dates: NestedLicensorDates;
  mfn?: MfnType;
  notes?: string;
  clearanceBundleId?: number;
};

export type UpdateLicensorFees = {
  id: number;
  share?: number;
  fullQuote?: number;
  estimatedFees?: number;
  approvedFees?: number;
  clearanceStatusId?: number;
  dates: NestedLicensorDates;
  mfn?: MfnType;
  notes?: string;
  clearanceBundleId?: number;
};

export type TUploadFileAndUpdateStatusPayload = {
  clearanceBundleLicensorsIds: number[];
  clearanceStatusId?: number;
  filesInfo?: TFileInfoRequest[];
};

export type TGetLicensorContactsRequest = {
  divisionId: number;
  qclearLicensorId: number;
};

export const licensorsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMainLicensorList: builder.query<TLicensorListResponse, TLicensorGetAllPayload>({
      query: (body) => ({ url: 'clear/licensors/get-all-main', method: 'POST', body }),
      providesTags: ['licensorsGetAll'],
      keepUnusedDataFor: 0,
    }),
    getLicensorContacts: builder.query<TLicensorContact[], TGetLicensorContactsRequest>({
      query: (body) => ({ url: 'clear/licensors/get-contacts', method: 'POST', body }),
      providesTags: ['licensorsContacts'],
    }),
    getPreferredContact: builder.query<{ contactId: number }, { rightsOwnerId: number }>({
      query: (body) => ({ url: 'clear/licensors/get-preferred-contact', method: 'POST', body }),
      keepUnusedDataFor: 0,
    }),
    patchUpdateLicensors: builder.mutation<void, TUploadFileAndUpdateStatusPayload>({
      query: (body) => ({
        url: 'clear/licensors/patch-update-licensors',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['licensorsGetAll', 'getTrackClearanceStatus', 'getBundleLicenses'],
    }),
    updateLicensor: builder.mutation<void, UpdateLicensor>({
      query: (body) => ({
        url: 'clear/licensors/update',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(fetchFooterInfo());
      },
      invalidatesTags: [
        'clearanceBundle',
        'getBundleLicenses',
        'getTrackClearanceStatus',
        'getTrackClearanceFees',
      ],
    }),
    updateLicensorFees: builder.mutation<any, UpdateLicensorFees>({
      query: (body) => ({
        url: 'clear/licensors/update-fees',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(fetchFooterInfo());
      },
      invalidatesTags: [
        'clearanceBundle',
      ],
    }),
  }),
});

export const {
  useGetMainLicensorListQuery,
  useGetLicensorContactsQuery,
  useGetPreferredContactQuery,
  usePatchUpdateLicensorsMutation,
  useUpdateLicensorMutation,
  useUpdateLicensorFeesMutation,
} = licensorsApi;
